import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

export const ResetPassword = () => {
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirm] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenfound = queryParams.get("token");
    if (tokenfound) {
      setToken(tokenfound);
    }
  }, [location.search]);

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      toast.error("Password and Confirm does not match..!!");
      return;
    }

    try {
      const response = await fetch(
        "https://api.erapay.biz/erapay/auth/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: token, newPassword: password }),
        }
      );

      const data = await response.json();

      console.log(data);
      if (data.success) {
        toast.success("Password set");
        navigate("/login");
      } else {
        console.log("in else");
        toast.info("Failed to set password. Please try again later..!!");
      }
    } catch (error) {
      toast.info("Error setting password. Please try again.");
    }
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background:
            "linear-gradient(135deg, #6e8efb 0%,rgb(255, 253, 154) 100%)",
          padding: 2,
        }}
      >
        <Container sx={{ minHeight: "100vh" }}>
          <Paper
            elevation={12}
            sx={{
              borderRadius: 4,
              minHeight: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: 300, textAlign: "center" }}>
              <Typography variant="h5" gutterBottom>
                Set Your Password
              </Typography>
              <TextField
                fullWidth
                type="password"
                label="New Password"
                variant="outlined"
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                fullWidth
                type="password"
                label="Confirm Password"
                variant="outlined"
                margin="normal"
                value={confirmPassword}
                onChange={(e) => setConfirm(e.target.value)}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={handleSubmit}
              >
                Set Password
              </Button>
            </Box>
          </Paper>
        </Container>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="colored"
        />
      </Box>
    </>
  );
};
