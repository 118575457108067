import React, { useContext, useState } from "react";
import { ThemeContext } from "./ThemeContext";
import { CssBaseline, Box, useTheme, useMediaQuery } from "@mui/material";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import DrawerComponent from "../src/Home/DrawerComponent";
import DashboardHeader from "../src/Home/DashboardHeader";

const Layout = () => {
    const { mode } = useContext(ThemeContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [selectedLabel, setSelectedLabel] = useState("Dashboard");
    const navigate = useNavigate();
    const location = useLocation(); // Detect current route

    const handleSelectLabel = (label) => {
        setSelectedLabel(label);
        console.log("Selected label:", label);
        navigate(`/${label}`);
    };

    return (
        <>
            <CssBaseline />
            <DrawerComponent onSelectLabel={handleSelectLabel} />
            <DashboardHeader mode={mode} selectedLabel={selectedLabel} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: "3vh",
                    maxWidth: isMobile ? "100%" : "1200px",
                    margin: "0 auto",
                    overflowX: "hidden",
                    minHeight: isMobile ? "100vh" : "auto",
                    width: isMobile ? "100vw" : "auto",
                }}
            >
                <Outlet /> {/* This will render child components dynamically */}
            </Box>
        </>
    );
};

export default Layout;
