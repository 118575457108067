import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Pagination,
  Box,
} from "@mui/material";
import axios from "axios";

const GiftCardListUser = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const token = sessionStorage.getItem("token");
  const userRole = sessionStorage.getItem("role");
  const userId = sessionStorage.getItem("userId");

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.get(
        "https://api.erapay.biz/erapay/user/mycard",
        {
          headers: {
            // userid: userId,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("User list:", response);

      if (
        response.data.success === true ||
        response.data.message === "Card Found"
      ) {
        // Convert single object to an array
        const cardData = response.data.card;
        setUsers(Array.isArray(cardData) ? cardData : [cardData]);
        setTotalRecords(1);
      } else {
        setUsers([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
      setUsers([]);
      setTotalRecords(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [currentPage, pageSize]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setCurrentPage(0);
  };

  return (
    <Box>
      <Grid>
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "sans-serif",
            fontSize: "19px",
            color: "#343C6A",
          }}
        >
          User List - Applied Cards
        </Typography>
      </Grid>
      <Grid>
        {loading ? (
          <Grid sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <TableContainer
              component={Paper}
              sx={{ overflowX: "auto", borderRadius: "12px 12px 0px 0px" }}
            >
              <Table>
                <TableHead
                  style={{ backgroundColor: "#f2b173", color: "white" }}
                >
                  <TableRow>
                    <TableCell sx={{ color: "white" }}>Sr No.</TableCell>
                    <TableCell sx={{ color: "white" }}>Card Number</TableCell>
                    <TableCell sx={{ color: "white" }}>User Name</TableCell>
                    <TableCell sx={{ color: "white" }}>Mobile No.</TableCell>
                    <TableCell sx={{ color: "white" }}>Card Expiry</TableCell>
                    <TableCell sx={{ color: "white" }}>Card Network</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.length > 0 ? (
                    users.map((user, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {currentPage * pageSize + index + 1}
                        </TableCell>
                        <TableCell>{user.cardNumber || "N/A"} </TableCell>
                        <TableCell>{user.user.name || "N/A"}</TableCell>
                        <TableCell>{user.user.mobile || "N/A"}</TableCell>
                        <TableCell>{user.end || "N/A"}</TableCell>
                        <TableCell>{user.cardNetwork || "N/A"}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No users found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* Pagination */}
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: "1%" }}
            >
              <Grid item>
                <Select
                  variant="outlined"
                  value={pageSize}
                  onChange={handlePageSizeChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
                <Typography display="inline" style={{ marginLeft: 8 }}>
                  Showing {currentPage * pageSize + 1} to{" "}
                  {Math.min((currentPage + 1) * pageSize, totalRecords)} out of{" "}
                  {totalRecords} records
                </Typography>
              </Grid>
              <Grid item>
                <Pagination
                  count={Math.ceil(totalRecords / pageSize)}
                  color="primary"
                  page={currentPage + 1}
                  onChange={handlePageChange}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default GiftCardListUser;
