import React, { useState, useMemo } from "react";
import {
  Grid,
  TextField,
  Typography,
  Box,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

const DashboardHeader = ({ mode, selectedLabel }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const name = sessionStorage.getItem("name");

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Clear sessionStorage and navigate to login
    sessionStorage.clear();
    navigate("/login");
  };

  const handleProfile = () => {
    // Navigate to the profile page
    navigate("/myprofile");
  };

  const styles = useMemo(
    () => ({
      textColor: mode === "light" ? "#000" : "#fff",
      backgroundColor: mode === "light" ? "white" : "#333",
      borderColor: mode === "light" ? "#ccc" : "#444",
      hoverBorderColor: mode === "light" ? "#888" : "#bbb",
      focusedBorderColor: mode === "light" ? "#007BFF" : "#66A1FF",
    }),
    [mode]
  );

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      marginTop={isMobile ? 6 : 6}
      sx={{ width: "80%", px: 2 }}
      ml={isMobile ? 0 : 20}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ px: 2, py: 1 }}
      >
        {isMobile ? (
          // Mobile Layout: Title and Avatar in One Row
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              ml: 5,
            }}
          >
            <Typography
              sx={{
                fontFamily: "'Dancing Script', cursive",
                fontSize: "18px",
                fontWeight: "bold",
                color: styles.textColor,
              }}
            >
              {selectedLabel}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", ml: 10 }}>
              <Avatar
                src="https://via.placeholder.com/40"
                alt="User Avatar"
                sx={{ width: 32, height: 32 }}
              />
              <IconButton
                sx={{ color: styles.textColor }}
                onClick={handleClick}
              >
                <ArrowDropDownIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleProfile}>My Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </Box>
          </Grid>
        ) : (
          // Desktop Layout: Search, Title, and User Info in Three Columns
          <>
            <Grid item xs={4} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  fontFamily: "'Dancing Script', cursive",
                  fontSize: "22px",
                  fontWeight: "bold",
                  color: styles.textColor,
                }}
              >
                {selectedLabel}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ textAlign: "right", marginRight: "8px" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: styles.textColor,
                      fontWeight: "bold",
                    }}
                  >
                    {name}
                  </Typography>
                  {/* <Typography
                    sx={{
                      fontSize: "12px",
                      color:
                        mode === "light" ? "#666" : "rgba(255, 255, 255, 0.6)",
                    }}
                  >
                    uroos.design@gmail.com
                  </Typography> */}
                </Box>
                <Avatar
                  src="https://via.placeholder.com/40"
                  alt="User Avatar"
                  sx={{ width: 32, height: 32 }}
                />
                <IconButton
                  sx={{ color: styles.textColor }}
                  onClick={handleClick}
                >
                  <ArrowDropDownIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleProfile}>My Profile</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default DashboardHeader;
