// // import { Container } from "@mui/system";
// // import React from "react";

// // const ConversionCoin = () => {
// //   return (
// //     <Container>

// //     </Container>
// //   );
// // };

// // export default ConversionCoin;

// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Container,
//   Typography,
//   TextField,
//   Paper,
//   IconButton,
//   Grid,
//   InputAdornment,
//   Divider,
//   Dialog,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemIcon,
//   InputBase,
//   AppBar,
//   Toolbar,
//   Button
// } from '@mui/material';
// import SwapVertIcon from '@mui/icons-material/SwapVert';
// import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// import SearchIcon from '@mui/icons-material/Search';
// import CloseIcon from '@mui/icons-material/Close';
// import SettingsIcon from '@mui/icons-material/Settings';
// import AssessmentIcon from '@mui/icons-material/Assessment';

// // Currency flags and data
// const currencies = [
//   { code: 'USD', flag: '🇺🇸', name: 'USD' },
//   { code: 'EUR', flag: '🇪🇺', name: 'EUR' },
//   { code: 'GBP', flag: '🇬🇧', name: 'GBP' },
//   { code: 'SGD', flag: '🇸🇬', name: 'SGD' },
//   { code: 'INR', flag: '🇮🇳', name: 'INR' },
//   { code: 'CAD', flag: '🇨🇦', name: 'CAD' },
//   { code: 'AUD', flag: '🇦🇺', name: 'AUD' },
//   { code: 'CHF', flag: '🇨🇭', name: 'CHF' },
//   { code: 'MXN', flag: '🇲🇽', name: 'MXN' },
//   { code: 'AED', flag: '🇦🇪', name: 'AED' },
// ];

// const CurrencyConverter = () => {
//   const [fromCurrency, setFromCurrency] = useState(currencies[0]);
//   const [toCurrency, setToCurrency] = useState(currencies[4]); // Default to INR
//   const [fromAmount, setFromAmount] = useState('736.70');
//   const [toAmount, setToAmount] = useState('1000.00');
//   const [currentInput, setCurrentInput] = useState('from');
//   const [dialogOpen, setDialogOpen] = useState(false);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [filteredCurrencies, setFilteredCurrencies] = useState(currencies);

//   const handleCurrencySelect = (currency) => {
//     if (currentInput === 'from') {
//       setFromCurrency(currency);
//     } else {
//       setToCurrency(currency);
//     }
//     setDialogOpen(false);
//   };

//   const handleSearch = (event) => {
//     const query = event.target.value.toLowerCase();
//     setSearchQuery(query);
    
//     const filtered = currencies.filter(
//       currency => 
//         currency.code.toLowerCase().includes(query) ||
//         currency.name.toLowerCase().includes(query)
//     );
    
//     setFilteredCurrencies(filtered);
//   };

//   const openCurrencyDialog = (inputType) => {
//     setCurrentInput(inputType);
//     setSearchQuery('');
//     setFilteredCurrencies(currencies);
//     setDialogOpen(true);
//   };

//   const handleKeypadPress = (value) => {
//     if (currentInput === 'from') {
//       if (value === 'backspace') {
//         setFromAmount(prev => prev.slice(0, -1) || '0');
//       } else if (value === 'clear') {
//         setFromAmount('0');
//       } else {
//         setFromAmount(prev => {
//           if (prev === '0' && value !== '.') return value;
//           if (value === '.' && prev.includes('.')) return prev;
//           return prev + value;
//         });
//       }
//       // Here we would convert and update toAmount based on exchange rates
//       // For demo, just multiply by a fixed rate
//       const rate = 1.36;
//       setToAmount((parseFloat(fromAmount + (value === 'backspace' ? '' : value)) * rate).toFixed(2));
//     } else {
//       if (value === 'backspace') {
//         setToAmount(prev => prev.slice(0, -1) || '0');
//       } else if (value === 'clear') {
//         setToAmount('0');
//       } else {
//         setToAmount(prev => {
//           if (prev === '0' && value !== '.') return value;
//           if (value === '.' && prev.includes('.')) return prev;
//           return prev + value;
//         });
//       }
//       // Here we would convert and update fromAmount based on exchange rates
//       // For demo, just divide by a fixed rate
//       const rate = 1.36;
//       setFromAmount((parseFloat(toAmount + (value === 'backspace' ? '' : value)) / rate).toFixed(2));
//     }
//   };

//   const swapCurrencies = () => {
//     setFromCurrency(toCurrency);
//     setToCurrency(fromCurrency);
//     setFromAmount(toAmount);
//     setToAmount(fromAmount);
//   };

//   return (
//     <Container maxWidth="xs" sx={{ p: 0 }}>
//       <Paper 
//         elevation={0}
//         sx={{ 
//           borderRadius: 4,
//           overflow: 'hidden',
//           bgcolor: '#f9f9f9',
//           height: '100vh'
//         }}
//       >
//         {/* Header */}
//         <Box sx={{ 
//           bgcolor: '#ffffff', 
//           p: 2, 
//           borderBottom: '1px solid #eaeaea',
//           textAlign: 'center'
//         }}>
//           <Typography variant="h6" sx={{ fontWeight: 600, color: '#333' }}>
//             Currency Converter
//           </Typography>
//           <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
//             Check live rates, set rate alerts, receive notifications and more.
//           </Typography>
//         </Box>

//         {/* Main content */}
//         <Box sx={{ p: 3 }}>
//           {/* From Currency */}
//           <Box sx={{ mb: 3 }}>
//             <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
//               Amount
//             </Typography>
//             <Paper
//               elevation={0}
//               sx={{ 
//                 display: 'flex', 
//                 alignItems: 'center', 
//                 p: 1.5, 
//                 bgcolor: '#f0f0f0',
//                 borderRadius: 2,
//                 border: currentInput === 'from' ? '2px solid #5469d4' : '2px solid transparent',
//                 transition: 'all 0.2s'
//               }}
//               onClick={() => setCurrentInput('from')}
//             >
//               <Button
//                 onClick={() => openCurrencyDialog('from')}
//                 sx={{ 
//                   minWidth: 'auto', 
//                   mr: 1,
//                   borderRadius: 2,
//                   textTransform: 'none'
//                 }}
//                 endIcon={<span style={{ fontSize: '0.7rem' }}>▼</span>}
//               >
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Typography variant="h6" component="span" sx={{ mr: 1 }}>
//                     {fromCurrency.flag}
//                   </Typography>
//                   <Typography variant="body1" component="span">
//                     {fromCurrency.code}
//                   </Typography>
//                 </Box>
//               </Button>
//               <Typography 
//                 variant="h5" 
//                 component="div"
//                 sx={{ 
//                   flexGrow: 1, 
//                   textAlign: 'right',
//                   fontWeight: 500
//                 }}
//               >
//                 {fromAmount}
//               </Typography>
//             </Paper>
//           </Box>

//           {/* Swap Button */}
//           <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
//             <IconButton
//               onClick={swapCurrencies}
//               sx={{ 
//                 bgcolor: '#5469d4', 
//                 color: 'white',
//                 '&:hover': { 
//                   bgcolor: '#4054b2',
//                 }
//               }}
//             >
//               <SwapVertIcon />
//             </IconButton>
//           </Box>

//           {/* To Currency */}
//           <Box sx={{ mb: 4 }}>
//             <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
//               Converted Amount
//             </Typography>
//             <Paper
//               elevation={0}
//               sx={{ 
//                 display: 'flex', 
//                 alignItems: 'center', 
//                 p: 1.5, 
//                 bgcolor: '#f0f0f0',
//                 borderRadius: 2,
//                 border: currentInput === 'to' ? '2px solid #5469d4' : '2px solid transparent',
//                 transition: 'all 0.2s'
//               }}
//               onClick={() => setCurrentInput('to')}
//             >
//               <Button
//                 onClick={() => openCurrencyDialog('to')}
//                 sx={{ 
//                   minWidth: 'auto', 
//                   mr: 1,
//                   borderRadius: 2,
//                   textTransform: 'none'
//                 }}
//                 endIcon={<span style={{ fontSize: '0.7rem' }}>▼</span>}
//               >
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   <Typography variant="h6" component="span" sx={{ mr: 1 }}>
//                     {toCurrency.flag}
//                   </Typography>
//                   <Typography variant="body1" component="span">
//                     {toCurrency.code}
//                   </Typography>
//                 </Box>
//               </Button>
//               <Typography 
//                 variant="h5" 
//                 component="div"
//                 sx={{ 
//                   flexGrow: 1, 
//                   textAlign: 'right',
//                   fontWeight: 500
//                 }}
//               >
//                 {toAmount}
//               </Typography>
//             </Paper>
//           </Box>

//           {/* Divider */}
//           <Divider sx={{ my: 2 }} />

//           {/* Exchange Rate Info */}
//           <Box sx={{ mb: 4 }}>
//             <Typography variant="subtitle2" color="text.secondary">
//               Exchange Rate
//             </Typography>
//             <Typography variant="body1" sx={{ mt: 1 }}>
//               1 {fromCurrency.code} = 1.36 {toCurrency.code}
//             </Typography>
//           </Box>

//           {/* Number Keypad */}
//           <Grid container spacing={1}>
//             {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => (
//               <Grid item xs={4} key={num}>
//                 <Button
//                   fullWidth
//                   onClick={() => handleKeypadPress(num.toString())}
//                   sx={{
//                     py: 1.5,
//                     borderRadius: 2,
//                     bgcolor: '#f0f0f0',
//                     color: '#333',
//                     fontSize: '1.2rem',
//                     '&:hover': {
//                       bgcolor: '#e0e0e0',
//                     }
//                   }}
//                 >
//                   {num}
//                 </Button>
//               </Grid>
//             ))}
//             <Grid item xs={4}>
//               <Button
//                 fullWidth
//                 onClick={() => handleKeypadPress('.')}
//                 sx={{
//                   py: 1.5,
//                   borderRadius: 2,
//                   bgcolor: '#f0f0f0',
//                   color: '#333',
//                   fontSize: '1.2rem',
//                   '&:hover': {
//                     bgcolor: '#e0e0e0',
//                   }
//                 }}
//               >
//                 .
//               </Button>
//             </Grid>
//             <Grid item xs={4}>
//               <Button
//                 fullWidth
//                 onClick={() => handleKeypadPress('0')}
//                 sx={{
//                   py: 1.5,
//                   borderRadius: 2,
//                   bgcolor: '#f0f0f0',
//                   color: '#333',
//                   fontSize: '1.2rem',
//                   '&:hover': {
//                     bgcolor: '#e0e0e0',
//                   }
//                 }}
//               >
//                 0
//               </Button>
//             </Grid>
//             <Grid item xs={4}>
//               <Button
//                 fullWidth
//                 onClick={() => handleKeypadPress('backspace')}
//                 sx={{
//                   py: 1.5,
//                   borderRadius: 2,
//                   bgcolor: '#f0f0f0',
//                   color: '#333',
//                   '&:hover': {
//                     bgcolor: '#e0e0e0',
//                   }
//                 }}
//               >
//                 <KeyboardBackspaceIcon />
//               </Button>
//             </Grid>
//           </Grid>
//         </Box>

//         {/* Bottom Navigation */}
//         <Box
//           sx={{
//             position: 'fixed',
//             bottom: 0,
//             left: 0,
//             right: 0,
//             borderTop: '1px solid #eaeaea',
//             bgcolor: '#ffffff',
//             display: 'flex',
//             justifyContent: 'space-evenly',
//             maxWidth: 'xs',
//             width: '100%',
//             mx: 'auto'
//           }}
//         >
//           <Button
//             sx={{ py: 1.5, color: '#333', flexGrow: 1 }}
//             startIcon={<AssessmentIcon />}
//           >
//             Chart
//           </Button>
//           <Divider orientation="vertical" flexItem />
//           <Button
//             sx={{ py: 1.5, color: '#333', flexGrow: 1 }}
//             startIcon={<SettingsIcon />}
//           >
//             Setting
//           </Button>
//         </Box>

//         {/* Currency Selection Dialog */}
//         <Dialog
//           open={dialogOpen}
//           onClose={() => setDialogOpen(false)}
//           fullWidth
//           maxWidth="xs"
//           PaperProps={{
//             sx: {
//               borderRadius: 4,
//               maxHeight: '80vh'
//             }
//           }}
//         >
//           <AppBar position="sticky" color="default" elevation={0} sx={{ borderBottom: '1px solid #eaeaea' }}>
//             <Toolbar>
//               <IconButton edge="start" color="inherit" onClick={() => setDialogOpen(false)}>
//                 <CloseIcon />
//               </IconButton>
//               <Box sx={{ ml: 1, flexGrow: 1 }}>
//                 <Paper
//                   component="form"
//                   sx={{ 
//                     p: '2px 4px', 
//                     display: 'flex', 
//                     alignItems: 'center',
//                     borderRadius: 2,
//                     bgcolor: '#f0f0f0',
//                   }}
//                 >
//                   <InputBase
//                     sx={{ ml: 1, flex: 1 }}
//                     placeholder="Search..."
//                     value={searchQuery}
//                     onChange={handleSearch}
//                     autoFocus
//                   />
//                   <IconButton sx={{ p: '10px' }} aria-label="search">
//                     <SearchIcon />
//                   </IconButton>
//                 </Paper>
//               </Box>
//             </Toolbar>
//           </AppBar>
//           <List sx={{ pt: 0 }}>
//             {filteredCurrencies.map((currency) => (
//               <ListItem 
//                 button 
//                 key={currency.code}
//                 onClick={() => handleCurrencySelect(currency)}
//               >
//                 <ListItemIcon sx={{ minWidth: 40 }}>
//                   <Typography variant="h6">{currency.flag}</Typography>
//                 </ListItemIcon>
//                 <ListItemText 
//                   primary={currency.code} 
//                   secondary={currency.name} 
//                 />
//               </ListItem>
//             ))}
//           </List>
//         </Dialog>
//       </Paper>
//     </Container>
//   );
// };

// export default CurrencyConverter;

import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Paper,
  InputAdornment,
  Divider,
  Tooltip,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { alpha } from "@mui/material/styles";

// Add this to your Dashboard component
const ConversionCoinPopup = () => {
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [coinAmount, setCoinAmount] = useState("");
  const [remark, setRemark] = useState("");
  const [inrValue, setInrValue] = useState(0);
  const [copied, setCopied] = useState(false);
  const [converting, setConverting] = useState(false);
  const [conversionSuccess, setConversionSuccess] = useState(false);

  // Metamask wallet address
  const walletAddress = "0xf9489Ef03ab1db48a92FE758A641B77B2bd616b4";

  // Coin conversion rate
  const coinRate = 21; // 1 coin = 21 INR

  const handleOpen = () => {
    setOpen(true);
    setConversionSuccess(false);
  };

  const handleClose = () => {
    setOpen(false);
    setCoinAmount("");
    setRemark("");
    setInrValue(0);
    setConverting(false);
    setConversionSuccess(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCoinAmountChange = (e) => {
    const value = e.target.value;
    setCoinAmount(value);
    if (value && !isNaN(value)) {
      setInrValue((parseFloat(value) * coinRate).toFixed(2));
    } else {
      setInrValue(0);
    }
  };

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(walletAddress);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleSubmit = () => {
    if (!coinAmount || isNaN(coinAmount) || parseFloat(coinAmount) <= 0) {
      return;
    }
    
    setConverting(true);
    
    // Simulate API call with timeout
    setTimeout(() => {
      setConverting(false);
      setConversionSuccess(true);
      setCoinAmount("");
      setRemark("");
      setInrValue(0);
    }, 1500);
  };

  return (
    <>
      {/* Conversion Coin Button - Add this to your Dashboard component near line 439 */}
      <Button
        onClick={handleOpen}
        variant="contained"
        size="large"
        // startIcon={<CurrencyBitcoinIcon />}
        sx={{
          position: "relative",
          overflow: "hidden",
          background: "linear-gradient(135deg, #FF9900, #FF5500)",
          border: "none",
          color: "#fff",
          fontWeight: "bold",
          py: 1.5,
          px: 3,
          borderRadius: "12px",
          textTransform: "none",
          fontSize: { xs: "0.9rem", sm: "1rem" },
          boxShadow: "0 10px 20px rgba(255, 153, 0, 0.3)",
          transition: "all 0.3s ease",
          "&:hover": {
            background: "linear-gradient(135deg, #FF5500, #FF9900)",
            transform: "translateY(-3px)",
            boxShadow: "0 15px 25px rgba(255, 153, 0, 0.4)",
          },
          "&:before": {
            content: '""',
            position: "absolute",
            top: "-50%",
            left: "-50%",
            width: "200%",
            height: "200%",
            background: "linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.3), rgba(255,255,255,0))",
            transform: "rotate(30deg)",
            animation: "shine 3s infinite linear",
          },
          "@keyframes shine": {
            "0%": { transform: "translateX(-100%) rotate(30deg)" },
            "100%": { transform: "translateX(100%) rotate(30deg)" }
          }
        }}
      >
        Conversion Coin
      </Button>

      {/* Popup Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "16px",
            background: "rgba(255, 255, 255, 0.95)",
            backdropFilter: "blur(10px)",
            boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
            overflow: "hidden"
          }
        }}
      >
        <DialogTitle
          sx={{
            background: "linear-gradient(135deg, #0f0c29, #302b63, #24243e)",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: 2,
            px: 3
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <CurrencyExchangeIcon />
            <Typography variant="h6" fontWeight="bold">
              Coin Conversion Portal
            </Typography>
          </Box>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            sx={{ color: "rgba(255,255,255,0.8)" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Box sx={{ px: 2, py: 1, borderBottom: 1, borderColor: "divider" }}>
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange} 
            variant="fullWidth"
            sx={{ 
              "& .MuiTab-root": {
                fontWeight: "bold",
                fontSize: "0.95rem",
                py: 2,
              },
              "& .Mui-selected": {
                color: "#302b63",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#302b63",
                height: 3,
                borderRadius: "3px 3px 0 0"
              }
            }}
          >
            <Tab 
              icon={<SwapHorizIcon />} 
              iconPosition="start"
              label="Convert Coins" 
            />
            <Tab 
              icon={<AccountBalanceWalletIcon />} 
              iconPosition="start"
              label="Wallet Address" 
            />
          </Tabs>
        </Box>

        <DialogContent sx={{ p: 3 }}>
          {tabValue === 0 ? (
            <Box>
              {/* Current Rate Display */}
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 2, 
                  mb: 3, 
                  background: "rgba(48, 43, 99, 0.05)", 
                  borderRadius: "12px",
                  border: "1px solid rgba(48, 43, 99, 0.1)"
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  color="textSecondary" 
                  sx={{ mb: 1, fontSize: "0.85rem" }}
                >
                  Current Conversion Rate
                </Typography>
                
                <Box display="flex" alignItems="center" gap={1}>
                  <Chip
                    icon={<CurrencyBitcoinIcon />}
                    label="1 Coin"
                    size="small"
                    sx={{ 
                      bgcolor: alpha("#302b63", 0.1), 
                      color: "#302b63",
                      fontWeight: "bold"
                    }}
                  />
                  <SwapHorizIcon sx={{ color: "#666" }} />
                  <Chip
                    label="₹21 INR"
                    size="small"
                    sx={{ 
                      bgcolor: alpha("#ff6a00", 0.1), 
                      color: "#ff6a00",
                      fontWeight: "bold"
                    }}
                  />
                </Box>
              </Paper>
              
              {conversionSuccess ? (
                <Box 
                  sx={{ 
                    textAlign: "center", 
                    py: 3,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2
                  }}
                >
                  <CheckCircleIcon 
                    sx={{ 
                      fontSize: 64, 
                      color: "green", 
                      animation: "pulse 1.5s ease-in-out" ,
                      "@keyframes pulse": {
                        "0%": { transform: "scale(0.8)", opacity: 0 },
                        "50%": { transform: "scale(1.1)" },
                        "100%": { transform: "scale(1)", opacity: 1 }
                      }
                    }} 
                  />
                  <Typography variant="h6" color="green" fontWeight="bold">
                    Conversion Successful!
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Your coins have been successfully converted to INR.
                  </Typography>
                  <Button 
                    variant="outlined" 
                    onClick={handleClose}
                    sx={{ 
                      mt: 2,
                      borderRadius: "8px",
                      color: "#302b63",
                      borderColor: "#302b63"
                    }}
                  >
                    Close
                  </Button>
                </Box>
              ) : (
                <Box component="form" sx={{ mt: 1 }}>
                  <TextField
                    fullWidth
                    label="Number of Coins"
                    variant="outlined"
                    value={coinAmount}
                    onChange={handleCoinAmountChange}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyBitcoinIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ mb: 3 }}
                  />
                  
                  <TextField
                    fullWidth
                    label="Equivalent INR Value"
                    variant="outlined"
                    value={inrValue === 0 ? "" : `₹${inrValue}`}
                    disabled
                    sx={{ 
                      mb: 3,
                      "& .MuiInputBase-input": {
                        fontWeight: "bold",
                        color: inrValue > 0 ? "#ff6a00" : "inherit"
                      }
                    }}
                  />
                  
                  <TextField
                    fullWidth
                    label="Remark (Optional)"
                    variant="outlined"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                    multiline
                    rows={2}
                    sx={{ mb: 3 }}
                  />
                  
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    onClick={handleSubmit}
                    disabled={!coinAmount || isNaN(coinAmount) || parseFloat(coinAmount) <= 0 || converting}
                    sx={{
                      background: "linear-gradient(135deg, #302b63, #24243e)",
                      color: "#fff",
                      fontWeight: "bold",
                      py: 1.5,
                      borderRadius: "8px",
                      "&:hover": {
                        background: "linear-gradient(135deg, #24243e, #302b63)",
                      },
                      "&:disabled": {
                        background: "#ccc",
                        color: "#666"
                      }
                    }}
                  >
                    {converting ? "Processing..." : "Convert Now"}
                  </Button>
                </Box>
              )}
            </Box>
          ) : (
            <Box sx={{ py: 2 }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: "medium" }}>
                Your MetaMask Wallet Address
              </Typography>
              
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  borderRadius: "12px",
                  background: "linear-gradient(to right bottom, rgba(48, 43, 99, 0.05), rgba(36, 36, 62, 0.08))",
                  border: "1px solid rgba(48, 43, 99, 0.1)",
                  position: "relative",
                  overflow: "hidden"
                }}
              >
                <Box 
                  sx={{ 
                    position: "absolute", 
                    top: 0, 
                    left: 0, 
                    width: "100%", 
                    height: "5px", 
                    background: "linear-gradient(90deg, #ff9900, #ff5500, #ff9900)",
                    backgroundSize: "200% auto",
                    animation: "gradient 3s linear infinite",
                    "@keyframes gradient": {
                      "0%": { backgroundPosition: "0% center" },
                      "100%": { backgroundPosition: "200% center" }
                    }
                  }} 
                />
                
                <Box display="flex" alignItems="center" gap={2}>
                  <AccountBalanceWalletIcon 
                    fontSize="large" 
                    sx={{ color: "#302b63" }} 
                  />
                  
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography 
                      variant="body2" 
                      color="textSecondary" 
                      sx={{ mb: 0.5, fontSize: "0.8rem" }}
                    >
                      Connected Wallet
                    </Typography>
                    
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        fontFamily: "monospace", 
                        fontWeight: "bold",
                        wordBreak: "break-all"
                      }}
                    >
                      {walletAddress}
                    </Typography>
                  </Box>
                  
                  <Tooltip 
                    title={copied ? "Copied!" : "Copy Address"} 
                    placement="top"
                  >
                    <IconButton
                      color={copied ? "success" : "primary"}
                      onClick={handleCopyAddress}
                      sx={{ ml: 1 }}
                    >
                      {copied ? <CheckCircleIcon /> : <ContentCopyIcon />}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Paper>
              
              <Box sx={{ mt: 4, p: 3, bgcolor: "rgba(255, 153, 0, 0.05)", borderRadius: "12px" }}>
                <Typography variant="subtitle2" color="warning.dark" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <CurrencyExchangeIcon fontSize="small" />
                  Transaction History
                </Typography>
                
                <Divider sx={{ my: 2 }} />
                
                <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
                  You can view your transaction history in the wallet application.
                </Typography>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConversionCoinPopup;
