// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Card,
//   CardContent,
//   Typography,
//   Skeleton,
//   useTheme,
//   useMediaQuery,
//   IconButton,
// } from "@mui/material";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/effect-cards";
// import "swiper/css/navigation";
// import { Pagination, Autoplay, EffectCards, Navigation } from "swiper/modules";
// import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
// import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
// import RefreshIcon from "@mui/icons-material/Refresh";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   Tooltip,
//   CartesianGrid,
//   ResponsiveContainer,
//   BarChart,
//   Bar,
// } from "recharts";
// import { useLocation } from "react-router-dom";

// const Dashboard = () => {
//   const [balance, setBalance] = useState(0);
//   const [totalUsers, setTotalUsers] = useState(0);
//   const [transactions, setTransactions] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const [refreshing, setRefreshing] = useState(false);

//   const [balanceData, setBalanceData] = useState([]);
//   const [transactionsData, setTransactionsData] = useState([]);

//   const location = useLocation();

//   useEffect(() => {
//     const queryParams = new URLSearchParams(location.search);

//     const token = queryParams.get("token");
//     const emp_role = queryParams.get("emp_role");
//     const status = queryParams.get("status");
//     const emp_name = queryParams.get("emp_name");
//     const emp_Id = queryParams.get("emp_Id");
//     console.log(emp_Id, emp_role);

//     if (token) {
//       sessionStorage.setItem("token", token);
//       sessionStorage.setItem("role", emp_role);
//       sessionStorage.setItem("status", status);
//       sessionStorage.setItem("name", emp_name);
//       sessionStorage.setItem("userId", emp_Id);
//     }
//   }, [location.search]);

//   const fetchChartData = async () => {
//     setRefreshing(true);
//     try {
//       // Mock Data for Charts (Replace with API call if available)
//       const data = [
//         { name: "Jan", balance: 5000, transactions: 20 },
//         { name: "Feb", balance: 7000, transactions: 35 },
//         { name: "Mar", balance: 6500, transactions: 30 },
//         { name: "Apr", balance: 8000, transactions: 40 },
//         { name: "May", balance: 9000, transactions: 50 },
//       ];

//       setBalanceData(data);
//       setTransactionsData(data);
//     } catch (error) {
//       console.error("Error fetching chart data:", error);
//     } finally {
//       setRefreshing(false);
//     }
//   };
//   const fetchUserCount = async () => {
//     const token = sessionStorage.getItem("token");
//     const emp_role = sessionStorage.getItem("role");

//     if (token && emp_role === "ADMIN") {
//       try {
//         const response = await fetch(
//           "https://api.erapay.biz/erapay/user/getcount",
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//             credentials: "include", // For sending cookies if needed
//           }
//         );

//         if (!response.ok) {
//           throw new Error(`Error: ${response.status}`);
//         }

//         const data = await response.json();
//         console.log("User count:", data.count);
//         setTotalUsers(data.count);
//       } catch (error) {
//         console.error("Error fetching user count:", error);
//       }
//     }
//   };
//   useEffect(() => {
//     fetchChartData();
//     fetchUserCount();
//   }, []);

//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

//   const isAdmin = sessionStorage.getItem("role") === "ADMIN";

//   const fetchData = async () => {
//     setRefreshing(true);
//     try {
//       const token = sessionStorage.getItem("token");

//       let response;
//       if (isAdmin) {
//         response = await fetch(
//           "https://api.erapay.biz/erapay/admin/allusersbalance",
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//       } else {
//         response = await fetch(
//           "https://api.erapay.biz/erapay/user/fetchbalance",
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//       }

//       if (!response.ok) {
//         throw new Error("Failed to fetch data");
//       }

//       const data = await response.json();

//       if (isAdmin) {
//         setBalance(data.data?.totalAmount || 0);
//         // setTotalUsers(data.totalUsers || 0);
//         setTransactions(data.totalTransactions || 0);
//       } else {
//         setBalance(data.balance || 0);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setLoading(false);
//       setRefreshing(false);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [isAdmin]);

//   const formatNumber = (num) => {
//     return new Intl.NumberFormat("en-IN").format(num);
//   };

//   const dashboardItems = [
//     {
//       title: "Total Balance",
//       value: formatNumber(balance),
//       prefix: "₹",
//       icon: <AccountBalanceWalletIcon />,
//       color: "linear-gradient(135deg, #33ccff, #3366ff)",
//       textColor: "#fff",
//     },
//     ...(isAdmin
//       ? [
//           {
//             title: "Total Users",
//             value: formatNumber(totalUsers),
//             icon: <PeopleAltIcon />,
//             color: "linear-gradient(135deg, #ff9966, #ff5e62)",
//             textColor: "#fff",
//           },
//           {
//             title: "Total Transactions",
//             value: formatNumber(transactions),
//             icon: <SwapHorizIcon />,
//             color: "linear-gradient(135deg, #56ab2f, #a8e063)",
//             textColor: "#fff",
//           },
//         ]
//       : []),
//   ];

//   const getSlidesPerView = () => {
//     if (isMobile) return 1;
//     if (isTablet) return 2;
//     return 3;
//   };

//   const getEffect = () => {
//     return isMobile ? "cards" : "slide";
//   };

//   return (
//     <div>
//       <Box
//         sx={{
//           background: "linear-gradient(135deg, #1a2980, #26d0ce)",
//           padding: { xs: "15px", sm: "20px", md: "30px" },
//           borderRadius: "15px",
//           boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.25)",
//           position: "relative",
//           overflow: "hidden",
//           "&::before": {
//             content: '""',
//             position: "absolute",
//             top: "-50%",
//             right: "-50%",
//             width: "100%",
//             height: "100%",
//             background:
//               "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)",
//             transform: "rotate(30deg)",
//           },
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             mb: 3,
//           }}
//         >
//           <Typography
//             variant="h5"
//             sx={{
//               color: "#fff",
//               fontWeight: "bold",
//               textShadow: "0px 2px 4px rgba(0,0,0,0.25)",
//               fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.8rem" },
//             }}
//           >
//             Financial Dashboard
//           </Typography>
//           <IconButton
//             onClick={() => fetchData()}
//             disabled={refreshing}
//             sx={{
//               color: "#fff",
//               backgroundColor: "rgba(255,255,255,0.2)",
//               "&:hover": { backgroundColor: "rgba(255,255,255,0.3)" },
//               animation: refreshing ? "spin 1s linear infinite" : "none",
//               "@keyframes spin": {
//                 "0%": { transform: "rotate(0deg)" },
//                 "100%": { transform: "rotate(360deg)" },
//               },
//             }}
//           >
//             <RefreshIcon />
//           </IconButton>
//         </Box>

//         <Swiper
//           effect={getEffect()}
//           slidesPerView={getSlidesPerView()}
//           spaceBetween={20}
//           pagination={{ clickable: true, dynamicBullets: true }}
//           autoplay={{
//             delay: 3000,
//             disableOnInteraction: false,
//             pauseOnMouseEnter: true,
//           }}
//           loop={true}
//           modules={[Pagination, Autoplay, EffectCards, Navigation]}
//           navigation={!isMobile}
//           grabCursor={true}
//           style={{ paddingBottom: "30px" }}
//         >
//           {dashboardItems.map((item, index) => (
//             <SwiperSlide key={index}>
//               <Card
//                 sx={{
//                   minHeight: { xs: 180, md: 200 },
//                   textAlign: "center",
//                   p: { xs: 2, sm: 3 },
//                   transition:
//                     "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
//                   boxShadow: "0px 10px 20px rgba(0,0,0,0.15)",
//                   borderRadius: "16px",
//                   background: item.color,
//                   "&:hover": {
//                     transform: "translateY(-10px)",
//                     boxShadow: "0px 15px 30px rgba(0,0,0,0.25)",
//                   },
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                 }}
//               >
//                 <CardContent>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       mb: 1,
//                     }}
//                   >
//                     <Box
//                       sx={{
//                         backgroundColor: "rgba(255,255,255,0.2)",
//                         borderRadius: "50%",
//                         p: 1,
//                         mr: 1,
//                         display: "flex",
//                         color: item.textColor,
//                       }}
//                     >
//                       {item.icon}
//                     </Box>
//                     <Typography
//                       variant="h6"
//                       sx={{
//                         color: item.textColor,
//                         fontWeight: "500",
//                         fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
//                       }}
//                     >
//                       {item.title}
//                     </Typography>
//                   </Box>
//                   {loading ? (
//                     <Skeleton
//                       variant="text"
//                       width="80%"
//                       height={60}
//                       sx={{
//                         backgroundColor: "rgba(255,255,255,0.1)",
//                         mx: "auto",
//                       }}
//                     />
//                   ) : (
//                     <Typography
//                       variant="h4"
//                       sx={{
//                         color: item.textColor,
//                         fontWeight: "bold",
//                         fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2.2rem" },
//                         mt: 1,
//                         animation: "fadeIn 0.8s ease-in-out",
//                         "@keyframes fadeIn": {
//                           "0%": { opacity: 0, transform: "translateY(10px)" },
//                           "100%": { opacity: 1, transform: "translateY(0)" },
//                         },
//                       }}
//                     >
//                       {item.prefix || ""}
//                       {item.value}
//                     </Typography>
//                   )}
//                 </CardContent>
//               </Card>
//             </SwiperSlide>
//           ))}
//         </Swiper>
//       </Box>
//       <Box sx={{ padding: "20px" }}>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             mb: 3,
//           }}
//         >
//           <Typography variant="h5" sx={{ fontWeight: "bold" }}>
//             Financial Dashboard
//           </Typography>
//           <IconButton onClick={fetchChartData} disabled={refreshing}>
//             <RefreshIcon />
//           </IconButton>
//         </Box>

//         {/* Line Chart */}
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             width: "100%",
//           }}
//         >
//           <Box
//             sx={{
//               width: "50%",
//               marginTop: "20px",
//               backgroundColor: "#fff",
//               padding: "20px",
//               borderRadius: "10px",
//               boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
//             }}
//           >
//             <Typography variant="h6" sx={{ mb: 2 }}>
//               Balance Trend
//             </Typography>
//             <ResponsiveContainer width="100%" height={300}>
//               <LineChart data={balanceData}>
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="name" />
//                 <YAxis />
//                 <Tooltip />
//                 <Line
//                   type="monotone"
//                   dataKey="balance"
//                   stroke="#8884d8"
//                   strokeWidth={2}
//                 />
//               </LineChart>
//             </ResponsiveContainer>
//           </Box>

//           {/* Bar Chart */}
//           <Box
//             sx={{
//               width: "50%",
//               marginTop: "20px",
//               ml: "5%",
//               backgroundColor: "#fff",
//               padding: "20px",
//               borderRadius: "10px",
//               boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
//             }}
//           >
//             <Typography variant="h6" sx={{ mb: 2 }}>
//               Transactions Overview
//             </Typography>
//             <ResponsiveContainer width="100%" height={300}>
//               <BarChart data={transactionsData}>
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="name" />
//                 <YAxis />
//                 <Tooltip />
//                 <Bar dataKey="transactions" fill="#82ca9d" />
//               </BarChart>
//             </ResponsiveContainer>
//           </Box>
//         </Box>
//       </Box>
//     </div>
//   );
// };

// export default Dashboard;

import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Skeleton,
  useTheme,
  useMediaQuery,
  IconButton,
  Paper,
  Divider,
  Container,
  Grid,
  alpha,
  Button,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import { Pagination, Autoplay, EffectCards, Navigation } from "swiper/modules";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import RefreshIcon from "@mui/icons-material/Refresh";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import BarChartIcon from "@mui/icons-material/BarChart";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
  Area,
  AreaChart,
} from "recharts";
import { useLocation } from "react-router-dom";
import FinancialDashboard from "./FinancialDashboard";

const Dashboard = () => {
  const [balance, setBalance] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [transactions, setTransactions] = useState(0);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);

  const [balanceData, setBalanceData] = useState([]);
  const [transactionsData, setTransactionsData] = useState([]);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const token = queryParams.get("token");
    const emp_role = queryParams.get("emp_role");
    const status = queryParams.get("status");
    const emp_name = queryParams.get("emp_name");
    const emp_Id = queryParams.get("emp_Id");
    console.log(emp_Id, emp_role);

    if (token) {
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("role", emp_role);
      sessionStorage.setItem("status", status);
      sessionStorage.setItem("name", emp_name);
      sessionStorage.setItem("userId", emp_Id);
    }
  }, [location.search]);

  const fetchChartData = async () => {
    setRefreshing(true);
    try {
      // Mock Data for Charts (Replace with API call if available)
      const data = [
        { name: "Jan", balance: 5000, transactions: 20, growth: 0 },
        { name: "Feb", balance: 7000, transactions: 35, growth: 40 },
        { name: "Mar", balance: 6500, transactions: 30, growth: -7 },
        { name: "Apr", balance: 8000, transactions: 40, growth: 23 },
        { name: "May", balance: 9000, transactions: 50, growth: 12.5 },
        { name: "Jun", balance: 11000, transactions: 45, growth: 22 },
      ];

      setBalanceData(data);
      setTransactionsData(data);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    } finally {
      setRefreshing(false);
    }
  };

  const fetchUserCount = async () => {
    const token = sessionStorage.getItem("token");
    const emp_role = sessionStorage.getItem("role");

    if (token && emp_role === "ADMIN") {
      try {
        const response = await fetch(
          "https://api.erapay.biz/erapay/user/getcount",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            credentials: "include", // For sending cookies if needed
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        console.log("User count:", data.count);
        setTotalUsers(data.count);
      } catch (error) {
        console.error("Error fetching user count:", error);
      }
    }
  };

  useEffect(() => {
    fetchChartData();
    fetchUserCount();
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const isAdmin = sessionStorage.getItem("role") === "ADMIN";

  const fetchData = async () => {
    setRefreshing(true);
    try {
      const token = sessionStorage.getItem("token");

      let response;
      if (isAdmin) {
        response = await fetch(
          "https://api.erapay.biz/erapay/admin/allusersbalance",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        response = await fetch(
          "https://api.erapay.biz/erapay/user/fetchbalance",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      if (isAdmin) {
        setBalance(data.data?.totalAmount || 0);
        setTransactions(data.totalTransactions || 0);
      } else {
        setBalance(data.balance || 0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isAdmin]);

  const formatNumber = (num) => {
    return new Intl.NumberFormat("en-IN").format(num);
  };

  const dashboardItems = [
    {
      title: "Total Balance",
      value: formatNumber(balance),
      prefix: "₹",
      icon: <AccountBalanceWalletIcon />,
      color: "linear-gradient(135deg, #0052d4, #4364f7, #6fb1fc)",
      textColor: "#fff",
      growth: "+12.5% this month",
    },
    ...(isAdmin
      ? [
        {
          title: "Total Users",
          value: formatNumber(totalUsers),
          icon: <PeopleAltIcon />,
          color: "linear-gradient(135deg, #ff6a00, #ee0979)",
          textColor: "#fff",
          growth: "+8.3% this month",
        },
        {
          title: "Total Transactions",
          value: formatNumber(transactions),
          icon: <SwapHorizIcon />,
          color: "linear-gradient(135deg, #11998e, #38ef7d)",
          textColor: "#fff",
          growth: "+15.2% this month",
        },
      ]
      : []),
  ];

  const getSlidesPerView = () => {
    if (isMobile) return 1;
    if (isTablet) return 2;
    return isAdmin ? 3 : 1;
  };

  const getEffect = () => {
    return isMobile ? "cards" : "slide";
  };

  // Custom tooltip for charts
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            border: "1px solid #ccc",
            p: 2,
            borderRadius: "8px",
            boxShadow: "0px 3px 8px rgba(0,0,0,0.15)",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ color: "#333", fontWeight: "bold", mb: 1 }}
          >
            {label}
          </Typography>
          {payload.map((entry, index) => (
            <Typography
              key={`item-${index}`}
              variant="body2"
              sx={{ color: entry.color, mb: 0.5 }}
            >
              {`${entry.name}: ${entry.dataKey === "growth" ? entry.value + "%" : "₹" + entry.value}`}
            </Typography>
          ))}
        </Box>
      );
    }
    return null;
  };

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      {/* Header Section with Glassmorphism */}
      <Paper
        elevation={0}
        sx={{
          background: "rgba(255, 255, 255, 0.8)",
          backdropFilter: "blur(10px)",
          borderRadius: "16px",
          overflow: "hidden",
          mb: 4,
          position: "relative",
          border: "1px solid rgba(255, 255, 255, 0.3)",
        }}
      >
        <Box
          sx={{
            background: "linear-gradient(135deg, #0f0c29, #302b63, #24243e)",
            padding: { xs: "20px", sm: "25px", md: "35px" },
            position: "relative",
            overflow: "hidden",
            zIndex: 1,
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.15)",
            "&::before": {
              content: '""',
              position: "absolute",
              top: "-100%",
              right: "-100%",
              width: "200%",
              height: "200%",
              background:
                "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)",
              transform: "rotate(30deg)",
              zIndex: -1,
            },
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: "-50%",
              left: "-50%",
              width: "100%",
              height: "100%",
              background:
                "radial-gradient(circle, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 60%)",
              zIndex: -1,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <FinancialDashboard />

            {/* Refresh Icon Button */}
            <IconButton
              onClick={() => fetchData()}
              disabled={refreshing}
              sx={{
                color: "#fff",
                backgroundColor: "rgba(255,255,255,0.15)",
                "&:hover": { backgroundColor: "rgba(255,255,255,0.25)" },
                animation: refreshing ? "spin 1s linear infinite" : "none",
                "@keyframes spin": {
                  "0%": { transform: "rotate(0deg)" },
                  "100%": { transform: "rotate(360deg)" },
                },
                transition: "all 0.3s ease",
                p: { xs: 1, sm: 1.2, md: 1.5 },
                boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
              }}
            >
              <RefreshIcon fontSize="medium" />
            </IconButton>
          </Box>


          <Swiper
            effect={getEffect()}
            slidesPerView={getSlidesPerView()}
            spaceBetween={20}
            pagination={{ clickable: true, dynamicBullets: true }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            loop={dashboardItems.length > 1}
            modules={[Pagination, Autoplay, EffectCards, Navigation]}
            navigation={!isMobile && dashboardItems.length > 1}
            grabCursor={true}
            style={{ paddingBottom: "40px" }}
          >
            {dashboardItems.map((item, index) => (
              <SwiperSlide key={index}>
                <Card
                  sx={{
                    minHeight: { xs: 200, md: 220 },
                    textAlign: "center",
                    p: { xs: 2, sm: 3 },
                    transition:
                      "all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                    boxShadow: "0px 15px 30px rgba(0,0,0,0.2)",
                    borderRadius: "16px",
                    background: item.color,
                    overflow: "hidden",
                    position: "relative",
                    "&:hover": {
                      transform: "translateY(-10px)",
                      boxShadow: "0px 20px 40px rgba(0,0,0,0.3)",
                    },
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      background:
                        "linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,0))",
                      zIndex: 0,
                    },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <CardContent sx={{ position: "relative", zIndex: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mb: 2,
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "rgba(255,255,255,0.2)",
                          borderRadius: "50%",
                          p: 1.5,
                          mr: 1.5,
                          display: "flex",
                          color: item.textColor,
                          boxShadow: "0px 5px 15px rgba(0,0,0,0.1)",
                        }}
                      >
                        {item.icon}
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{
                          color: item.textColor,
                          fontWeight: "600",
                          fontSize: { xs: "1rem", sm: "1.1rem", md: "1.2rem" },
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                    {loading ? (
                      <Skeleton
                        variant="text"
                        width="80%"
                        height={60}
                        sx={{
                          backgroundColor: "rgba(255,255,255,0.1)",
                          mx: "auto",
                        }}
                      />
                    ) : (
                      <>
                        <Typography
                          variant="h3"
                          sx={{
                            color: item.textColor,
                            fontWeight: "800",
                            fontSize: {
                              xs: "1.8rem",
                              sm: "2.2rem",
                              md: "2.5rem",
                            },
                            mt: 1,
                            mb: 1,
                            textShadow: "0px 2px 5px rgba(0,0,0,0.15)",
                            animation: "fadeIn 0.8s ease-in-out",
                            "@keyframes fadeIn": {
                              "0%": {
                                opacity: 0,
                                transform: "translateY(10px)",
                              },
                              "100%": { opacity: 1, transform: "translateY(0)" },
                            },
                          }}
                        >
                          {item.prefix || ""}
                          {item.value}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: alpha(item.textColor, 0.9),
                            fontWeight: "500",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: { xs: "0.75rem", md: "0.85rem" },
                          }}
                        >
                          <TrendingUpIcon
                            fontSize="small"
                            sx={{ mr: 0.5, fontSize: "1rem" }}
                          />
                          {item.growth}
                        </Typography>
                      </>
                    )}
                  </CardContent>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Paper>

      {/* Chart Section */}
      <Typography
        variant="h5"
        sx={{
          fontWeight: "700",
          mb: 3,
          color: "#1a237e",
          borderLeft: "4px solid #1a237e",
          pl: 2,
          py: 1,
        }}
      >
        Financial Analytics
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={0}
            sx={{
              p: 3,
              borderRadius: "16px",
              background:
                "linear-gradient(to right bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.8))",
              backdropFilter: "blur(10px)",
              boxShadow:
                "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1)",
              border: "1px solid rgba(255, 255, 255, 0.18)",
              height: "100%",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              "&:hover": {
                transform: "translateY(-5px)",
                boxShadow:
                  "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 3,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TrendingUpIcon
                  sx={{
                    color: "primary.main",
                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    p: 1,
                    borderRadius: "12px",
                    mr: 2,
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", color: "#333" }}
                >
                  Balance Trend
                </Typography>
              </Box>
              <IconButton
                onClick={fetchChartData}
                disabled={refreshing}
                size="small"
                sx={{
                  color: theme.palette.primary.main,
                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.primary.main, 0.2),
                  },
                  animation: refreshing ? "spin 1s linear infinite" : "none",
                  "@keyframes spin": {
                    "0%": { transform: "rotate(0deg)" },
                    "100%": { transform: "rotate(360deg)" },
                  },
                }}
              >
                <RefreshIcon fontSize="small" />
              </IconButton>
            </Box>
            <Typography
              variant="body2"
              sx={{ color: "#666", mb: 3, fontSize: "0.85rem" }}
            >
              Track your balance growth over the past 6 months
            </Typography>
            <ResponsiveContainer width="100%" height={280}>
              <AreaChart
                data={balanceData}
                margin={{ top: 10, right: 10, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorBalance" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor="#4364f7"
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor="#4364f7"
                      stopOpacity={0}
                    />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                <XAxis
                  dataKey="name"
                  tick={{ fill: "#666" }}
                  axisLine={{ stroke: "#ddd" }}
                />
                <YAxis
                  tick={{ fill: "#666" }}
                  axisLine={{ stroke: "#ddd" }}
                  tickFormatter={(value) => `₹${value}`}
                />
                <Tooltip content={<CustomTooltip />} />
                <Area
                  type="monotone"
                  dataKey="balance"
                  stroke="#4364f7"
                  strokeWidth={3}
                  fillOpacity={1}
                  fill="url(#colorBalance)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper
            elevation={0}
            sx={{
              p: 3,
              borderRadius: "16px",
              background:
                "linear-gradient(to right bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.8))",
              backdropFilter: "blur(10px)",
              boxShadow:
                "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1)",
              border: "1px solid rgba(255, 255, 255, 0.18)",
              height: "100%",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              "&:hover": {
                transform: "translateY(-5px)",
                boxShadow:
                  "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 3,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <BarChartIcon
                  sx={{
                    color: "#ff6a00",
                    backgroundColor: alpha("#ff6a00", 0.1),
                    p: 1,
                    borderRadius: "12px",
                    mr: 2,
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", color: "#333" }}
                >
                  Transactions Overview
                </Typography>
              </Box>
              <IconButton
                onClick={fetchChartData}
                disabled={refreshing}
                size="small"
                sx={{
                  color: "#ff6a00",
                  backgroundColor: alpha("#ff6a00", 0.1),
                  "&:hover": { backgroundColor: alpha("#ff6a00", 0.2) },
                  animation: refreshing ? "spin 1s linear infinite" : "none",
                }}
              >
                <RefreshIcon fontSize="small" />
              </IconButton>
            </Box>
            <Typography
              variant="body2"
              sx={{ color: "#666", mb: 3, fontSize: "0.85rem" }}
            >
              Monthly transaction volume and growth rate
            </Typography>
            <ResponsiveContainer width="100%" height={280}>
              <BarChart
                data={transactionsData}
                margin={{ top: 10, right: 10, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                <XAxis
                  dataKey="name"
                  tick={{ fill: "#666" }}
                  axisLine={{ stroke: "#ddd" }}
                />
                <YAxis
                  yAxisId="left"
                  tick={{ fill: "#666" }}
                  axisLine={{ stroke: "#ddd" }}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  tick={{ fill: "#666" }}
                  axisLine={{ stroke: "#ddd" }}
                  tickFormatter={(value) => `${value}%`}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar
                  yAxisId="left"
                  dataKey="transactions"
                  fill="#ff9966"
                  radius={[4, 4, 0, 0]}
                  name="Transactions"
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="growth"
                  stroke="#ee0979"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  activeDot={{ r: 6 }}
                  name="Growth Rate (%)"
                />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;