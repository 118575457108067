import React from 'react'
import Header from './Header'
import HomePageFirst from './HomePageFirst'
import HomeMarquee from './HomeMorque'
import HomePageTwo from './HomePageTwo'
import HomePageThree from './HomePageThree'
import HomePageFour from './HomePageFour'
import Footer from './Footer'
import HomeFaqFive from './HomeFaqFive'
import ContactUsPage from './ContactUsPage'

const LandingPage = () => {
  return (
    <div>
      <Header />
      <HomePageFirst />
      <HomePageTwo />
      <HomePageThree />
      <HomePageFour />         
      <HomeFaqFive/>
      <ContactUsPage />
      <Footer />
    </div>
  )
}

export default LandingPage
