import React, { useState, useEffect, useContext } from "react";
import { Button, Box, Typography, TextField, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";  // Import the Add icon
import UserTable from "./UserTable"; // Assuming UserTable is in the same directory
import EditUserDialog from "./EditUserDialog";
import AddUserDialog from "./adduserdialog"; 
import { ThemeContext } from "../ThemeContext";

const Users = () => {
  const { mode, toggleTheme } = useContext(ThemeContext);

  const [open, setOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false); // For the EditUserDialog
  const [partners, setPartners] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tablemsg, setTablemsg] = useState("");
  const [selectedUser, setSelectedUser] = useState(null); // State for selected user to edit
  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle opening and closing of the Add User dialog
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    fetchUsersData();
  };

  // Function to handle opening the Edit User dialog with user data
  const handleEditOpen = (user) => {
    setSelectedUser(user); // Set the selected user
    setOpenEditDialog(true); // Open the EditUserDialog
  };

  // Function to handle closing the Edit User dialog
  const handleEditClose = () => {
    setOpenEditDialog(false);
    setSelectedUser(null);  // Clear the selected user
    fetchUsersData();  // Reload user data
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);

    // Agar search field empty ho jaye, toh wapas se data fetch karo
    if (value.trim() === "") {
      setCurrentPage(0);
      fetchUsersData();
    }
  };

  const handleSearchSubmit = (event) => {
    if (event.key === "Enter") {
      setCurrentPage(0);
      fetchUsersData();
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setCurrentPage(0);
  };


  // API call to fetch users data
  const fetchUsersData = async () => {
    const token = sessionStorage.getItem("token");

    try {
      const response = await fetch(`https://api.erapay.biz/erapay/admin/users`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          searchBy: searchQuery,
          currPage: currentPage,
          pageSize: pageSize,
        }
      });

      const result = await response.json();

      if (response.ok && result.success) {
        setPartners(result.data.content || []);
        setTotalRecords(result.data.totalElements || 0);
      } else {
        setTablemsg(result.message || "Failed to load users data.");
      }
    } catch (error) {
      console.error("Error fetching users data:", error);
      setTablemsg("Failed to load users data.");
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, [currentPage, pageSize]);

  return (
    <div style={{ padding: "20px" }}>


      <AddUserDialog open={open} handleClose={handleClose} />

      {/* Pass the selected user data to EditUserDialog */}
      <EditUserDialog
        open={openEditDialog}
        handleClose={handleEditClose}
        user={selectedUser} // Pass user data to the EditUserDialog
      />

      <UserTable
        handleSearchChange={handleSearchChange}
        handleSearchSubmit={handleSearchSubmit}
        handleOpen={handleOpen}
        searchQuery={searchQuery}
        partners={partners}
        tablemsg={tablemsg}
        handleViewOpen={() => { }}
        handleEditOpen={handleEditOpen} // Pass handleEditOpen function to handle edit action
        handleOpenModal={() => { }}
        handleStatusToggle={() => { }}
        pageSize={pageSize}
        currentPage={currentPage}
        totalRecords={totalRecords}
        mode={mode}

        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
      />
    </div>
  );
};

export default Users;
