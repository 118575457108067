import React, { useEffect, useState } from "react";
import AddGiftCard from "./AddGiftCard";
import FetchGiftCards from "./FetchGiftCards";
import { Card, CardContent, Typography, Button } from "@mui/material";

const GiftCard = () => {
  const [refresh, setRefresh] = useState(false);
  const [card, setCard] = useState(false);
  const [cardData, setCardData] = useState({});
  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const myCardFetch = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await fetch(
        "https://api.erapay.biz/erapay/user/mycard",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      console.log(data);
      if (data?.success) {
        setCard(true);
        setCardData(data?.card);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    myCardFetch();
  }, []);

  return (
    <Card sx={{ p: 2, mt: 2 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Card Management
        </Typography>
        {!card && <AddGiftCard onGiftCardAdded={handleRefresh} />}
        {card && <FetchGiftCards cardDetails={cardData} refresh={refresh} />}
      </CardContent>
    </Card>
  );
};

export default GiftCard;
