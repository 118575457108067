import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
    Box,
} from "@mui/material";
// import { updateUser } from "./api";
import { toast } from "react-toastify";
import { updateUser } from "./api";

const EditUserDialog = ({ open, handleClose, user, handleSave, onEditComplete }) => {
    console.log(user);
    const [userData, setUserData] = useState({
        userId: "",
        name: "",
        adhaar: "",
        role: {
            userType: "",
            id: "",
        },
        pan: "",
        email: "",
        mobile: "",
        status: "",
        dob: "",
        walletAddress: "",
    });

    useEffect(() => {
        if (user) {
            setUserData({
                userId: user.userId || "",
                name: user.name || "",
                adhaar: user.adhaar || "",
                role: {
                    userType: user.role?.userType || "",
                    id: user.role?.id || "", // Make sure to handle both 'userType' and 'id'
                },
                pan: user.pan || "",
                email: user.email || "",
                mobile: user.mobile || "",
                status: user.status || "",
                dob: user.dob || "",
                walletAddress: user.walletAddress || "",
            });
        }
    }, [user]);

    const handleCancel = () => {
        handleClose();
        setUserData({
            userId: "",
            name: "",
            adhaar: "",
            role: {
                userType: "",
                id: "",
            },
            pan: "",
            email: "",
            mobile: "",
            status: "",
            dob: "",
            walletAddress: "",
        });
    };

    const handleSaveUser = async () => {
        if (!userData.userId || !userData.name || !userData.role.userType || !userData.pan || !userData.email || !userData.status || !userData.dob) {
            toast.warn("All fields except middle name and mobile number are required.");
            return;
        }

        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(userData.email)) {
            toast.error("Invalid email format.");
            return;
        }

        try {
            const response = await updateUser(userData);
            const statusCode = response?.statusCodeValue;
            const message = response?.body?.message || "Unexpected response from server.";

            switch (statusCode) {
                case 200:
                    toast.success(message, { position: "top-right", autoClose: 3000, theme: "colored" });

                    // Ensure the parent component updates properly
                    handleSave(userData);

                    // Close the modal
                    handleClose();

                    // Optional: Ensure parent component refreshes data
                    if (typeof onEditComplete === "function") {
                        onEditComplete();
                    }
                    break;
                case 404:
                    toast.error(message, { position: "top-right", autoClose: 5000, theme: "colored" });
                    break;
                case 406:
                    toast.warn(message, { position: "top-right", autoClose: 5000, theme: "colored" });
                    break;
                case 500:
                    toast.error(message, { position: "top-right", autoClose: 5000, theme: "colored" });
                    break;
                default:
                    toast.info(message);
                    break;
            }
        } catch (error) {
            toast.error("Failed to update user!", { position: "top-right", autoClose: 5000, theme: "colored" });
        }
    };



    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleAddressChange = (event) => {
        const { name, value } = event.target;
        setUserData((prevData) => ({
            ...prevData,
            status: {
                ...prevData.status,
                [name]: value,
            },
        }));
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            sx={{
                "& .MuiDialog-paper": {
                    borderRadius: "12px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                },
            }}
        >
            <DialogTitle
                sx={{
                    background: "linear-gradient(45deg, #027BFE,rgb(158, 79, 249))",
                    color: "white",
                    textAlign: "center",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    py: 2,
                }}
            >
                Edit User Details
            </DialogTitle>

            <DialogContent
                sx={{
                    p: 4,
                    maxHeight: "500px",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": { width: "6px" },
                    "&::-webkit-scrollbar-thumb": { background: "#027BFE", borderRadius: "10px" },
                }}
            >
                <Grid container spacing={2} mt={4}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="First Name"
                            name="name"
                            fullWidth
                            variant="outlined"
                            value={userData.name}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Middle Name"
                            name="adhaar"
                            fullWidth
                            variant="outlined"
                            value={userData.adhaar}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Role"
                            name="role.userType"
                            fullWidth
                            variant="outlined"
                            value={userData.role.userType}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Business Name"
                            name="pan"
                            fullWidth
                            variant="outlined"
                            value={userData.pan}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Email"
                            name="email"
                            fullWidth
                            variant="outlined"
                            value={userData.email}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Mobile Number"
                            name="mobile"
                            fullWidth
                            variant="outlined"
                            value={userData.mobile}
                            InputProps={{ readOnly: true }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    {/* Address Fields */}

                    <Grid item xs={12}>
                        <TextField label="Status" name="status" fullWidth variant="outlined" value={userData.status || ""} onChange={handleAddressChange} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Date of Birth"
                            name="dob"
                            fullWidth
                            variant="outlined"
                            value={userData.dob}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Wallet Address"
                            name="walletAddress"
                            fullWidth
                            variant="outlined"
                            value={userData.walletAddress}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions sx={{ p: 2, justifyContent: "center" }}>
                <Button
                    onClick={handleCancel}
                    variant="contained"
                    sx={{
                        backgroundColor: "#FF3B30",
                        color: "#ffffff",
                        "&:hover": { backgroundColor: "#D32F2F" }
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSaveUser}
                    variant="contained"
                    sx={{
                        backgroundColor: "#027BFE",
                        color: "#ffffff",
                        "&:hover": { backgroundColor: "#0056b3" }
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditUserDialog;
