// import React, { useState, useContext } from "react";
// import {
//   Drawer,
//   List,
//   ListItem,
//   IconButton,
//   Box,
//   Divider,
//   useMediaQuery,
//   Switch,
//   FormControlLabel,
//   Tooltip,
//   TextField,
//   Menu,
//   MenuItem,
// } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import MenuIcon from "@mui/icons-material/Menu";
// import DashboardIcon from "@mui/icons-material/Dashboard";
// import LeaderboardIcon from "@mui/icons-material/Leaderboard";
// import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
// import StarIcon from "@mui/icons-material/Star";
// import GroupIcon from "@mui/icons-material/Group";
// import SettingsIcon from "@mui/icons-material/Settings";
// import ReportIcon from "@mui/icons-material/Report";
// import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
// import LogoutIcon from "@mui/icons-material/Logout";
// import HomeIcon from "@mui/icons-material/Home";
// import InfoIcon from "@mui/icons-material/Info";
// import ContactMailIcon from "@mui/icons-material/ContactMail";
// import { ThemeContext } from "../ThemeContext";
// import { useNavigate } from "react-router-dom";
// import { Assessment, CreditCard } from "@mui/icons-material";

// const DrawerComponent = ({ onSelectLabel }) => {
//   const [open, setOpen] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const isMobile = useMediaQuery("(max-width: 768px)");
//   const isTablet = useMediaQuery("(max-width: 1024px)");
//   const navigate = useNavigate();

//   // Access theme mode and toggle function
//   const { mode, toggleTheme } = useContext(ThemeContext);
//   const token = sessionStorage.getItem("token");
//   const isAdmin = sessionStorage.getItem("role") === "ADMIN";

//   const menuItems = token
//     ? [
//         { icon: <DashboardIcon />, label: "Dashboard", path: "/dashboard" },
//         ...(isAdmin
//           ? [
//               { icon: <GroupIcon />, label: "Users", path: "/users" },

//               {
//                 icon: <LeaderboardIcon />,
//                 label: "Card List",
//                 path: "/AllCardsList",
//               },
//               {
//                 icon: <CardGiftcardIcon />,
//                 label: "Applied cards",
//                 path: "/allcards",
//               },
//             ]
//           : []),

//         {
//           icon: <Assessment />,
//           label: "Reports",
//           path: "/AllPayInList",
//         },
//         {
//           icon: <CreditCard />,
//           label: "Cards",
//           path: "/card",
//         },
//         // { icon: <SettingsSuggestIcon />, label: "Advanced Settings" },
//         {
//           icon: (
//             <FormControlLabel
//               control={
//                 <Switch
//                   checked={mode === "dark"}
//                   onChange={toggleTheme}
//                   sx={{
//                     "& .MuiSwitch-switchBase.Mui-checked": {
//                       color: "#bb86fc",
//                     },
//                     "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
//                       backgroundColor: "#bb86fc",
//                     },
//                     marginLeft: { xs: 3, md: 3, lg: 3 },
//                   }}
//                 />
//               }
//             />
//           ),
//           label: "Theme Toggle",
//           isSwitch: true,
//         },
//         { icon: <LogoutIcon />, label: "Logout" },
//       ]
//     : [
//         { icon: <HomeIcon />, label: "Home" },
//         { icon: <InfoIcon />, label: "About" },
//         { icon: <SettingsSuggestIcon />, label: "Settings" },
//         { icon: <ContactMailIcon />, label: "Contact" },
//       ];

//   const handleMenuItemClick = (label) => {
//     onSelectLabel(label); // Pass the label to the parent component

//     // Find the path of the selected menu item
//     const selectedItem = menuItems.find((item) => item.label === label);

//     // If a valid path exists, navigate to it; else, handle the label case
//     if (selectedItem && selectedItem.path) {
//       navigate(selectedItem.path); // Navigate to the specific path
//     }
//     if (isMobile) {
//       setOpen(false);
//     }
//   };

//   // For Dropdown Menu (mobile)
//   const handleDropdownClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleDropdownClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <>
//       {/* Show Hamburger Menu on Mobile */}
//       {isMobile && (
//         <IconButton
//           onClick={() => setOpen(true)}
//           sx={{
//             position: "absolute",
//             top: 10,
//             left: 10,
//             zIndex: 1300, // Ensures it's above other content
//           }}
//         >
//           <MenuIcon />
//         </IconButton>
//       )}

//       {/* Drawer Component */}
//       <Drawer
//         anchor="left"
//         variant={isMobile ? "temporary" : "permanent"}
//         open={isMobile ? open : true}
//         onClose={() => setOpen(false)}
//         sx={{
//           width: isMobile ? "60vw" : isTablet ? "30vw" : 100, // Adjust width based on screen size
//           flexShrink: 0,
//           "& .MuiDrawer-paper": {
//             width: isMobile ? "60vw" : isTablet ? "30vw" : 100,
//             borderRadius: isMobile ? "20px" : "20px 20px 20px 20px",
//             overflow: "hidden",
//             height: "100vh",
//             margin: isMobile ? "2vh 0" : "2.5vh 0",
//             backgroundColor: mode === "dark" ? "#1e1e1e" : "#ffffff",
//             color: mode === "dark" ? "#ffffff" : "#000000",
//             transition: "width 0.3s ease-in-out", // Smooth transition
//           },
//         }}
//       >
//         <Box
//           sx={{
//             width: "100%",
//             height: "100%",
//             p: 2,
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//           }}
//         >
//           {/* Close Button (Only for Mobile) */}
//           {isMobile && (
//             <IconButton
//               onClick={() => setOpen(false)}
//               sx={{ alignSelf: "flex-end", mb: 2 }}
//             >
//               <CloseIcon />
//             </IconButton>
//           )}

//           {/* Mobile Title and Search */}
//           {isMobile && (
//             <Box sx={{ width: "100%", mb: 2, textAlign: "center" }}>
//               <h2>Mobile Navigation</h2>
//               <TextField
//                 fullWidth
//                 variant="outlined"
//                 placeholder="Search"
//                 sx={{ mb: 2 }}
//               />
//             </Box>
//           )}

//           {/* Mobile Dropdown (Optional) */}
//           {isMobile && (
//             <IconButton onClick={handleDropdownClick} sx={{ mb: 2 }}>
//               <SettingsIcon />
//             </IconButton>
//           )}
//           <Menu
//             anchorEl={anchorEl}
//             open={Boolean(anchorEl)}
//             onClose={handleDropdownClose}
//           >
//             <MenuItem onClick={handleDropdownClose}>Option 1</MenuItem>
//             <MenuItem onClick={handleDropdownClose}>Option 2</MenuItem>
//           </Menu>

//           {/* Sidebar Menu */}
//           <List>
//             {menuItems.map((item, index) => (
//               <ListItem
//                 key={index}
//                 sx={{
//                   justifyContent: "center",
//                   mb: 3,
//                   transition: "all 0.2s ease-in-out",
//                   "&:hover": {
//                     transform: "scale(1.1)",
//                   },
//                 }}
//                 onClick={() => handleMenuItemClick(item.label)}
//               >
//                 {item.isSwitch ? (
//                   item.icon
//                 ) : (
//                   <Tooltip title={item.label} arrow>
//                     <IconButton
//                       sx={{
//                         color: mode === "dark" ? "#bbb" : "#444",
//                         "&:hover, &:active": {
//                           color: mode === "dark" ? "#bb86fc" : "#6200ea",
//                         },
//                       }}
//                     >
//                       {item.icon}
//                     </IconButton>
//                   </Tooltip>
//                 )}
//               </ListItem>
//             ))}
//           </List>

//           {/* Divider for logged-in users */}
//           {token && <Divider sx={{ my: 2 }} />}
//         </Box>
//       </Drawer>
//     </>
//   );
// };

// export default DrawerComponent;


import React, { useState, useContext, useEffect } from "react";
import {
  Drawer,
  List,
  ListItem,
  IconButton,
  Box,
  Divider,
  useMediaQuery,
  Switch,
  FormControlLabel,
  Tooltip,
  TextField,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Avatar,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import GroupIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import { ThemeContext } from "../ThemeContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Assessment, CreditCard } from "@mui/icons-material";

const DrawerComponent = ({ onSelectLabel }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeItem, setActiveItem] = useState("");
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  // Access theme mode and toggle function
  const { mode, toggleTheme } = useContext(ThemeContext);
  const token = sessionStorage.getItem("token");
  const isAdmin = sessionStorage.getItem("role") === "ADMIN";
  const userName = sessionStorage.getItem("userName") || "User";

  // Define drawer width based on screen size
  const drawerWidth = isMobile ? "70vw" : isTablet ? "220px" : "80px";
  const expandedDrawerWidth = "220px";

  const menuItems = token
  ? [
      { icon: <DashboardIcon />, label: "Dashboard", path: "/dashboard" },
      ...(isAdmin
        ? [
            { icon: <GroupIcon />, label: "Users", path: "/users" },
            {
              icon: <LeaderboardIcon />,
              label: "Card List",
              path: "/allcardslist",
            },
            {
              icon: <CardGiftcardIcon />,
              label: "Applied Cards",
              path: "/allcards",
            },
          ]
        : []),
      {
        icon: <Assessment />,
        label: "Reports",
        path: "/allpayinlist",
      },
      {
        icon: <CreditCard />,
        label: "Cards",
        path: "/card",
      },
      {
        icon: <SettingsSuggestIcon />,
        label: "Settings",
        path: "/settings",
      },
      { icon: <LogoutIcon />, label: "Logout", path: "/logout" },
    ]
  : [
      { icon: <HomeIcon />, label: "Home", path: "/" },
      { icon: <InfoIcon />, label: "About", path: "/about" },
      { icon: <SettingsIcon />, label: "Settings", path: "/settings" },
      { icon: <ContactMailIcon />, label: "Contact", path: "/contact" },
    ];

  // Set active item based on current location
  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem = menuItems.find((item) => item.path === currentPath);
    if (currentItem) {
      setActiveItem(currentItem.label);
    }
  }, [location.pathname]);

  // Pre-fetch data for all possible routes
  useEffect(() => {
    // This could be used to pre-fetch data for common routes
    const preFetchRoutes = async () => {
      // Example: If you have API calls for dashboard data
      if (token) {
        try {
          // You can add your API fetch calls here
          // const dashboardData = await fetch('/api/dashboard').then(res => res.json());
          // const cardsData = await fetch('/api/cards').then(res => res.json());
          // Store in context or localStorage for instant access when navigating
        } catch (error) {
          console.error("Error pre-fetching data:", error);
        }
      }
    };

    preFetchRoutes();
  }, [token]);

  const handleMenuItemClick = (label, path) => {
    onSelectLabel && onSelectLabel(label);
    setActiveItem(label);

    if (label === "Logout") {
      // Handle logout
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("role");
      navigate("/");
      return;
    }

    if (path) {
      navigate(path);
    }

    if (isMobile) {
      setOpen(false);
    }
  };

  // For Dropdown Menu (mobile)
  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  // Hover expand for desktop
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      {/* Show Hamburger Menu on Mobile */}
      {isMobile && (
        <IconButton
          onClick={() => setOpen(true)}
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
            zIndex: 1300,
            backgroundColor: mode === "dark" ? "rgba(30, 30, 30, 0.7)" : "rgba(255, 255, 255, 0.7)",
            "&:hover": {
              backgroundColor: mode === "dark" ? "rgba(50, 50, 50, 0.9)" : "rgba(240, 240, 240, 0.9)",
            },
          }}
        >
          <MenuIcon />
        </IconButton>
      )}

      {/* Drawer Component */}
      <Drawer
        anchor="left"
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? open : true}
        onClose={() => setOpen(false)}
        sx={{
          width: isMobile ? drawerWidth : isExpanded ? expandedDrawerWidth : drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: isMobile ? drawerWidth : isExpanded ? expandedDrawerWidth : drawerWidth,
            borderRadius: isMobile ? "0 20px 20px 0" : "0 20px 20px 0",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            height: "100vh",
            margin: isMobile ? "0" : "0",
            backgroundColor: mode === "dark" ? "#1e1e1e" : "#ffffff",
            color: mode === "dark" ? "#ffffff" : "#000000",
            transition: "width 0.3s ease-in-out",
          },
        }}
        onMouseEnter={() => !isMobile && !isTablet && setIsExpanded(true)}
        onMouseLeave={() => !isMobile && !isTablet && setIsExpanded(false)}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2,
          }}
        >
          {/* Close Button (Only for Mobile) */}
          {isMobile && (
            <IconButton
              onClick={() => setOpen(false)}
              sx={{ alignSelf: "flex-end", mb: 2 }}
            >
              <CloseIcon />
            </IconButton>
          )}

          {/* User Profile Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              mb: 3,
              mt: 2,
            }}
          >
            <Avatar
              sx={{
                width: 60,
                height: 60,
                mb: 1,
                backgroundColor: mode === "dark" ? "#bb86fc" : "#6200ea",
              }}
            >
              {userName.charAt(0).toUpperCase()}
            </Avatar>
            {(isMobile || isTablet || isExpanded) && (
              <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: "bold" }}>
                {userName}
              </Typography>
            )}
          </Box>

          {/* Mobile Search */}
          {isMobile && (
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search"
              size="small"
              sx={{
                mb: 2,
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px",
                },
              }}
            />
          )}

          {/* Theme Toggle */}
          <Box 
            sx={{ 
              width: "100%", 
              display: "flex", 
              justifyContent: "center",
              mb: 2,
              alignItems: "center" 
            }}
          >
            {(isMobile || isTablet || isExpanded) ? (
              <FormControlLabel
                control={
                  <Switch
                    checked={mode === "dark"}
                    onChange={toggleTheme}
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#bb86fc",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                        backgroundColor: "#bb86fc",
                      },
                    }}
                  />
                }
                label={mode === "dark" ? "Dark" : "Light"}
              />
            ) : (
              <Tooltip title={`${mode === "dark" ? "Light" : "Dark"} Mode`} placement="right">
                <IconButton onClick={toggleTheme}>
                  {mode === "dark" ? (
                    <span style={{ fontSize: "24px" }}>🌙</span>
                  ) : (
                    <span style={{ fontSize: "24px" }}>☀️</span>
                  )}
                </IconButton>
              </Tooltip>
            )}
          </Box>

          <Divider sx={{ width: "80%", mb: 2 }} />

          {/* Sidebar Menu */}
          <List sx={{ width: "100%" }}>
            {menuItems.map((item, index) => (
              <ListItem
                key={index}
                sx={{
                  borderRadius: "12px",
                  mb: 0.5,
                  p: 1,
                  backgroundColor: activeItem === item.label
                    ? mode === "dark" ? "rgba(187, 134, 252, 0.1)" : "rgba(98, 0, 234, 0.1)"
                    : "transparent",
                  "&:hover": {
                    backgroundColor: mode === "dark" ? "rgba(187, 134, 252, 0.05)" : "rgba(98, 0, 234, 0.05)",
                  },
                  transition: "all 0.2s ease-in-out",
                  cursor: "pointer",
                }}
                onClick={() => handleMenuItemClick(item.label, item.path)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile || isTablet || isExpanded ? "40px" : "0",
                    color: activeItem === item.label
                      ? mode === "dark" ? "#bb86fc" : "#6200ea"
                      : mode === "dark" ? "#bbb" : "#444",
                    display: "flex",
                    justifyContent: (!isExpanded && !isMobile && !isTablet) ? "center" : "flex-start",
                    width: (!isExpanded && !isMobile && !isTablet) ? "100%" : "auto",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                
                {(isMobile || isTablet || isExpanded) && (
                  <ListItemText 
                    primary={item.label}
                    sx={{
                      "& .MuiTypography-root": {
                        fontWeight: activeItem === item.label ? "bold" : "normal",
                        color: activeItem === item.label
                          ? mode === "dark" ? "#bb86fc" : "#6200ea"
                          : "inherit",
                      }
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>

          <Box sx={{ flexGrow: 1 }} />

          {/* App version */}
          {(isMobile || isTablet || isExpanded) && (
            <Typography variant="caption" sx={{ mt: 2, opacity: 0.6 }}>
              v1.0.0
            </Typography>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default DrawerComponent;