import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ThemeProviderComponent from "./ThemeContext";
import Layout from "./Layout";
import LandingPage from "./website/LandingPage";
import LoginForm from "./Login/Login";
import Dashboard from "./Home/Dashboard";
import FetchGiftCards from "./GiftCard/FetchGiftCards";
import AddUserDialog from "./Users/adduserdialog";
import Users from "./Users/Users";
import AddMoney from "./Payin/AddMoney";
import UserCardApplyList from "./GiftCard/UserCardApplyList";
import GiftCardListUser from "./GiftCard/GiftCardListUser";
import AddGiftCard from "./GiftCard/AddGiftCard";
import AllPayIn from "../src/UsersList/AllPayIn";
import AllCards from "../src/UsersList/AllCards";
import MyProfile from "./Home/MyProfile";
import ProfileSection from "./ProfileSection";
import GiftCard from "./GiftCard/GiftCard";
import { Outlet } from "react-router-dom"; // Import Outlet for nested routing
import { ResetPassword } from "./Home/ResetPassword";
import { NotRegistered } from "./Home/NotRegistered";
import ConversionCoinPopup from "./Payin/ConversionCoin";
import DepositReport from "./Reports/DepositReport";

function App() {
  return (
    <Router>
      <Routes>
        {/* Routes outside ThemeProviderComponent */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/Login" element={<LoginForm />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/notregistered" element={<NotRegistered />} />

        {/* Protected Routes inside ThemeProviderComponent */}
        <Route
          path="/*"
          element={
            <ThemeProviderComponent>
              <Layout>
                <Outlet />{" "}
                {/* This allows child routes to render inside Layout */}
              </Layout>
            </ThemeProviderComponent>
          }
        >
          <Route path="Dashboard" element={<Dashboard />} />
          <Route path="MyProfile" element={<ProfileSection />} />
          <Route path="FetchCards" element={<FetchGiftCards />} />
          <Route path="AddUser" element={<AddUserDialog />} />
          <Route path="Users" element={<Users />} />
          <Route path="AddMoney" element={<AddMoney />} />
          <Route path="AllCards" element={<UserCardApplyList />} />
          <Route path="GiftCardListUser" element={<GiftCardListUser />} />
          <Route path="AddCards" element={<AddGiftCard />} />
          <Route path="AllPayInList" element={<AllPayIn />} />
          <Route path="AllCardsList" element={<AllCards />} />
          <Route path="Card" element={<GiftCard />} />
          <Route path="conversioncoin" element={<ConversionCoinPopup />} />
          <Route path="depositReports" element={<DepositReport />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
