import axios from "axios";

// Function to get the token from sessionStorage
const getTokenFromSession = () => {
  return sessionStorage.getItem("token"); // Assuming 'token' is the key in sessionStorage
};

// Function to update the user
export const updateUser = async (userData) => {
  try {
    const token = getTokenFromSession();
    if (!token) {
      throw new Error("No token found in sessionStorage");
    }

        const response = await axios({
            method: 'POST',
            url: 'https://api.erapay.biz/erapay/user/updateuser',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            data: userData,
        });

    return response.data; // Assuming the response contains the data you need
  } catch (error) {
    console.error("Error updating user:", error);
    throw error; // Rethrow the error to be handled in the calling component
  }
};
