import React, { useState, useEffect } from "react";
import Homeimg1 from "../assets/Home/Homeimg1.jpg";
import Homeimg2 from "../assets/Home/Homeimg2.jpg";
import { styled } from "@mui/material/styles";
import { Button, Box, Typography, useTheme } from "@mui/material";
import Marquee from "react-fast-marquee";
import Ipaisa from "../assets/Home/Morque/iPaisaLogo2.png";
import Coinera from "../assets/Home/Morque/CoinEraname.png";
import Coineralogo from "../assets/Home/Morque/logo.png";
import Vllogo from '../assets/Home/Morque/vllogo.png'
import { useNavigate } from "react-router-dom"; // Import useNavigate

const HomePageFirst = () => {
  const navigate = useNavigate(); // Hook for navigation
  const images = [Homeimg1, Homeimg2];
  const [currentImage, setCurrentImage] = useState(0);
  // const marqueeImages = [Ipaisa, Coinera, Coineralogo,Vllogo];
  const marqueeImages = [Ipaisa, Coineralogo,Vllogo];

  
  const theme = useTheme();
  const handleLoginClick = () => {
    navigate("/login"); // Redirects to the login page
  };

  // Custom Styled Button with responsive properties
  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: "black",
    color: "white",
    border: "2px solid blue",
    fontWeight: "bold",
    textTransform: "none",
    borderRadius: "50px",
    transition: "all 0.3s ease",
    "&:hover": {
      background: "linear-gradient(45deg, #2575fc 30%, #6a11cb 90%)",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
      transform: "scale(1.05)",
    },
    "&:active": {
      transform: "scale(0.98)",
    },
    // Responsive styles
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
      padding: '8px 16px',
      minWidth: '100px',
      minHeight: '40px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '0.8rem',
      padding: '10px 20px',
      minWidth: '110px',
      minHeight: '45px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '0.9rem',
      padding: '12px 22px',
      minWidth: '120px',
      minHeight: '48px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      padding: '15px 25px',
      minWidth: '130px',
      minHeight: '50px',
    },
  }));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage === 0 ? 1 : 0));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  // Helper function to calculate responsive font sizes
  const responsiveFontSize = (baseSizeDesktop, minSize) => {
    return {
      fontSize: {
        xs: `clamp(${minSize}rem, 4vw, ${baseSizeDesktop * 0.5}rem)`,
        sm: `clamp(${minSize}rem, 5vw, ${baseSizeDesktop * 0.6}rem)`,
        md: `clamp(${minSize}rem, 6vw, ${baseSizeDesktop * 0.8}rem)`,
        lg: `${baseSizeDesktop}rem`,
      },
    };
  };

  return (
    <Box
     id="Home-Section-One"
      sx={{
        position: "relative",
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden", // Prevent any potential overflow issues
      }}
    >
      {/* Background Image with responsive height */}
      <Box
        sx={{
          width: "100%",
          height: {
            xs: "50vh",
            sm: "60vh",
            md: "70vh",
            lg: "100vh",
          },
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={images[currentImage]}
          alt="Home Image"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover", // This ensures the image covers the container properly
            transition: "opacity 1s ease-in-out",
          }}
        />
      </Box>

      {/* Text and Button Overlay with responsive spacing */}
      <Box
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          color: "white",
          padding: {
            xs: "10px",
            sm: "15px",
            md: "20px",
            lg: "25px",
          },
          // Add a semi-transparent overlay to ensure text visibility on any background
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            zIndex: 1,
          },
          "& > *": {
            position: "relative",
            zIndex: 2,
          },
        }}
      >
        <Typography
          sx={{
            ...responsiveFontSize(6.2, 1.8),
            fontWeight: "bold",
            fontFamily: "Poppins",
            lineHeight: {
              xs: 1.2,
              sm: 1.3,
              md: 1.4,
              lg: 1.5,
            },
            maxWidth: {
              xs: "95%",
              sm: "90%",
              md: "85%",
              lg: "80%",
            },
            marginBottom: {
              xs: 1,
              sm: 1.5,
              md: 2,
              lg: 2.5,
            },
          }}
        >
          Shop with Crypto, Just Like Fiat
        </Typography>
        <Typography
          sx={{
            ...responsiveFontSize(2.5, 1),
            fontWeight: "bold",
            fontFamily: "Poppins",
            maxWidth: {
              xs: "90%",
              sm: "85%",
              md: "80%",
              lg: "75%",
            },
            marginBottom: {
              xs: 2,
              sm: 2.5,
              md: 3,
              lg: 3.5,
            },
          }}
        >
          Seamless Digital Currency Payments
        </Typography>
        <StyledButton variant="contained" color="secondary" onClick={handleLoginClick}>
          LOGIN NOW
        </StyledButton>
      </Box>

      {/* Marquee Section with responsive sizing */}
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          bottom: "0",
          left: "0",
          overflow: "hidden",
        }}
      >
        {/* Gradient Overlay for Blur Effect */}
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "linear-gradient(to right, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 10%, rgba(0,0,0,0) 90%, rgba(0,0,0,0.7) 100%)",
            top: 0,
            left: 0,
            pointerEvents: "none",
            zIndex: 2,
          }}
        />

        <Marquee
          speed={50}
          gradient={false}
          style={{
            background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background for better visibility
            height: "auto",
            padding: "10px 0",
          }}
        >
          {marqueeImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`marquee-img-${index}`}
              style={{
                height: "auto",
                maxHeight: "80px",
                width: "auto",
                margin: "0 30px",
              }}
              // Add responsive image sizing
              onLoad={(e) => {
                const img = e.target;
                // Dynamically adjust image size based on screen width
                if (window.innerWidth < 600) {
                  img.style.maxHeight = "40px";
                } else if (window.innerWidth < 960) {
                  img.style.maxHeight = "60px";
                } else {
                  img.style.maxHeight = "80px";
                }
              }}
            />
          ))}
        </Marquee>
      </Box>
    </Box>
  );
};

export default HomePageFirst;