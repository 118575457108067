import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Pagination,
  Box,
} from "@mui/material";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const UserCardApplyList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.get(
        "https://api.erapay.biz/erapay/admin/allcardapply",
        {
          headers: {
            currPage: currentPage,
            pageSize: pageSize,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("User list:", response);
      if (
        response.data &&
        response.data.data &&
        Array.isArray(response.data.data.content)
      ) {
        setUsers(response.data.data.content);
        setTotalRecords(response.data.data.totalElements || 0);
      } else {
        setUsers([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
      setUsers([]);
      setTotalRecords(0);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeStatus = async (userId, newStatus) => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.put(
        "https://api.erapay.biz/erapay/admin/changeapplicationstatus",
        null, // Do not pass any payload here
        {
          headers: {
            status: newStatus,
            userid: userId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Status changed:", response);

      toast.done(response.data?.message);

      fetchUsers(); // Refresh the user list after status change
    } catch (error) {
      console.error("Error changing status:", error.response);
      toast.error(error.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [currentPage, pageSize]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setCurrentPage(0);
  };

  return (
    <Box style={{ padding: "2rem" }}>
      <ToastContainer position="top-right" autoClose={3000} />
      <Grid>
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "sans-serif",
            fontSize: "19px",
            color: "#343C6A",
          }}
        >
          User List - Applied Cards
        </Typography>
      </Grid>
      <Grid style={{ marginTop: "1%" }}>
        {loading ? (
          <Grid sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <TableContainer
              component={Paper}
              sx={{ overflowX: "auto", borderRadius: "12px 12px 0px 0px" }}
            >
              <Table>
                <TableHead
                  style={{ backgroundColor: "#f2b173", color: "white" }}
                >
                  <TableRow>
                    <TableCell sx={{ color: "white" }}>User ID</TableCell>
                    <TableCell sx={{ color: "white" }}>User Name</TableCell>
                    <TableCell sx={{ color: "white" }}>Mobile No.</TableCell>
                    <TableCell sx={{ color: "white" }}>Apply For</TableCell>
                    <TableCell sx={{ color: "white" }}>
                      Wallet Balance
                    </TableCell>
                    <TableCell sx={{ color: "white" }}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.length > 0 ? (
                    users.map((user, index) => (
                      <TableRow key={index}>
                        <TableCell>{user.user.userId || "N/A"} </TableCell>
                        <TableCell>{user.user.name || "N/A"}</TableCell>
                        <TableCell>{user.user.mobile || "N/A"}</TableCell>
                        <TableCell>{user.applyFor || "N/A"}</TableCell>
                        <TableCell>
                          {Number(user.wallet).toFixed(2) || "0"}
                        </TableCell>
                        <TableCell>
                          {/* Dropdown for changing status */}
                          <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                              label="Status"
                              value={user.status || ""}
                              onChange={(e) =>
                                handleChangeStatus(
                                  user.user.userId,
                                  e.target.value
                                )
                              }
                            >
                              <MenuItem value="COMPLETED">Completed</MenuItem>
                              <MenuItem value="PENDING">Pending</MenuItem>
                              <MenuItem value="CANCELLED">Cancelled</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No users found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* Pagination */}
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: "1%" }}
            >
              <Grid item>
                <Select
                  variant="outlined"
                  value={pageSize}
                  onChange={handlePageSizeChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
                <Typography display="inline" style={{ marginLeft: 8 }}>
                  Showing {currentPage * pageSize + 1} to{" "}
                  {Math.min((currentPage + 1) * pageSize, totalRecords)} out of{" "}
                  {totalRecords} records
                </Typography>
              </Grid>
              <Grid item>
                <Pagination
                  count={Math.ceil(totalRecords / pageSize)}
                  color="primary"
                  page={currentPage + 1}
                  onChange={handlePageChange}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default UserCardApplyList;
