import React, { useState } from "react";
import {
  Button,
  Box,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import GradientCard from "./FetchGiftCards";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import toast CSS

const AddGiftCard = ({ onGiftCardAdded }) => {
  const [formData, setFormData] = useState({
    cardType: "PHYSICAL",
  });

  const [cardDetails, setCardDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = sessionStorage.getItem("token");
    const userid = sessionStorage.getItem("userId");
    const cardholderName = sessionStorage.getItem("name");

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      cardtype: formData.cardType,
      userid: userid,
    };

    try {
      const response = await axios.post(
        "https://api.erapay.biz/erapay/user/cardapply",
        {},
        { headers }
      );

      setCardDetails({
        cardholderName: cardholderName,
      });

      if (response.data.error) {
        toast.error(response.data.error.message || "An error occurred.");
      } else {
        toast.success(response.data.message || "Gift Card Applied Successfully");
        setFormData({ cardType: "PHYSICAL" });
        onGiftCardAdded();
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.response?.data?.message || "Server error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper
      elevation={5}
      sx={{
        p: 4,
        maxWidth: 850,
        mx: "auto",
        mt: 5,
        borderRadius: 3,
        boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
        background: "linear-gradient(to right, #ffffff, #f8f9fa)",
      }}
    >
      <Typography variant="h5" fontWeight={600} align="center" gutterBottom color="primary">
        Apply for a Gift Card
      </Typography>

      <Box component="form" onSubmit={handleSubmit}>
        <FormControl fullWidth margin="dense">
          <InputLabel>Card Type</InputLabel>
          <Select
            name="cardType"
            value={formData.cardType}
            onChange={handleChange}
            label="Card Type"
            variant="outlined"
            sx={{ borderRadius: 2 }}
          >
            <MenuItem value="PHYSICAL">Physical</MenuItem>
            <MenuItem value="VIRTUAL">Virtual</MenuItem>
          </Select>
        </FormControl>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            mt: 2,
            py: 1.5,
            borderRadius: 2,
            background: "linear-gradient(135deg, #6a11cb, #2575fc)",
            color: "white",
            fontWeight: "bold",
            textTransform: "none",
            transition: "all 0.3s ease",
            "&:hover": {
              background: "linear-gradient(135deg, #2575fc, #6a11cb)",
              transform: "scale(1.02)",
            },
          }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Apply for Gift Card"}
        </Button>
      </Box>

      {cardDetails && <GradientCard cardDetails={cardDetails} cardType={formData.cardType} />}

      <ToastContainer position="top-right" autoClose={4000} hideProgressBar={false} theme="colored" />
    </Paper>
  );
};

export default AddGiftCard;
