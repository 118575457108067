

import React, { useState, useEffect, useRef } from 'react';
import HomeOneimg from '../assets/Home/home31.jpg';
import HomeTwoimg from '../assets/Home/home32.jpg';
import HomeThreeimg from '../assets/Home/home33.jpg';
import HomeFourimg from '../assets/Home/home34.jpg';
import HomeFiveimg from '../assets/Home/home35.jpg';
import { styled } from "@mui/material/styles";
import { Container, Typography, Box, useMediaQuery, useTheme } from '@mui/material';

// Styled components
const SliderContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const SliderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'transform 0.5s ease',
  paddingLeft: '5%',
  paddingRight: '5%',
}));

const SlideItem = styled(Box)(({ theme, active }) => ({
  flexShrink: 0,
  padding: theme.spacing(1),
  position: 'relative',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  height: active ? '400px' : '350px',
  transform: active ? 'scale(1.1)' : 'scale(0.9)',
  opacity: active ? 1 : 0.7,
  transition: 'all 0.5s ease',
  cursor: active ? 'default' : 'pointer',
  zIndex: active ? 2 : 1,
  [theme.breakpoints.down('md')]: {
    height: active ? '300px' : '250px',
  },
  [theme.breakpoints.down('sm')]: {
    height: active ? '250px' : '200px',
  },
}));

const SlideImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '16px',
});

const SlideContent = styled(Box)(({ theme, active }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  padding: theme.spacing(3),
  background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
  color: 'white',
  borderBottomLeftRadius: '16px',
  borderBottomRightRadius: '16px',
  opacity: active ? 1 : 0.9,
}));

const SlideIcon = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '30px',
    height: '30px',
  },
}));

const HomePageThree = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Slides data
  const slides = [
    {
      image: HomeOneimg,
      title: "Secure Custodian",
      description: "Licensed Trust Company with USD 42M insurance coverage",
      icon: "🛡️" // Shield icon
    },
    {
      image: HomeTwoimg,
      title: "PAY",
      description: "Make payment at 130M+ merchants globally",
      icon: "💳" // Card icon
    },
    {
      image: HomeThreeimg,
      title: "SEND",
      description: "Send and receive crypto with 0 fees",
      icon: "📲" // Mobile icon
    },
    {
      image: HomeFourimg,
      title: "TRANSFER",
      description: "Fast and secure crypto transfers worldwide",
      icon: "🔄" // Transfer icon
    },
    {
      image: HomeFiveimg,
      title: "EXCHANGE",
      description: "Convert between cryptocurrencies at competitive rates",
      icon: "💱" // Currency exchange icon
    }
  ];
  
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  const autoPlayRef = useRef(null);

  // Next slide function
  const nextSlide = () => {
    if (currentIndex < slides.length - 1) {
      setCurrentIndex(prevIndex => prevIndex + 1);
    } else {
      setCurrentIndex(0); // Loop back to the beginning
    }
  };

  // Previous slide function
  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevIndex => prevIndex - 1);
    } else {
      setCurrentIndex(slides.length - 1); // Loop to the end
    }
  };

  // Handle click on a slide
  const handleSlideClick = (index) => {
    // Only allow clicking on the adjacent slides
    if (index === currentIndex - 1) {
      prevSlide();
    } else if (index === currentIndex + 1) {
      nextSlide();
    }
  };

  // Set up autoplay
  useEffect(() => {
    if (autoPlay) {
      autoPlayRef.current = setInterval(() => {
        nextSlide();
      }, 3000); // Change slide every 3 seconds
    }
    
    return () => {
      if (autoPlayRef.current) {
        clearInterval(autoPlayRef.current);
      }
    };
  }, [autoPlay, currentIndex]);

  // Pause autoplay on hover
  const handleMouseEnter = () => setAutoPlay(false);
  const handleMouseLeave = () => setAutoPlay(true);

  // Determine which slides to display
  const getVisibleSlides = () => {
    if (isMobile) {
      // On mobile, show only the current slide
      return [slides[currentIndex]];
    }

    // Get the previous, current, and next slides
    let visibleSlides = [];
    const prevIdx = currentIndex === 0 ? slides.length - 1 : currentIndex - 1;
    const nextIdx = currentIndex === slides.length - 1 ? 0 : currentIndex + 1;
    
    visibleSlides.push({
      ...slides[prevIdx],
      index: prevIdx,
      active: false
    });
    
    visibleSlides.push({
      ...slides[currentIndex],
      index: currentIndex,
      active: true
    });
    
    visibleSlides.push({
      ...slides[nextIdx],
      index: nextIdx,
      active: false
    });
    
    return visibleSlides;
  };

  return (
    <Container
    id="Home-Section-Three"
    maxWidth="xl">
      <Box sx={{ textAlign: 'center', my: 4 }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontFamily: "Poppins",
            lineHeight: 1.2,
            marginBottom: { xs: 2, md: 3 },
            fontSize: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
          }}
        >
          PAY, SEND, TRANSFER with Coinera
        </Typography>
      </Box>
      
      <SliderContainer
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <SliderWrapper>
          {getVisibleSlides().map((slide, idx) => (
            <SlideItem
              key={`slide-${slide.index}`}
              active={slide.active}
              onClick={() => !slide.active && handleSlideClick(slide.index)}
              sx={{ 
                margin: { xs: '0 5px', md: '0 10px' },
                width: isMobile ? '90%' : '30%'
              }}
            >
              <SlideImage src={slide.image} alt={slide.title} />
              <SlideIcon>
                <Typography variant="h5">{slide.icon}</Typography>
              </SlideIcon>
              <SlideContent active={slide.active}>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold", 
                    fontSize: { xs: '1.5rem', md: '2rem' },
                    mb: 1
                  }}
                >
                  {slide.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: { xs: '0.9rem', md: '1.1rem' } }}
                >
                  {slide.description}
                </Typography>
              </SlideContent>
            </SlideItem>
          ))}
        </SliderWrapper>
      </SliderContainer>
    </Container>
  );
};

export default HomePageThree;