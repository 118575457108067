import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
  Pagination,
  Box,
} from "@mui/material";
import axios from "axios";

const AllCards = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState();
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    const token = sessionStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://api.erapay.biz/erapay/admin/allcards",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            currPage: currentPage,
            pageSize: pageSize,
          },
        }
      );

      if (response.status === 200) {
        setData(response.data?.data?.content || []);
        setTotalRecords(response.data?.data?.totalElements || 0);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setCurrentPage(0);
  };

  return (
    <Box style={{ padding: "2rem" }}>
      <Grid>
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "sans-serif",
            fontSize: "19px",
            color: "#343C6A",
          }}
        >
          All Cards List
        </Typography>
      </Grid>

      <Grid style={{ marginTop: "1%" }}>
        {loading ? (
          <Grid sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Grid>
        ) : (
          <TableContainer
            component={Paper}
            sx={{ overflowX: "auto", borderRadius: "12px 12px 0px 0px" }}
          >
            <Table>
              <TableHead style={{ backgroundColor: "#f2b173", color: "white" }}>
                <TableRow>
                  <TableCell sx={{ color: "white" }}>Sr No.</TableCell>
                  <TableCell sx={{ color: "white" }}>Card No.</TableCell>
                  <TableCell sx={{ color: "white" }}>User Name</TableCell>
                  <TableCell sx={{ color: "white" }}>Mobile No.</TableCell>
                  <TableCell sx={{ color: "white" }}>Wallet</TableCell>
                  <TableCell sx={{ color: "white" }}>Card Expiry</TableCell>
                  <TableCell sx={{ color: "white" }}>Card Network</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor:
                          index % 2 === 0 ? "#FFFFFF" : "#fbe5d0",
                      }}
                    >
                      <TableCell>
                        {currentPage * pageSize + index + 1}
                      </TableCell>
                      <TableCell>{row.cardNumber || "N/A"}</TableCell>
                      <TableCell>{row.user.name || "N/A"}</TableCell>
                      <TableCell>{row.user.mobile || "N/A"}</TableCell>
                      <TableCell>
                        {Number(row.user.wallet).toFixed(2) || "N/A"}
                      </TableCell>
                      <TableCell>{row.end || "N/A"}</TableCell>
                      <TableCell>{row.cardNetwork || "N/A"}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>

      {/* Pagination */}
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: "1%" }}
      >
        <Grid item>
          <Select
            variant="outlined"
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
          <Typography display="inline" style={{ marginLeft: 8 }}>
            Showing {currentPage * pageSize + 1} to{" "}
            {Math.min((currentPage + 1) * pageSize, totalRecords)} out of{" "}
            {totalRecords} records
          </Typography>
        </Grid>
        <Grid item>
          <Pagination
            count={Math.ceil(totalRecords / pageSize)}
            color="primary"
            page={currentPage + 1}
            onChange={handlePageChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllCards;
