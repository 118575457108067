import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField, Grid, CircularProgress, IconButton, Paper, Tabs, Chip, alpha, Divider, Tooltip, Tab, InputAdornment, Autocomplete } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import { Money } from '@mui/icons-material';  // Example coin icon
import { ThemeContext } from "../ThemeContext";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";


const FinancialDashboard = () => {
    const { mode, toggleTheme } = useContext(ThemeContext);
    const [openDialog, setOpenDialog] = useState(null);
    const [open, setOpen] = useState(false);
    const [coinAmount, setCoinAmount] = useState("");
    const [tabValue, setTabValue] = useState(0);
    const [remark, setRemark] = useState("");
    const [inrValue, setInrValue] = useState(0);
    const [copied, setCopied] = useState(false);
    const [converting, setConverting] = useState(false);
    const [conversionSuccess, setConversionSuccess] = useState(false);
    const [depositAmount, setDepositAmount] = useState('');
    const [bankAccount, setBankAccount] = useState('');
    const [recipientName, setRecipientName] = useState('');
    const [recipientAccount, setRecipientAccount] = useState('');
    const [transferAmount, setTransferAmount] = useState('');
    const [depositTime, setDepositTime] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const walletAddress = "0xf9489Ef03ab1db48a92FE758A641B77B2bd616b4";
    const [partners, setPartners] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tablemsg, setTablemsg] = useState('');


    const coinRate = 21; // 1 coin = 21 INR

    const handleOpen = (action) => {
        setOpen(true);
        setConversionSuccess(false);
        setOpenDialog(action);

        console.log(`Action selected: ${action}`);
        // You can use this action to determine what modal content to display
    };


    const handleClose = () => {
        setOpen(false); // Close the modal
        setCoinAmount(""); // Reset coinAmount
        setRemark(""); // Reset remark
        setInrValue(0); // Reset INR value
        setDepositAmount(''); // Reset deposit amount
        setBankAccount(''); // Reset bank account
        setRecipientName(''); // Reset recipient name
        setRecipientAccount(''); // Reset recipient account
        setTransferAmount(''); // Reset transfer amount
        setConverting(false); // Reset conversion status
        setConversionSuccess(false); // Reset conversion success status
        setOpenDialog(null); // Reset the dialog action
    };

    const fetchUsersData = async (searchQuery = '') => {
        const token = sessionStorage.getItem("token");
        try {
            const response = await fetch(`https://api.erapay.biz/erapay/admin/users`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    searchBy: searchQuery,
                    currPage: 0, // default to the first page when searching
                    pageSize: 10, // set page size to 10
                }
            });
            const result = await response.json();
            if (response.ok && result.success) {
                setPartners(result.data.content || []);
                setTotalRecords(result.data.totalElements || 0);
            } else {
                setTablemsg(result.message || "Failed to load users data.");
            }
        } catch (error) {
            console.error("Error fetching users data:", error);
            setTablemsg("Failed to load users data.");
        }
    };

    useEffect(() => {
        fetchUsersData();
    }, [currentPage, pageSize]);

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
        if (bottom && partners.length < totalRecords) {
            setCurrentPage(prev => prev + 1); // Load next page
        }
    };

    const handleRecipientNameChange = (e, newValue) => {
        setRecipientName(newValue);  // This will update the selected recipient's name
    };

    const handleRecipientAccountChange = (e, newValue) => {
        setRecipientAccount(newValue);  // This will update the selected recipient's wallet address
        // Trigger the fetch with wallet address if needed
        fetchUsersData(newValue.walletAddress);  // Pass the wallet address to fetch data
    };


    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleCoinAmountChange = (e) => {
        const value = e.target.value;
        setCoinAmount(value);
        if (value && !isNaN(value)) {
            setInrValue((parseFloat(value) * coinRate).toFixed(2));
        } else {
            setInrValue(0);
        }
    };
    const submitButtonStyle = {
        background: "linear-gradient(135deg, #302b63, #24243e)",
        color: "#fff",
        fontWeight: "bold",
        py: 1.5,
        borderRadius: "8px",
        "&:hover": {
            background: "linear-gradient(135deg, #24243e, #302b63)",
        },
        "&:disabled": {
            background: "#ccc",
            color: "#666",
        },
    };
    const handleCopyAddress = () => {
        navigator.clipboard.writeText(walletAddress);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const handleDepositAmountChange = (e) => {
        setDepositAmount(e.target.value);
    };

    const handleTransferAmountChange = (e) => {
        setTransferAmount(e.target.value);
    };


    const handleSubmit = () => {
        if (!coinAmount || isNaN(coinAmount) || parseFloat(coinAmount) <= 0) {
            setConverting(true);

            return;
        }

        setConverting(true);

        // Simulate API call with timeout
        setTimeout(() => {
            setConverting(false);
            setConversionSuccess(true);
            setCoinAmount("");
            setRemark("");
            setInrValue(0);
        }, 1500);
    };


    const handleFileUpload = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleDepositSubmit = async () => {
        if (!depositAmount || !bankAccount || !selectedFile || !depositTime) {
            alert("Please fill all fields and upload a document!");
            return;
        }

        setLoading(true);
        const formData = new FormData();
        formData.append("screenshot", selectedFile);
        formData.append("coins", depositAmount);
        const formattedDate = depositTime.toISOString().slice(0, 19).replace("Z", " ");
        formData.append("depositTime", formattedDate);
        formData.append("depositedFromWallet", bankAccount);
        const token = sessionStorage.getItem("token");
        try {
            const response = await axios.post("https://api.erapay.biz/erapay/user/depositcoins", formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            console.log("Deposit Successful:", response.data);
            alert("Deposit successful!");
        } catch (error) {
            console.error("Deposit Failed:", error);
            alert("Failed to deposit coins.");
        }

        setLoading(false);
    };

    return (
        <div>
            <Box sx={{
                display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3,
                backgroundColor: mode ? "#424242" : "#ffffff",
                transition: "background-color 0.3s ease"
            }}>
                <Typography variant="h4" sx={{
                    color: mode ? "#fff" : "#000", fontWeight: "700",
                    textShadow: mode ? "2px 2px 4px rgba(0, 0, 0, 0.3)" : "none",
                    transition: 'color 0.3s ease-in-out', '&:hover': { textShadow: '4px 4px 8px rgba(0, 0, 0, 0.5)' }
                }}>
                    Financial Dashboard
                </Typography>
            </Box>

            <Grid container spacing={2} justifyContent="flex-start" sx={{ mb: 2 }}>
                <Grid item onClick={() => handleOpen("buy")}>
                    <IconButton
                        // Ensure this function is defined to open the modal
                        sx={{
                            color: mode ? "#fff" : "#000",
                            '&:hover': { transform: 'scale(1.1)', transition: 'transform 0.2s', color: '#1976d2' }
                        }}
                    >
                        <AccountBalanceWalletIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: mode ? "#fff" : "#000", textAlign: "center" }}>
                        Buy
                    </Typography>
                </Grid>

                <Grid item onClick={() => handleOpen("deposit")}>
                    <IconButton sx={{
                        color: mode ? "#fff" : "#000", '&:hover': { transform: 'scale(1.1)', transition: 'transform 0.2s', color: '#1976d2' }
                    }}>
                        <ArrowDownwardIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: mode ? "#fff" : "#000", textAlign: "center" }}>Deposit</Typography>
                </Grid>
                <Grid item onClick={() => handleOpen("transfer")}>
                    <IconButton sx={{
                        color: mode ? "#fff" : "#000", '&:hover': { transform: 'scale(1.1)', transition: 'transform 0.2s', color: '#1976d2' }
                    }}>
                        <TransferWithinAStationIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: mode ? "#fff" : "#000", textAlign: "center" }}>Transfer Coin</Typography>
                </Grid>
            </Grid>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: "16px",
                        background: "rgba(255, 255, 255, 0.95)",
                        backdropFilter: "blur(10px)",
                        boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
                        overflow: "hidden"
                    }
                }}
            >
                <DialogTitle
                    sx={{
                        background: "linear-gradient(135deg, #0f0c29, #302b63, #24243e)",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        py: 2,
                        px: 3
                    }}
                >
                    <Box display="flex" alignItems="center" gap={1}>
                        <CurrencyExchangeIcon />
                        <Typography variant="h6" fontWeight="bold">
                            {openDialog === "buy" ? "Buy Coin" : openDialog === "deposit" ? "Deposit Coin" : openDialog === "transfer" ? "Transfer Coin" : "Coin Conversion Portal"}
                        </Typography>

                    </Box>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        sx={{ color: "rgba(255,255,255,0.8)" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <Box sx={{ px: 2, py: 1, borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        variant="fullWidth"
                        sx={{
                            "& .MuiTab-root": {
                                fontWeight: "bold",
                                fontSize: "0.95rem",
                                py: 2,
                            },
                            "& .Mui-selected": {
                                color: "#302b63",
                            },
                            "& .MuiTabs-indicator": {
                                backgroundColor: "#302b63",
                                height: 3,
                                borderRadius: "3px 3px 0 0"
                            }
                        }}
                    >
                        <Tab
                            icon={<SwapHorizIcon />}
                            iconPosition="start"
                            label="Convert Coins"
                        />
                        <Tab
                            icon={<AccountBalanceWalletIcon />}
                            iconPosition="start"
                            label="Wallet Address"
                        />
                    </Tabs>
                </Box>

                <DialogContent sx={{ p: 3 }}>
                    {tabValue === 0 ? (
                        <Box>
                            {/* Current Rate Display */}
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 2,
                                    mb: 3,
                                    background: "rgba(48, 43, 99, 0.05)",
                                    borderRadius: "12px",
                                    border: "1px solid rgba(48, 43, 99, 0.1)"
                                }}
                            >
                                <Typography
                                    variant="subtitle2"
                                    color="textSecondary"
                                    sx={{ mb: 1, fontSize: "0.85rem" }}
                                >
                                    Current Conversion Rate
                                </Typography>

                                <Box display="flex" alignItems="center" gap={1}>
                                    <Chip
                                        icon={<CurrencyBitcoinIcon />}
                                        label="1 Coin"
                                        size="small"
                                        sx={{
                                            bgcolor: alpha("#302b63", 0.1),
                                            color: "#302b63",
                                            fontWeight: "bold"
                                        }}
                                    />
                                    <SwapHorizIcon sx={{ color: "#666" }} />
                                    <Chip
                                        label="₹21 INR"
                                        size="small"
                                        sx={{
                                            bgcolor: alpha("#ff6a00", 0.1),
                                            color: "#ff6a00",
                                            fontWeight: "bold"
                                        }}
                                    />
                                </Box>
                            </Paper>

                            {conversionSuccess ? (
                                <Box
                                    sx={{
                                        textAlign: "center",
                                        py: 3,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        gap: 2
                                    }}
                                >
                                    <CheckCircleIcon
                                        sx={{
                                            fontSize: 64,
                                            color: "green",
                                            animation: "pulse 1.5s ease-in-out",
                                            "@keyframes pulse": {
                                                "0%": { transform: "scale(0.8)", opacity: 0 },
                                                "50%": { transform: "scale(1.1)" },
                                                "100%": { transform: "scale(1)", opacity: 1 }
                                            }
                                        }}
                                    />
                                    <Typography variant="h6" color="green" fontWeight="bold">
                                        Conversion Successful!
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Your coins have been successfully converted to INR.
                                    </Typography>
                                    <Button
                                        variant="outlined"
                                        onClick={handleClose}
                                        sx={{
                                            mt: 2,
                                            borderRadius: "8px",
                                            color: "#302b63",
                                            borderColor: "#302b63"
                                        }}
                                    >
                                        Close
                                    </Button>
                                </Box>
                            ) : (
                                <Box component="form" sx={{ mt: 1 }}>
                                    {openDialog === "buy" && (
                                        <Box>
                                            <TextField
                                                fullWidth
                                                label="Number of Coins"
                                                variant="outlined"
                                                value={coinAmount}
                                                onChange={handleCoinAmountChange}
                                                type="text"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <CurrencyBitcoinIcon color="primary" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{ mb: 3 }}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Equivalent INR Value"
                                                variant="outlined"
                                                value={inrValue === 0 ? "" : `₹${inrValue}`}
                                                disabled
                                                sx={{
                                                    mb: 3,
                                                    "& .MuiInputBase-input": {
                                                        fontWeight: "bold",
                                                        color: inrValue > 0 ? "#ff6a00" : "inherit",
                                                    },
                                                }}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Remark (Optional)"
                                                variant="outlined"
                                                value={remark}
                                                onChange={(e) => setRemark(e.target.value)}
                                                multiline
                                                rows={2}
                                                sx={{ mb: 3 }}
                                            />
                                        </Box>
                                    )}
                                    {openDialog === "deposit" && (
                                        <Box>
                                            <TextField
                                                fullWidth
                                                label="Coins to Deposit"
                                                variant="outlined"
                                                value={depositAmount}
                                                onChange={(e) => setDepositAmount(e.target.value)}
                                                type="text"
                                                sx={{ mb: 3 }}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Wallet Address"
                                                variant="outlined"
                                                value={bankAccount}
                                                onChange={(e) => setBankAccount(e.target.value)}
                                                sx={{ mb: 3 }}
                                            />
                                            <Box sx={{ mb: 3 }}>
                                                <Typography sx={{ mb: 1 }}>Select Date & Time</Typography>
                                                <DatePicker
                                                    selected={depositTime}
                                                    onChange={(date) => setDepositTime(date)}
                                                    showTimeSelect
                                                    dateFormat="Pp"
                                                    className="custom-datepicker"
                                                    style={{ width: "100%" }}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    mb: 3,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    p: 2,
                                                    border: "2px dashed #ccc",
                                                    borderRadius: "8px",
                                                    textAlign: "center",
                                                    cursor: "pointer",
                                                    transition: "0.3s",
                                                    "&:hover": { borderColor: "#1976d2" },
                                                }}
                                            >
                                                <input
                                                    type="file"
                                                    accept=".pdf,.jpg,.png"
                                                    style={{ display: "none" }}
                                                    id="upload-file"
                                                    onChange={handleFileUpload}
                                                />
                                                <label htmlFor="upload-file">
                                                    <Button variant="outlined" component="span">
                                                        Upload Document
                                                    </Button>
                                                </label>
                                                {selectedFile && <Typography variant="body2" sx={{ mt: 1, color: "#666" }}>{selectedFile.name}</Typography>}
                                                <Typography variant="body2" sx={{ mt: 1, color: "#666" }}>
                                                    Supported formats: PDF, JPG, PNG
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                    {/* {openDialog === "transfer" && (
                                        <Box>
                                            <Autocomplete
                                                value={recipientName}
                                                onChange={(e, newValue) => setRecipientName(newValue)}
                                                options={partners}
                                                getOptionLabel={(option) => option.name}  // Use 'name' as the label
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Recipient's Name" variant="outlined" sx={{ mb: 3 }} />
                                                )}
                                                // Add key prop to each option based on the userId
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option.userId}>
                                                        {option.name}
                                                    </li>
                                                )}
                                            />
                                            <Autocomplete
                                                value={recipientAccount}
                                                onChange={(e, newValue) => {
                                                    setRecipientAccount(newValue);
                                                    // Trigger the fetch with wallet address
                                                    fetchUsersData(newValue); // Pass the wallet address as the search query
                                                }}
                                                options={partners}
                                                getOptionLabel={(option) => option.walletAddress}  // Use walletAddress as the label
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Recipient's Wallet Address" variant="outlined" sx={{ mb: 3 }} />
                                                )}
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option.userId}>
                                                        {option.walletAddress}
                                                    </li>
                                                )}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Coins to Transfer"
                                                variant="outlined"
                                                value={transferAmount}
                                                onChange={handleTransferAmountChange}
                                                type="text"
                                                sx={{ mb: 3 }}
                                            />
                                        </Box>
                                    )} */}
                                    {openDialog === "transfer" && (
                                        <>
                                            <Autocomplete
                                                value={recipientName} // Display selected recipient's name
                                                onChange={handleRecipientNameChange} // Update recipient's name
                                                options={partners}
                                                getOptionLabel={(option) => option.name || ""} // Ensure a fallback value for empty name
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Recipient's Name" variant="outlined" sx={{ mb: 3 }} />
                                                )}
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option.userId}>
                                                        {option.name}
                                                    </li>
                                                )}
                                            />

                                            <Autocomplete
                                                value={recipientAccount} // Display selected recipient's wallet address
                                                onChange={handleRecipientAccountChange} // Update recipient's wallet address
                                                options={partners}
                                                getOptionLabel={(option) => option.walletAddress || ""} // Ensure fallback for empty walletAddress
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Recipient's Wallet Address" variant="outlined" sx={{ mb: 3 }} />
                                                )}
                                                renderOption={(props, option) => (
                                                    <li {...props} key={option.userId}>
                                                        {option.walletAddress} {/* Display wallet address */}
                                                    </li>
                                                )}
                                            />

                                            <TextField
                                                fullWidth
                                                label="Coins to Transfer"
                                                variant="outlined"
                                                value={transferAmount}
                                                onChange={(e) => setTransferAmount(e.target.value)} // Update transfer amount
                                                type="text"
                                                sx={{ mb: 3 }}
                                            />
                                        </>
                                    )}

                                    <Button
                                        fullWidth
                                        variant="contained"
                                        size="large"
                                        onClick={openDialog === "deposit" ? handleDepositSubmit : handleSubmit}
                                        disabled={openDialog === "deposit"
                                            ? !depositAmount || !bankAccount || !selectedFile || loading
                                            : !coinAmount || isNaN(coinAmount) || parseFloat(coinAmount) <= 0 || converting}
                                        sx={submitButtonStyle}
                                    >
                                        {converting
                                            ? "Processing..."
                                            : openDialog === "buy"
                                                ? "Buy Coins"
                                                : openDialog === "deposit"
                                                    ? "Deposit Coins"
                                                    : openDialog === "transfer"
                                                        ? "Transfer Coins"
                                                        : "Convert Now"}
                                    </Button>
                                </Box>

                            )}
                        </Box>
                    ) : (
                        <Box sx={{ py: 2 }}>
                            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: "medium" }}>
                                Your MetaMask Wallet Address
                            </Typography>

                            <Paper
                                elevation={0}
                                sx={{
                                    p: 3,
                                    borderRadius: "12px",
                                    background: "linear-gradient(to right bottom, rgba(48, 43, 99, 0.05), rgba(36, 36, 62, 0.08))",
                                    border: "1px solid rgba(48, 43, 99, 0.1)",
                                    position: "relative",
                                    overflow: "hidden"
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "5px",
                                        background: "linear-gradient(90deg, #ff9900, #ff5500, #ff9900)",
                                        backgroundSize: "200% auto",
                                        animation: "gradient 3s linear infinite",
                                        "@keyframes gradient": {
                                            "0%": { backgroundPosition: "0% center" },
                                            "100%": { backgroundPosition: "200% center" }
                                        }
                                    }}
                                />

                                <Box display="flex" alignItems="center" gap={2}>
                                    <AccountBalanceWalletIcon
                                        fontSize="large"
                                        sx={{ color: "#302b63" }}
                                    />

                                    <Box sx={{ flexGrow: 1 }}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            sx={{ mb: 0.5, fontSize: "0.8rem" }}
                                        >
                                            Connected Wallet
                                        </Typography>

                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontFamily: "monospace",
                                                fontWeight: "bold",
                                                wordBreak: "break-all"
                                            }}
                                        >
                                            {walletAddress}
                                        </Typography>
                                    </Box>

                                    <Tooltip
                                        title={copied ? "Copied!" : "Copy Address"}
                                        placement="top"
                                    >
                                        <IconButton
                                            color={copied ? "success" : "primary"}
                                            onClick={handleCopyAddress}
                                            sx={{ ml: 1 }}
                                        >
                                            {copied ? <CheckCircleIcon /> : <ContentCopyIcon />}
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Paper>

                            <Box sx={{ mt: 4, p: 3, bgcolor: "rgba(255, 153, 0, 0.05)", borderRadius: "12px" }}>
                                <Typography variant="subtitle2" color="warning.dark" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <CurrencyExchangeIcon fontSize="small" />
                                    Transaction History
                                </Typography>

                                <Divider sx={{ my: 2 }} />

                                <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
                                    You can view your transaction history in the wallet application.
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default FinancialDashboard;
