import React, { useState, useEffect, useRef } from 'react';
import OnlineShopImg from '../assets/Home/Homefour/onlineshopping.jpg';
import GamingImg from '../assets/Home/Homefour/gamingimg.jpg';
import SocialMediaImg from '../assets/Home/Homefour/socialmedia.jpg';
import FoodImg from '../assets/Home/Homefour/Foodimg.jpg';
import TravelImg from '../assets/Home/Homefour/TravelImg.jpg';
import { 
  Container, 
  Box, 
  Typography, 
  useMediaQuery, 
  useTheme, 
  Tabs, 
  Tab, 
  Avatar,
  Paper,
  Grid,
  Fade
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import FlightOutlinedIcon from '@mui/icons-material/FlightOutlined';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';

// Styled components
const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      overflowX: 'auto',
    },
  },
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '16px',
  borderRadius: '50px',
  padding: '8px 16px',
  marginRight: theme.spacing(1),
  color: '#666666',
  transition: 'all 0.3s',
  minWidth: 'auto',
  '&.Mui-selected': {
    color: '#ffffff',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
  },
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    padding: '6px 12px',
  },
}));

const BackgroundImage = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  zIndex: -1,
  opacity: 0.2,
  transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
  transform: 'scale(1.02)', // Slightly larger to avoid white edges during transition
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: -1,
  },
}));

const MerchantCard = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
  marginBottom: theme.spacing(2),
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 20px rgba(0, 0, 0, 0.15)',
  },
}));

const CategorySection = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: '600px',
  padding: theme.spacing(6, 2),
  borderRadius: theme.spacing(3),
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    minHeight: '500px',
    padding: theme.spacing(4, 2),
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: '400px',
    padding: theme.spacing(3, 1),
  },
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(4),
  color: '#ffffff',
  textShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
    marginBottom: theme.spacing(2),
  },
}));

// Content transition wrapper
const TabContentWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
  height: '100%',
  transition: 'all 0.5s ease-in-out',
}));

const HomePageFour = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [activeTab, setActiveTab] = useState(0);
  const [previousTab, setPreviousTab] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const sectionRef = useRef(null);
  const autoPlayRef = useRef(null);
  const [shouldObserve, setShouldObserve] = useState(true);
  const [contentKey, setContentKey] = useState(0); // Used to force re-render of Fade component

  // Tab data
  const tabs = [
    {
      label: 'Online Shopping',
      icon: <ShoppingBagOutlinedIcon />,
      image: OnlineShopImg,
      title: 'Shop Online with Crypto',
      description: 'Purchase from your favorite online retailers using cryptocurrency',
      merchants: [
        { name: 'Amazon', logo: 'https://placehold.co/50x50/FF9900/FFFFFF?text=A', amount: '- $152.75 USD' },
        { name: 'eBay', logo: 'https://placehold.co/50x50/E53238/FFFFFF?text=e', amount: '- $89.99 USD' },
        { name: 'Etsy', logo: 'https://placehold.co/50x50/F56400/FFFFFF?text=E', amount: '- $45.50 USD' }
      ]
    },
    {
      label: 'F&B',
      icon: <RestaurantOutlinedIcon />,
      image: FoodImg,
      title: 'Dine & Pay with Crypto',
      description: 'Enjoy meals from your favorite restaurants and pay using cryptocurrency',
      merchants: [
        { name: 'McDonalds', logo: 'https://placehold.co/50x50/FF0000/FFFF00?text=M', amount: '- $36.00 USD' },
        { name: 'Starbucks', logo: 'https://placehold.co/50x50/006241/FFFFFF?text=S', amount: '- $12.00 USD' },
        { name: 'KFC', logo: 'https://placehold.co/50x50/FF0000/FFFFFF?text=K', amount: '- $56.00 USD' }
      ]
    },
    {
      label: 'Travel',
      icon: <FlightOutlinedIcon />,
      image: TravelImg,
      title: 'Travel with Crypto',
      description: 'Book flights, hotels, and experiences using cryptocurrency',
      merchants: [
        { name: 'Booking.com', logo: 'https://placehold.co/50x50/003580/FFFFFF?text=B', amount: '- $435.25 USD' },
        { name: 'Trip.com', logo: 'https://placehold.co/50x50/1EC6B6/FFFFFF?text=T', amount: '- $289.99 USD' },
        { name: 'Expedia', logo: 'https://placehold.co/50x50/0A84FF/FFFFFF?text=E', amount: '- $567.50 USD' }
      ]
    },
    {
      label: 'Gaming & Tech',
      icon: <SportsEsportsOutlinedIcon />,
      image: GamingImg,
      title: 'Game & Tech with Crypto',
      description: 'Purchase games, in-app items, and tech products using cryptocurrency',
      merchants: [
        { name: 'PUBG', logo: 'https://placehold.co/50x50/F9CA24/000000?text=P', amount: '- $25.99 USD' },
        { name: 'FreeFire', logo: 'https://placehold.co/50x50/FF4B2B/FFFFFF?text=F', amount: '- $15.50 USD' },
        { name: 'Steam', logo: 'https://placehold.co/50x50/1B2838/FFFFFF?text=S', amount: '- $59.99 USD' }
      ]
    },
    {
      label: 'Social',
      icon: <ChatOutlinedIcon />,
      image: SocialMediaImg,
      title: 'Connect with Crypto',
      description: 'Send money to friends and family on social platforms using cryptocurrency',
      merchants: [
        { name: 'Instagram', logo: 'https://placehold.co/50x50/E1306C/FFFFFF?text=I', amount: '- $45.00 USD' },
        { name: 'Facebook', logo: 'https://placehold.co/50x50/1877F2/FFFFFF?text=F', amount: '- $30.00 USD' },
        { name: 'Telegram', logo: 'https://placehold.co/50x50/0088CC/FFFFFF?text=T', amount: '- $25.00 USD' }
      ]
    }
  ];

  // Handle tab change with smoother transitions
  const handleTabChange = (event, newValue) => {
    if (isTransitioning || newValue === activeTab) return;
    
    setPreviousTab(activeTab);
    setIsTransitioning(true);
    setActiveTab(newValue);
    setContentKey(prevKey => prevKey + 1);
    
    // Reset auto-rotation
    clearAutoPlayInterval();
    setShouldObserve(false);
    
    // Resume auto-rotation after user interaction pause
    const timer = setTimeout(() => {
      setIsTransitioning(false);
      setShouldObserve(true);
      startAutoPlay();
    }, 500); // Match transition duration
    
    return () => clearTimeout(timer);
  };

  // Clear interval safely
  const clearAutoPlayInterval = () => {
    if (autoPlayRef.current) {
      clearInterval(autoPlayRef.current);
      autoPlayRef.current = null;
    }
  };

  // Auto-rotation of tabs
  const startAutoPlay = () => {
    // Clear any existing interval first
    clearAutoPlayInterval();
    
    autoPlayRef.current = setInterval(() => {
      if (!isTransitioning) {
        setPreviousTab(activeTab);
        setIsTransitioning(true);
        setContentKey(prevKey => prevKey + 1);
        
        setActiveTab(prev => {
          const nextTab = prev === tabs.length - 1 ? 0 : prev + 1;
          return nextTab;
        });
        
        // Reset transition state after animation completes
        setTimeout(() => {
          setIsTransitioning(false);
        }, 500);
      }
    }, 6000); // Change tab every 6 seconds
  };

  // Set up IntersectionObserver for scroll-based tab changing
  useEffect(() => {
    if (!sectionRef.current || !shouldObserve) return;
    
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            startAutoPlay();
          } else {
            clearAutoPlayInterval();
          }
        });
      },
      { threshold: 0.2 }
    );
    
    observer.observe(sectionRef.current);
    
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
      clearAutoPlayInterval();
    };
  }, [sectionRef, shouldObserve]);
  
  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      clearAutoPlayInterval();
    };
  }, []);

  // Preload images to prevent flickering
  useEffect(() => {
    tabs.forEach(tab => {
      const img = new Image();
      img.src = tab.image;
    });
  }, []);

  return (
    <div
    id='Home-Section-Four'
    maxWidth="xl" disableGutters >
      <CategorySection ref={sectionRef}>
        {/* Background images with crossfade effect */}
        {tabs.map((tab, index) => (
          <BackgroundImage 
            key={index}
            sx={{ 
              backgroundImage: `url(${tab.image})`,
              opacity: activeTab === index ? 1 : 0,
              zIndex: activeTab === index ? -1 : -2,
            }} 
          />
        ))}

        <StyledHeading variant={isMobile ? "h3" : "h2"}>
          MAKE PAYMENT WITH CRYPTO ANYTIME, ANYWHERE
        </StyledHeading>

        <Typography 
          variant={isMobile ? "subtitle1" : "h6"} 
          align="center" 
          sx={{ 
            color: 'white', 
            mb: 4, 
            fontWeight: 300,
            textShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
            maxWidth: '800px',
            mx: 'auto'
          }}
        >
          Accepted by 130M+ merchants globally | Apple Pay/Google Pay compatibility | No advance fiat conversion needed
        </Typography>

        <Box sx={{ mb: 4, position: 'relative', zIndex: 2 }}>
          <StyledTabs
            value={activeTab}
            onChange={handleTabChange}
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : false}
            aria-label="merchant categories"
            sx={{ 
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              borderRadius: '50px',
              padding: '4px',
              maxWidth: isMobile ? '100%' : '800px',
              margin: '0 auto'
            }}
          >
            {tabs.map((tab, index) => (
              <StyledTab 
                key={index} 
                icon={tab.icon} 
                iconPosition="start" 
                label={!isMobile && tab.label} 
                aria-label={tab.label}
                disabled={isTransitioning}
              />
            ))}
          </StyledTabs>
        </Box>

        <TabContentWrapper>
          <Fade 
            key={`content-${activeTab}-${contentKey}`} 
            in={!isTransitioning} 
            timeout={{ enter: 500, exit: 400 }}
            mountOnEnter
            unmountOnExit
          >
            <Box sx={{ mt: 4, position: 'relative', zIndex: 2 }}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={8} lg={6}>
                  <Box sx={{ mb: 3 }}>
                    <Typography 
                      variant={isMobile ? "h5" : "h4"} 
                      sx={{ 
                        fontWeight: 600, 
                        color: 'white',
                        textShadow: '0 1px 2px rgba(0, 0, 0, 0.5)',
                        mb: 1
                      }}
                    >
                      {tabs[activeTab].title}
                    </Typography>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        color: 'white',
                        textShadow: '0 1px 2px rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      {tabs[activeTab].description}
                    </Typography>
                  </Box>

                  {tabs[activeTab].merchants.map((merchant, idx) => (
                    <Fade 
                      key={`merchant-${activeTab}-${idx}`} 
                      in={true} 
                      timeout={500} 
                      style={{ 
                        transitionDelay: `${idx * 100}ms`,
                        transform: `translateY(${isTransitioning ? '20px' : '0'})`, 
                      }}
                    >
                      <MerchantCard>
                        <Avatar 
                          src={merchant.logo} 
                          alt={merchant.name}
                          sx={{ 
                            width: 50, 
                            height: 50, 
                            mr: 2,
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                          }}
                        />
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="h6" sx={{ fontWeight: 500 }}>
                            {merchant.name}
                          </Typography>
                        </Box>
                        <Typography 
                          variant="subtitle1" 
                          sx={{ 
                            fontWeight: 600, 
                            color: 'text.secondary' 
                          }}
                        >
                          {merchant.amount}
                        </Typography>
                      </MerchantCard>
                    </Fade>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </Fade>
        </TabContentWrapper>
      </CategorySection>
    </div>
  );
};

export default HomePageFour;