import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Edit } from "@mui/icons-material";

const ProfileSection = () => {
  const [editMode, setEditMode] = useState(false);
  const token = sessionStorage.getItem("token");
  const userId = sessionStorage.getItem("emp_Id");

  const [formValues, setFormValues] = useState({
    userId: "",
    name: "",
    email: "",
    pan: "",
    mobile: "",
    adhaar: "",
    dob: "",
    walletAddress: "",
    wallet: "",
    status: "",
    role: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://api.erapay.biz/erapay/user/mydetails",
          {
            headers: {
              userid: userId,
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = response.data.user;
        setFormValues({
          userId: data.userId || "",
          name: data.name || "",
          email: data.email || "",
          pan: data.pan || "",
          mobile: data.mobile || "",
          adhaar: data.adhaar || "",
          dob: data.dob || "",
          walletAddress: data.walletAddress || "",
          wallet: data.wallet || "",
          status: data.status || "",
          role: data.role?.userType || "",
        });
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch user details");
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSave = async () => {
    setEditMode(false);
    setLoading(true);
    try {
      await axios.post(
        "https://api.erapay.biz/erapay/user/updateuser",
        {
          userid: formValues.userId,
          name: formValues.name,
          email: formValues.email,
          pan: formValues.pan,
          mobile: formValues.mobile,
          adhaar: formValues.adhaar,
          dob: formValues.dob,
          walletAddress: formValues.walletAddress,
          wallet: formValues.wallet,
          status: formValues.status,
          role: formValues.role,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Profile updated successfully!");
      setLoading(false);
    } catch (err) {
      toast.error("Failed to update user details");
      setError("Failed to update user details");
      setLoading(false);
    }
  };

  return (
    <Container style={{ padding: "2rem" }}>
      <Paper elevation={2} style={{ padding: "2rem", borderRadius: "18px" }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
            My Profile
          </Typography>
          {editMode && (
            <Button
              style={{ backgroundColor: "#027BFE", color: "#ffffff" }}
              onClick={handleSave}
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}
            </Button>
          )}
        </Grid>
        {error && <Typography color="error">{error}</Typography>}

        {/* Profile Header */}
        <Grid
          container
          style={{
            border: "1px solid #D9D9D9",
            borderRadius: "8px",
            marginTop: "2%",
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid
              item
              xs={12}
              md={8}
              style={{ padding: "1rem", display: "flex", alignItems: "center" }}
            >
              <Avatar style={{ width: "70px", height: "70px" }} />
              <Box ml={2}>
                <Typography>
                  <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                    {formValues.name}
                  </span>
                </Typography>
                <Typography color="textSecondary">
                  User ID:{" "}
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    {formValues.userId}
                  </span>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* Personal Information Section */}
        <Grid
          container
          style={{
            border: "1px solid #D9D9D9",
            borderRadius: "8px",
            marginTop: "2%",
          }}
        >
          <Grid item xs={12} style={{ padding: "1rem" }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
                Personal Information
              </Typography>
              <IconButton
                onClick={() => setEditMode(!editMode)}
                style={{ cursor: "pointer" }}
              >
                <Edit />
              </IconButton>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Name</Typography>
                {editMode ? (
                  <TextField
                    name="name"
                    value={formValues.name}
                    onChange={handleInputChange}
                    fullWidth
                  />
                ) : (
                  <Typography style={{ fontWeight: "bold" }}>
                    {formValues.name}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Email Address</Typography>
                {editMode ? (
                  <TextField
                    name="email"
                    value={formValues.email}
                    onChange={handleInputChange}
                    fullWidth
                  />
                ) : (
                  <Typography style={{ fontWeight: "bold" }}>
                    {formValues.email}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Phone Number</Typography>
                <Typography style={{ fontWeight: "bold" }}>
                  {formValues.mobile}
                </Typography>
              </Grid>

              {/* PAN */}
              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">PAN</Typography>
                <Typography style={{ fontWeight: "bold" }}>
                  {formValues.pan}
                </Typography>
              </Grid>

              {/* Aadhaar */}
              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Aadhaar</Typography>
                <Typography style={{ fontWeight: "bold" }}>
                  {formValues.adhaar}
                </Typography>
              </Grid>

              {/* Date of Birth */}
              <Grid item xs={12} sm={6} md={4}>
                <Typography color="textSecondary">Date of Birth</Typography>
                <Typography style={{ fontWeight: "bold" }}>
                  {formValues.dob}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ProfileSection;
