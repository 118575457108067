import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Container,
  Box,
  Typography,
  Paper,
  Divider,
  useMediaQuery,
  InputAdornment,
  IconButton,
  Modal,
} from "@mui/material";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Google } from "@mui/icons-material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6C63FF",
    },
    secondary: {
      main: "#FF6584",
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  shape: {
    borderRadius: 12,
  },
});

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [femail, setFEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = useState(false);

  // Function to check password API
  const checkPassword = async () => {
    if (!email) return;

    try {
      const response = await fetch(
        "https://api.erapay.biz/erapay/auth/checkpassword",
        {
          method: "GET",
          headers: { email: email },
        }
      );

      if (response.ok) {
        toast.success("Email verified successfully!");
      } else {
        toast.error("Invalid email or user not found!");
      }
    } catch (error) {
      toast.error("Error verifying email!");
    }
  };

  // Function to handle login API call
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      toast.error("Please enter both email and password");
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(
        "https://api.erapay.biz/erapay/auth/signin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic e3thcGlfa2V5fX06e3thcGlfc2VjcmV0fX0=",
          },
          body: JSON.stringify({ empId: email, password: password }),
        }
      );

      const data = await response.json();

      switch (data.statusCodeValue) {
        case 200:
          toast.success("Login successful!");

          // Store response data in sessionStorage
          sessionStorage.setItem("token", data.body.token);
          sessionStorage.setItem("role", data.body.role);
          sessionStorage.setItem("name", data.body.user_name);
          sessionStorage.setItem("userId", data.body.userId);
          sessionStorage.setItem("walletAddress", data.body.walletAddress);

          // Navigate to dashboard
          navigate("/dashboard");
          break;
        case 404:
          toast.error(
            data.body?.message ||
            "User not found. Please check your credentials."
          );
          break;
        case 406:
          toast.error(
            data.body?.message || "Incorrect password. Please try again."
          );
          break;
        default:
          toast.error(data.body?.message || "Login failed. Please try again.");
          break;
      }
    } catch (error) {
      toast.error("Network error. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle Google login success
  const handleGoogleLogin = async () => {
    window.location.href =
      "https://api.erapay.biz/erapay/oauth2/authorization/google";
    setIsLoading(true);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleResetPassword = async () => {
    console.log("Reset link sent to:", femail);
    // Call API to send reset password link
    try {
      const response = await fetch(
        "https://api.erapay.biz/erapay/auth/checkpassword",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            email: femail,
          },
        }
      );

      const data = await response.json();
      console.log(data?.data?.emailValidated);
      if (data?.data?.emailValidated) {
        // forget - password;
        try {
          const response = await fetch(
            "https://api.erapay.biz/erapay/auth/forget-password",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                email: femail,
              },
            }
          );

          const data = await response.json();
          console.log(data);
          toast.info(data?.body?.message);
          handleClose(); // Close modal after action
        } catch (error) { }
      } else {
        toast.info("This Email is not Registered Yet..!!");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => setOpen(false);

  return (
    <ThemeProvider theme={theme}>
      <Modal open={open} onClose={handleClose}>
        <Box
          component={Paper}
          elevation={12}
          sx={{
            width: 400,
            p: 4,
            borderRadius: 4,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <Typography variant="h5" gutterBottom>
            Reset Password
          </Typography>
          <TextField
            fullWidth
            label="Enter your email"
            variant="outlined"
            margin="normal"
            value={femail}
            onChange={(e) => setFEmail(e.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleResetPassword}
          >
            Reset Password
          </Button>
        </Box>
      </Modal>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background:
            "linear-gradient(135deg, #6e8efb 0%,rgb(255, 253, 154) 100%)",
          padding: 2,
        }}
      >
        <GoogleOAuthProvider clientId="577852041118-mipl99u7aaicm38kgu1ov3adm18g5c52.apps.googleusercontent.com">
          <Container component="main" maxWidth="sm">
            <ToastContainer position="top-right" autoClose={3000} />

            <Paper
              elevation={12}
              sx={{
                borderRadius: 4,
                padding: isMobile ? 3 : 5,
                background: "rgba(255, 255, 255, 0.95)",
                backdropFilter: "blur(10px)",
                boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 14px 40px rgba(0, 0, 0, 0.15)",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {/* Logo or Brand Element */}
                <Box
                  sx={{
                    width: isMobile ? 60 : 80,
                    height: isMobile ? 60 : 80,
                    borderRadius: "50%",
                    background:
                      "linear-gradient(45deg, #6C63FF,rgb(228, 228, 20))",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    EP
                  </Typography>
                </Box>

                <Typography
                  variant={isMobile ? "h5" : "h4"}
                  sx={{
                    fontWeight: "bold",
                    marginBottom: 1,
                    background: "linear-gradient(45deg, #6C63FF, #FF6584)",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Welcome Back
                </Typography>

                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ marginBottom: 3, textAlign: "center" }}
                >
                  Sign in to access your account
                </Typography>

                <Box sx={{ width: "100%", mb: 3 }}>
                  {/* <GoogleLogin
                    onClick={handleGoogleLoginSuccess}
                    size={isMobile ? "medium" : "large"}
                    width={isMobile ? "100%" : "auto"}
                    theme="filled_blue"
                    shape="pill"
                    text="continue_with"
                    logo_alignment="center"
                  /> */}
                  <Button
                    className="google-login-button"
                    onClick={handleGoogleLogin}
                    variant="contained"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "auto",
                    }}
                  >
                    <Google sx={{ mr: 1 }} /> Login with Google
                  </Button>
                </Box>

                <Divider sx={{ width: "100%", mb: 3 }}>
                  <Typography variant="body2" color="text.secondary">
                    OR
                  </Typography>
                </Divider>

                {/* Email/Password Login Form */}
                <Box
                  component="form"
                  onSubmit={handleLogin}
                  sx={{ width: "100%" }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    label="Email Address"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={checkPassword}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 3,
                      },
                    }}
                  />

                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon color="primary" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 3,
                      },
                    }}
                  />

                  <Button
                    sx={{
                      textAlign: "right",
                      mt: 1,
                      cursor: "pointer",
                      color: "primary.main",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => setOpen(true)}
                  >
                    Forgot Password?
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      mt: 3,
                      mb: 2,
                      py: 1.5,
                      borderRadius: 3,
                      textTransform: "none",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      background: "linear-gradient(45deg, #6C63FF, #FF6584)",
                      boxShadow: "0 4px 20px rgba(108, 99, 255, 0.5)",
                      "&:hover": {
                        background: "linear-gradient(45deg, #5A52E0, #FF4D6D)",
                        boxShadow: "0 6px 25px rgba(108, 99, 255, 0.7)",
                      },
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? "Signing in..." : "Sign In"}
                  </Button>

                  {/* <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      Don't have an account?{' '}
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{
                          color: 'primary.main',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          '&:hover': {
                            textDecoration: 'underline'
                          }
                        }}
                      >
                        Sign Up
                      </Typography>
                    </Typography>
                  </Box> */}
                </Box>
              </Box>
            </Paper>
          </Container>
        </GoogleOAuthProvider>
      </Box>
    </ThemeProvider>
  );
};

export default LoginForm;
