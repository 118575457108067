import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  MenuItem,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  pan: Yup.string()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN format")
    .required("PAN is required"),
  mobile: Yup.string()
    .matches(/^[6-9]\d{9}$/, "Invalid mobile number")
    .required("Mobile number is required"),
  adhaar: Yup.string()
    .matches(/^\d{12}$/, "Invalid Aadhaar number")
    .required("Aadhaar number is required"),
  status: Yup.string().required("Status is required"),
  dob: Yup.date().required("Date of Birth is required"),
  walletAddress: Yup.string().required("Wallet Address is required"),
});

const AddUserDialog = ({ open, handleClose }) => {
  const [emailVerified, setEmailVerified] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [mobileVerified, setMobileVerified] = useState(false);
  const [mobileError, setMobileError] = useState(null);
  const [panVerified, setPanVerified] = useState(false);
  const [panError, setPanError] = useState(null);
  const [adhaarVerified, setAdhaarVerified] = useState(false);
  const [adhaarError, setAdhaarError] = useState(null);

  const token = sessionStorage.getItem("token"); // Retrieve token

  const handleEmailBlur = async () => {
    const email = formik.values.email;

        if (email) {
            try {
                const response = await fetch('https://api.erapay.biz/erapay/user/checkemail', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'email': email,
                    },
                });

        if (response.ok) {
          const result = await response.json();
          if (result.data.success) {
            setEmailVerified(true);
            setEmailError(null);
            setSuccessMessage("Email is verified successfully.");
            toast.success(
              result.data.message || "Email is verified successfully!",
              {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
              }
            );
          } else {
            setEmailVerified(false);
            setEmailError("Email is already in use.");
            setSuccessMessage(null);
            toast.error(result.data.message || "Email is already in use.", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            });
          }
        } else if (response.status === 406) {
          setEmailVerified(false);
          setEmailError("Email is already in use.");
          setSuccessMessage(null);
          toast.error("Email is already in use.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
        } else {
          setEmailVerified(false);
          setEmailError("Failed to verify email.");
          setSuccessMessage(null);
          toast.error("Failed to verify email.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
        }
      } catch (error) {
        setEmailVerified(false);
        setEmailError("Error verifying email.");
        setSuccessMessage(null);
        toast.error("Error verifying email.", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    }
  };

    const handleMobileBlur = async () => {
        const mobile = formik.values.mobile;
        if (mobile) {
            try {
                const response = await fetch('https://api.erapay.biz/erapay/user/checkmobile', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'mobile': mobile,
                    }
                });

        if (response.ok) {
          const result = await response.json();
          if (result.data && result.data.success) {
            setMobileVerified(true);
            setMobileError(null);
            toast.success(
              result.data.message || "Mobile verified successfully!",
              {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
              }
            );
          } else {
            setMobileVerified(false);
            setMobileError("Invalid Mobile");
            toast.error("Invalid Mobile.", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            });
          }
        } else if (response.status === 406) {
          setMobileVerified(false);
          setMobileError("Mobile is already in use.");
          toast.error("Mobile is already in use.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
        } else {
          setMobileVerified(false);
          setMobileError("Invalid response from server");
          toast.error("Invalid response from server.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
        }
      } catch (error) {
        setMobileVerified(false);
        setMobileError("Error checking Mobile.");
        toast.error("Error checking Mobile.", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    }
  };

    const handlePanBlur = async () => {
        const pan = formik.values.pan;
        if (pan) {
            try {
                const response = await fetch('https://api.erapay.biz/erapay/user/checkpan', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'pan': pan,
                    }
                });

        if (response.ok) {
          const result = await response.json();
          if (result.data && result.data.success) {
            setPanVerified(true);
            setPanError(null);
            toast.success(result.data.message || "PAN verified successfully!", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            });
          } else {
            setPanVerified(false);
            setPanError("Invalid PAN");
            toast.error("Invalid PAN.", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            });
          }
        } else if (response.status === 406) {
          setPanVerified(false);
          setPanError("PAN is already in use.");
          toast.error("PAN is already in use.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
        } else {
          setPanVerified(false);
          setPanError("Invalid response from server");
          toast.error("Invalid response from server.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
        }
      } catch (error) {
        setPanVerified(false);
        setPanError("Error checking PAN.");
        toast.error("Error checking PAN.", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    }
  };

    const handleAdhaarBlur = async () => {
        const adhaar = formik.values.adhaar;
        if (adhaar) {
            try {
                const response = await fetch('https://api.erapay.biz/erapay/user/checkadhaar', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'adhaar': adhaar,
                    }
                });

        if (response.ok) {
          const result = await response.json();
          if (result.data && result.data.success) {
            setAdhaarVerified(true);
            setAdhaarError(null);
            toast.success(
              result.data.message || "Adhaar verified successfully!",
              {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
              }
            );
          } else {
            setAdhaarVerified(false);
            setAdhaarError("Invalid Adhaar");
            toast.error("Invalid Adhaar.", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            });
          }
        } else if (response.status === 406) {
          setAdhaarVerified(false);
          setAdhaarError("Adhaar is already in use.");
          toast.error("Adhaar is already in use.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
        } else {
          setAdhaarVerified(false);
          setAdhaarError("Invalid response from server");
          toast.error("Invalid response from server.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
        }
      } catch (error) {
        setAdhaarVerified(false);
        setAdhaarError("Error checking Adhaar.");
        toast.error("Error checking Adhaar.", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      pan: "",
      mobile: "",
      adhaar: "",
      status: "ACTIVE",
      dob: "",
      walletAddress: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log("Form Submitted", values);

      const payload = {
        name: values.name,
        email: values.email,
        pan: values.pan,
        mobile: values.mobile,
        adhaar: values.adhaar,
        status: values.status,
        dob: values.dob,
        walletAddress: values.walletAddress,
      };

            try {
                const response = await fetch('https://api.erapay.biz/erapay/auth/register', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Include token here
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

        if (response.ok) {
          const result = await response.json();
          console.log("API Response:", result);

          if (result.response.success) {
            // Success toast when response.success is true
            alert("User registered successfully!");
            toast.success("User registered successfully!", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            });
            handleClose(); // Close the dialog on success
          } else {
            toast.error("Error while registering user. Please try again.", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            });
          }
        } else if (response.status === 406) {
          // Handle 406 error - Values already present (e.g., email/phone/PAN)
          toast.error("Email/Phone/PAN already in use.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
        } else {
          toast.error("Error while creating user. Please try again.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Error while submitting form. Please try again.", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    },
  });

  useEffect(() => {
    if (!open) {
      // Reset the form or clear form details when the dialog closes
      formik.resetForm(); // Reset the form fields using Formik's resetForm method
      setEmailVerified(false);
      setPanVerified(false);
      setAdhaarVerified(false);
      setMobileVerified(false);
      setPanError(null); // Clear any previous error message when the dialog closes
      setMobileError(null); // Clear any previous error message when the dialog closes
      setEmailError(null); // Clear any previous error message when the dialog closes
      setAdhaarError(null); // Clear any previous error message when the dialog closes
    }
  }, [open]); // Runs when 'open' changes

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "16px", // Slightly larger radius for a modern look
          boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)", // Softer and more spread-out shadow
        },
      }}
    >
      <DialogTitle
        sx={{
          background: "linear-gradient(45deg, #027BFE, #9E4FF9)", // Refined gradient for a vibrant look
          color: "white",
          textAlign: "center",
          fontSize: "1.2rem", // Larger title font size for better readability
          fontWeight: "bold",
          py: 3,
          borderTopLeftRadius: "16px", // Rounded corners on top left and right for consistency
          borderTopRightRadius: "16px",
        }}
      >
        Create a new User
      </DialogTitle>
      <DialogContent
        sx={{
          p: 4,
          maxHeight: "600px", // Increased max height to provide more space
          overflowY: "auto",
          "&::-webkit-scrollbar": { width: "8px" },
          "&::-webkit-scrollbar-thumb": {
            background: "#027BFE",
            borderRadius: "10px",
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {" "}
            {/* Increased spacing for better separation */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                variant="outlined" // Added variant to make it stand out
                sx={{ borderRadius: "8px" }} // Added border radius for the input fields
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  handleEmailBlur();
                }}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                variant="outlined"
                sx={{ borderRadius: "8px" }}
              />
              {emailVerified && (
                <Typography sx={{ color: "green", mt: 1 }}>
                  Verified Email
                </Typography>
              )}
              {emailError && (
                <Typography sx={{ color: "red", mt: 1 }}>
                  {emailError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="PAN"
                name="pan"
                value={formik.values.pan}
                onChange={(e) => {
                  // Convert the input value to uppercase
                  formik.setFieldValue("pan", e.target.value.toUpperCase());
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  handlePanBlur();
                }}
                error={formik.touched.pan && Boolean(formik.errors.pan)}
                helperText={formik.touched.pan && formik.errors.pan}
                variant="outlined"
                sx={{ borderRadius: "8px" }}
              />

              {panVerified && (
                <Typography sx={{ color: "green", mt: 1 }}>
                  Verified Pan
                </Typography>
              )}
              {panError && (
                <Typography sx={{ color: "red", mt: 1 }}>{panError}</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Mobile"
                name="mobile"
                value={formik.values.mobile}
                onChange={formik.handleChange}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  handleMobileBlur();
                }}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
                variant="outlined"
                sx={{ borderRadius: "8px" }}
              />
              {mobileVerified && (
                <Typography sx={{ color: "green", mt: 1 }}>
                  Verified Mobile
                </Typography>
              )}
              {mobileError && (
                <Typography sx={{ color: "red", mt: 1 }}>
                  {mobileError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Aadhaar"
                name="adhaar"
                value={formik.values.adhaar}
                onChange={formik.handleChange}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  handleAdhaarBlur();
                }}
                error={formik.touched.adhaar && Boolean(formik.errors.adhaar)}
                helperText={formik.touched.adhaar && formik.errors.adhaar}
                variant="outlined"
                sx={{ borderRadius: "8px" }}
              />
              {adhaarVerified && (
                <Typography sx={{ color: "green", mt: 1 }}>
                  Verified Adhaar
                </Typography>
              )}
              {adhaarError && (
                <Typography sx={{ color: "red", mt: 1 }}>
                  {adhaarError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Status"
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.status && Boolean(formik.errors.status)}
                helperText={formik.touched.status && formik.errors.status}
                variant="outlined"
                sx={{ borderRadius: "8px" }}
              >
                <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                <MenuItem value="INACTIVE">INACTIVE</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Date of Birth"
                name="dob"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={formik.values.dob}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.dob && Boolean(formik.errors.dob)}
                helperText={formik.touched.dob && formik.errors.dob}
                variant="outlined"
                sx={{ borderRadius: "8px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Wallet Address"
                name="walletAddress"
                value={formik.values.walletAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.walletAddress &&
                  Boolean(formik.errors.walletAddress)
                }
                helperText={
                  formik.touched.walletAddress && formik.errors.walletAddress
                }
                variant="outlined"
                sx={{ borderRadius: "8px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={!formik.isValid || formik.isSubmitting}
                sx={{
                  py: 1.5, // Added padding for better button height
                  borderRadius: "8px", // Button border radius
                  fontWeight: "bold", // Bold text for the button
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="colored"
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddUserDialog;
