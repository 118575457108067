import { Box, Typography, Container, Grid, Link, Button, Stack, Divider, useTheme, useMediaQuery } from '@mui/material';
import React from 'react';
import GooglePlayIcon from '@mui/icons-material/GetApp';
import AppleIcon from '@mui/icons-material/Apple';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ 
      backgroundColor: '#1a1a1a',
      position: 'relative',
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: -100,
        right: -100,
        width: 200,
        height: 200,
        borderRadius: '50%',
        background: 'radial-gradient(circle, rgba(233,30,99,0.2) 0%, rgba(233,30,99,0) 70%)',
        zIndex: 0
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -50,
        left: -50,
        width: 150,
        height: 150,
        borderRadius: '50%',
        background: 'radial-gradient(circle, rgba(33,150,243,0.2) 0%, rgba(33,150,243,0) 70%)',
        zIndex: 0
      }
    }}>
      <Container maxWidth="xl" sx={{ position: 'relative', zIndex: 1, }}>
        {/* Top section with logo and tagline */}
        <Box sx={{ 
          py: 5, 
          display: 'flex', 
          flexDirection: isTablet ? 'column' : 'row',
          alignItems: isTablet ? 'center' : 'flex-start',
          justifyContent: 'space-between'
        }}>
          <Box sx={{ mb: isTablet ? 3 : 0 }}>
            <Typography variant="h4" sx={{ 
              color: 'white', 
              fontWeight: 'bold',
              mb: 1,
            //   backgroundImage: 'linear-gradient(90deg, #E91E63, #2196F3)',
            backgroundImage: 'linear-gradient(90deg,rgb(226, 233, 30),rgb(243, 170, 33))',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}>
              COINERA
            </Typography>
            <Typography variant="body1" sx={{ color: 'white', fontWeight: 500 }}>
            Crypto for Everyone, Everywhere!
            </Typography>
          </Box>
          
          <Button
            variant="contained"
            size="large"
            sx={{ 
              backgroundColor: '#E91E63',
              fontWeight: 'bold',
              borderRadius: 28,
              px: 4,
              '&:hover': {
                backgroundColor: '#D81B60'
              }
            }}
          >
            Get the app
          </Button>
        </Box>
        
        <Divider sx={{ borderColor: 'rgba(255,255,255,0.1)', my: 3 }} />

        {/* Main footer content */}
        <Grid container spacing={isMobile ? 4 : 8} sx={{ py: 4 }}>
          {/* Get the app section */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" sx={{ 
              mb: 3, 
              color: 'white', 
              fontWeight: 'bold',
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -8,
                left: 0,
                width: 40,
                height: 3,
                backgroundColor: '#E91E63',
                borderRadius: 2
              }
            }}>
              Get the app
            </Typography>
            <Stack spacing={2} direction="column">
              <Button
                variant="outlined"
                startIcon={<GooglePlayIcon />}
                sx={{
                  color: 'white',
                  fontWeight: 'bold',
                  borderColor: 'rgba(255,255,255,0.3)',
                  borderRadius: 2,
                  justifyContent: 'flex-start',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    borderColor: '#E91E63',
                    backgroundColor: 'rgba(233,30,99,0.1)'
                  }
                }}
              >
                Google Play
              </Button>
              <Button
                variant="outlined"
                startIcon={<AppleIcon />}
                sx={{
                  color: 'white',
                  fontWeight: 'bold',
                  borderColor: 'rgba(255,255,255,0.3)',
                  borderRadius: 2,
                  justifyContent: 'flex-start',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    borderColor: '#E91E63',
                    backgroundColor: 'rgba(233,30,99,0.1)'
                  }
                }}
              >
                App Store
              </Button>
            </Stack>
          </Grid>

          {/* Payment section */}
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" sx={{ 
              mb: 3, 
              color: 'white', 
              fontWeight: 'bold',
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -8,
                left: 0,
                width: 40,
                height: 3,
                backgroundColor: '#E91E63',
                borderRadius: 2
              }
            }}>
              Payment
            </Typography>
            <Stack spacing={1.5}>
              <Link href="#" underline="none" sx={{ 
                color: 'white',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': { 
                  color: '#E91E63',
                  transform: 'translateX(5px)'
                },
                display: 'inline-block'
              }}>
                Cards
              </Link>
              <Link href="#" underline="none" sx={{ 
                color: 'white',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': { 
                  color: '#E91E63',
                  transform: 'translateX(5px)'
                },
                display: 'inline-block'
              }}>
                Send & Receive
              </Link>
              <Link href="#" underline="none" sx={{ 
                color: 'white',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': { 
                  color: '#E91E63',
                  transform: 'translateX(5px)'
                },
                display: 'inline-block'
              }}>
                International Transfer
              </Link>
            </Stack>
          </Grid>

          {/* Wallet section */}
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="h6" sx={{ 
              mb: 3, 
              color: 'white', 
              fontWeight: 'bold',
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -8,
                left: 0,
                width: 40,
                height: 3,
                backgroundColor: '#E91E63',
                borderRadius: 2
              }
            }}>
              Wallet
            </Typography>
            <Stack spacing={1.5}>
              <Link href="#" underline="none" sx={{ 
                color: 'white',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': { 
                  color: '#E91E63',
                  transform: 'translateX(5px)'
                },
                display: 'inline-block'
              }}>
                Secure Custodian
              </Link>
              <Link href="#" underline="none" sx={{ 
                color: 'white',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': { 
                  color: '#E91E63',
                  transform: 'translateX(5px)'
                },
                display: 'inline-block'
              }}>
                Connect your wallet
              </Link>
            </Stack>
          </Grid>

          {/* Support & Blog merged section */}
          <Grid item xs={6} sm={6} md={2}>
            <Typography variant="h6" sx={{ 
              mb: 3, 
              color: 'white', 
              fontWeight: 'bold',
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -8,
                left: 0,
                width: 40,
                height: 3,
                backgroundColor: '#E91E63',
                borderRadius: 2
              }
            }}>
              Support
            </Typography>
            <Stack spacing={1.5}>
              <Link href="#" underline="none" sx={{ 
                color: 'white',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': { 
                  color: '#E91E63',
                  transform: 'translateX(5px)'
                },
                display: 'inline-block'
              }}>
                FAQ
              </Link>
              <Link href="#" underline="none" sx={{ 
                color: 'white',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': { 
                  color: '#E91E63',
                  transform: 'translateX(5px)'
                },
                display: 'inline-block'
              }}>
                Download App
              </Link>
              <Link href="#" underline="none" sx={{ 
                color: 'white',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': { 
                  color: '#E91E63',
                  transform: 'translateX(5px)'
                },
                display: 'inline-block'
              }}>
                Terms & Conditions
              </Link>
              <Link href="#" underline="none" sx={{ 
                color: 'white',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': { 
                  color: '#E91E63',
                  transform: 'translateX(5px)'
                },
                display: 'inline-block'
              }}>
                Press Release
              </Link>
              <Link href="#" underline="none" sx={{ 
                color: 'white',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': { 
                  color: '#E91E63',
                  transform: 'translateX(5px)'
                },
                display: 'inline-block'
              }}>
                Blog
              </Link>
            </Stack>
          </Grid>

          {/* About Us section */}
          <Grid item xs={6} sm={6} md={3}>
            <Typography variant="h6" sx={{ 
              mb: 3, 
              color: 'white', 
              fontWeight: 'bold',
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -8,
                left: 0,
                width: 40,
                height: 3,
                backgroundColor: '#E91E63',
                borderRadius: 2
              }
            }}>
              About Us
            </Typography>
            <Stack spacing={1.5}>
              <Link href="#" underline="none" sx={{ 
                color: 'white',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': { 
                  color: '#E91E63',
                  transform: 'translateX(5px)'
                },
                display: 'inline-block'
              }}>
                Our Mission
              </Link>
              <Link href="#" underline="none" sx={{ 
                color: 'white',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': { 
                  color: '#E91E63',
                  transform: 'translateX(5px)'
                },
                display: 'inline-block'
              }}>
                Leadership
              </Link>
              <Link href="#" underline="none" sx={{ 
                color: 'white',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': { 
                  color: '#E91E63',
                  transform: 'translateX(5px)'
                },
                display: 'inline-block'
              }}>
                Compliance and Security
              </Link>
              <Link href="#" underline="none" sx={{ 
                color: 'white',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': { 
                  color: '#E91E63',
                  transform: 'translateX(5px)'
                },
                display: 'inline-block'
              }}>
                Partners
              </Link>
              <Link href="#" underline="none" sx={{ 
                color: 'white',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': { 
                  color: '#E91E63',
                  transform: 'translateX(5px)'
                },
                display: 'inline-block'
              }}>
                Join Us
              </Link>
              <Link href="#" underline="none" sx={{ 
                color: 'white',
                fontWeight: 500,
                transition: 'all 0.2s ease',
                '&:hover': { 
                  color: '#E91E63',
                  transform: 'translateX(5px)'
                },
                display: 'inline-block'
              }}>
                Affiliate Program
              </Link>
            </Stack>
          </Grid>
        </Grid>
        
        <Divider sx={{ borderColor: 'rgba(255,255,255,0.1)', my: 3 }} />
        
        {/* Bottom section with social media and copyright */}
        <Box sx={{ 
          py: 3, 
          display: 'flex', 
          flexDirection: isTablet ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2
        }}>
          <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
            © 2025 Coinera. All rights reserved.
          </Typography>
          
          <Stack direction="row" spacing={2}>
            <IconButton size="small" component="a" href="#" sx={{ 
              color: 'white',
              transition: 'all 0.3s ease',
              '&:hover': { 
                color: '#E91E63',
                transform: 'translateY(-3px)'
              }
            }}>
              <TwitterIcon />
            </IconButton>
            <IconButton size="small" component="a" href="#" sx={{ 
              color: 'white',
              transition: 'all 0.3s ease',
              '&:hover': { 
                color: '#E91E63',
                transform: 'translateY(-3px)'
              }
            }}>
              <FacebookIcon />
            </IconButton>
            <IconButton size="small" component="a" href="#" sx={{ 
              color: 'white',
              transition: 'all 0.3s ease',
              '&:hover': { 
                color: '#E91E63',
                transform: 'translateY(-3px)'
              }
            }}>
              <InstagramIcon />
            </IconButton>
            <IconButton size="small" component="a" href="#" sx={{ 
              color: 'white',
              transition: 'all 0.3s ease',
              '&:hover': { 
                color: '#E91E63',
                transform: 'translateY(-3px)'
              }
            }}>
              <LinkedInIcon />
            </IconButton>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

// Add the missing IconButton component
const IconButton = ({ children, sx, ...props }) => {
  return (
    <Box 
      component="button" 
      sx={{ 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 36,
        height: 36,
        borderRadius: '50%',
        backgroundColor: 'rgba(255,255,255,0.1)',
        border: 'none',
        cursor: 'pointer',
        transition: 'all 0.2s',
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.2)',
        },
        ...sx
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default Footer;