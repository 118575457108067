


import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Container,
  Typography,
  Button,
  Fade
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Coinera from "../assets/Home/Morque/CoinEraname.png";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const menuItems = [
    { label: 'Home', id: 'Home-Section-One' },
    { label: 'About', id: 'Home-Section-Two' },
    { label: 'Services', id: 'Home-Section-Three' },
    { label: 'Pricing', id: 'Home-Section-Four' },
    { label: 'Contact', id: 'Home-Section-Five' },
  ];

  const handleLoginClick = () => {
    navigate("/login");
  };

  // Scroll Handling
  const handleMenuClick = (id) => {
    setOpen(false); // Close the drawer
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <AppBar
        position="fixed"
        elevation={scrolled ? 4 : 0}
        sx={{
          transition: "all 0.3s ease",
          background: scrolled ? "rgba(255, 255, 255, 0.85)" : "rgba(255, 255, 255, 0.15)",
          backdropFilter: "blur(10px)",
          boxShadow: scrolled ? "0px 4px 20px rgba(0, 0, 0, 0.1)" : "none",
          color: "#ffffff",
          height: { xs: "70px", md: "90px" },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar sx={{ justifyContent: "space-between", height: "100%", px: { xs: 2, md: 4 } }}>
            {/* Logo */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={Coinera} alt="Coinera Logo" style={{ height: "210px", width: "auto", filter: "brightness(1.2)" }} />
            </Box>

            {/* Hamburger Menu */}
            <IconButton
              edge="end"
              aria-label="menu"
              onClick={toggleDrawer}
              sx={{
                color: "#000000",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.2)" },
                padding: "12px",
              }}
            >
              <MenuIcon sx={{ fontSize: 32 }} />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Mobile Navigation Drawer */}
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer}
        PaperProps={{
          sx: {
            width: "100%",
            height: "100%",
            background: "linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)",
            color: "white",
          },
        }}
      >
        <Box sx={{ p: 4, display: "flex", flexDirection: "column", height: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 8 }}>
            <img src={Coinera} alt="Coinera Logo" style={{ height: "210px", filter: "brightness(1.2)" }} />
            <IconButton
              onClick={toggleDrawer}
              sx={{
                color: "white",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                padding: "12px",
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.2)" },
              }}
            >
              <CloseIcon sx={{ fontSize: 32 }} />
            </IconButton>
          </Box>

          <List sx={{ flexGrow: 1 }}>
            {menuItems.map((item, index) => (
              <Fade in={open} style={{ transitionDelay: `${index * 100}ms` }} key={index}>
                <ListItem
                  button
                  onClick={() => handleMenuClick(item.id)}
                  sx={{
                    py: 3,
                    borderBottom: "1px solid rgba(255,255,255,0.1)",
                    "&:hover": { backgroundColor: "rgba(255,255,255,0.1)" },
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="h5" sx={{ fontWeight: 600, letterSpacing: "0.5px" }}>
                        {item.label}
                      </Typography>
                    }
                  />
                </ListItem>
              </Fade>
            ))}
          </List>

          <Box sx={{ p: 2 }}>
            <Button
              variant="contained"
              onClick={handleLoginClick}
              fullWidth
              sx={{
                py: 2,
                borderRadius: "50px",
                backgroundColor: "white",
                color: "#1a237e",
                fontWeight: 600,
                fontSize: "1.1rem",
                "&:hover": { backgroundColor: "rgba(255,255,255,0.9)" },
              }}
            >
              Get Started
            </Button>
          </Box>
        </Box>
      </Drawer>

      <Toolbar sx={{ height: { xs: "70px", md: "90px" } }} />
    </>
  );
};

export default Header;

