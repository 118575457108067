import { Button, Container, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export const NotRegistered = () => {
  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background:
            "linear-gradient(135deg, #6e8efb 0%,rgb(255, 253, 154) 100%)",
          padding: 2,
        }}
      >
        <Container sx={{ minHeight: "100vh" }}>
          <Paper
            elevation={12}
            sx={{
              borderRadius: 4,
              minHeight: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <Typography variant="h4">
                You are not Registered with us..!!
              </Typography>
              <Typography variant="h5">
                Please complete your Registration with Admin..!!
              </Typography>
              <Typography variant="h6">
                You can close this Window..!!
              </Typography>
            </Box>
          </Paper>
        </Container>
      </Box>
    </>
  );
};
