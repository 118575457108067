import React, { useState } from 'react';
import { 
  Container, 
  Grid, 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Paper, 
  Card, 
  CardContent,
  IconButton,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme,
  Divider,
  Tooltip
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SendIcon from '@mui/icons-material/Send';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const ContactUsPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    
    // Clear error when typing
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: ''
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }
    
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }
    
    if (!formData.subject.trim()) {
      newErrors.subject = 'Subject is required';
    }
    
    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    } else if (formData.message.trim().length < 10) {
      newErrors.message = 'Message must be at least 10 characters';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (validateForm()) {
      // In a real app, you would send the form data to your server here
      console.log('Form submitted:', formData);
      
      // Show success message
      setSnackbar({
        open: true,
        message: 'Message sent successfully! We will get back to you soon.',
        severity: 'success'
      });
      
      // Reset form
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    } else {
      setSnackbar({
        open: true,
        message: 'Please fix the errors in the form.',
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false
    });
  };

  const contactInfo = [
    {
      icon: <LocationOnIcon />,
      title: 'Our Location',
      content: 'Edsom Fintech Pvt. Ltd.'
    },
    {
      icon: <PhoneIcon />,
      title: 'Phone Number',
      content: '+1 (555) 123-4567'
    },
    {
      icon: <EmailIcon />,
      title: 'Email Address',
      content: 'support@coinera.com'
    },
    {
      icon: <AccessTimeIcon />,
      title: 'Working Hours',
      content: 'Mon - Fri: 9:00 AM - 6:00 PM'
    }
  ];

  const socialLinks = [
    { icon: <FacebookIcon />, name: 'Facebook' },
    { icon: <TwitterIcon />, name: 'Twitter' },
    { icon: <LinkedInIcon />, name: 'LinkedIn' },
    { icon: <InstagramIcon />, name: 'Instagram' }
  ];

  return (
    <Box 
    id="Home-Section-Five"
      sx={{
        background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e8f0 100%)',
        py: { xs: 6, md: 10 },
        minHeight: '100vh'
      }}
    >
      <Container maxWidth="lg">
        {/* Page Header */}
        <Box sx={{ textAlign: 'center', mb: { xs: 5, md: 8 } }}>
          <Typography 
            variant="h2" 
            component="h1" 
            sx={{ 
              fontWeight: 800, 
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              mb: 2,
              background: 'linear-gradient(90deg, #3f51b5 0%, #2196f3 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textShadow: '0px 4px 10px rgba(0,0,0,0.05)'
            }}
          >
            Get In Touch
          </Typography>
          <Typography 
            variant="h6" 
            sx={{ 
              color: 'text.secondary',
              maxWidth: '800px',
              mx: 'auto',
              mb: 3,
              px: { xs: 2, md: 0 }
            }}
          >
            Have questions or need assistance? Our team is here to help you. Reach out to us using any of the methods below.
          </Typography>
          
          {/* Social Media Links */}
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 4 }}>
            {socialLinks.map((link, index) => (
              <Tooltip key={index} title={link.name}>
                <IconButton 
                  color="primary"
                  sx={{ 
                    transition: 'all 0.3s ease',
                    '&:hover': { 
                      transform: 'translateY(-5px)',
                      boxShadow: '0 5px 15px rgba(63, 81, 181, 0.2)'
                    } 
                  }}
                >
                  {link.icon}
                </IconButton>
              </Tooltip>
            ))}
          </Box>
        </Box>

        <Grid container spacing={4}>
          {/* Contact Form */}
          <Grid item xs={12} md={6}>
            <Paper
              elevation={3}
              sx={{
                borderRadius: 4,
                overflow: 'hidden',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.95)',
                backdropFilter: 'blur(10px)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 15px 30px rgba(0, 0, 0, 0.1)'
                }
              }}
            >
              <Box 
                component="form" 
                onSubmit={handleSubmit}
                sx={{ 
                  p: { xs: 3, md: 4 },
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Typography 
                  variant="h4" 
                  component="h2" 
                  sx={{ 
                    fontWeight: 700, 
                    mb: 1,
                    color: '#1a237e'
                  }}
                >
                  Send us a Message
                </Typography>
                
                <Divider sx={{ mb: 3, width: '50px', height: '4px', backgroundColor: '#3f51b5', borderRadius: '2px' }} />

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Your Name"
                      name="name"
                      variant="outlined"
                      value={formData.name}
                      onChange={handleChange}
                      error={!!errors.name}
                      helperText={errors.name}
                      InputProps={{
                        sx: { borderRadius: 2 }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Email Address"
                      name="email"
                      variant="outlined"
                      value={formData.email}
                      onChange={handleChange}
                      error={!!errors.email}
                      helperText={errors.email}
                      InputProps={{
                        sx: { borderRadius: 2 }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Subject"
                      name="subject"
                      variant="outlined"
                      value={formData.subject}
                      onChange={handleChange}
                      error={!!errors.subject}
                      helperText={errors.subject}
                      InputProps={{
                        sx: { borderRadius: 2 }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Your Message"
                      name="message"
                      variant="outlined"
                      multiline
                      rows={6}
                      value={formData.message}
                      onChange={handleChange}
                      error={!!errors.message}
                      helperText={errors.message}
                      InputProps={{
                        sx: { borderRadius: 2 }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      endIcon={<SendIcon />}
                      sx={{
                        py: 1.5,
                        px: 4,
                        borderRadius: 8,
                        fontWeight: 600,
                        fontSize: '1rem',
                        boxShadow: '0 8px 20px rgba(63, 81, 181, 0.3)',
                        background: 'linear-gradient(90deg, #3f51b5 0%, #2196f3 100%)',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          boxShadow: '0 10px 25px rgba(63, 81, 181, 0.5)',
                          transform: 'translateY(-2px)'
                        }
                      }}
                    >
                      Send Message
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12} md={6}>
            <Box 
              sx={{ 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 4
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  borderRadius: 4,
                  overflow: 'hidden',
                  background: 'linear-gradient(135deg, #3f51b5 0%, #2196f3 100%)',
                  color: 'white',
                  p: { xs: 3, md: 4 },
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.15)'
                  }
                }}
              >
                <Typography 
                  variant="h4" 
                  component="h2" 
                  sx={{ 
                    fontWeight: 700, 
                    mb: 1 
                  }}
                >
                  Contact Information
                </Typography>
                
                <Divider sx={{ mb: 3, width: '50px', height: '4px', backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: '2px' }} />
                
                <Typography sx={{ mb: 4, opacity: 0.9 }}>
                  Feel free to reach out to us using any of the contact methods below. Our team is available to assist you with any inquiries.
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  {contactInfo.map((info, index) => (
                    <Box 
                      key={index} 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'flex-start', 
                        gap: 2,
                        transition: 'transform 0.2s ease',
                        '&:hover': {
                          transform: 'translateX(5px)'
                        }
                      }}
                    >
                      <IconButton 
                        sx={{ 
                          backgroundColor: 'rgba(255, 255, 255, 0.2)',
                          color: 'white',
                          p: 1.5,
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.3)'
                          }
                        }}
                      >
                        {info.icon}
                      </IconButton>
                      <Box>
                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 0.5 }}>
                          {info.title}
                        </Typography>
                        <Typography sx={{ opacity: 0.9 }}>
                          {info.content}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Paper>

              {/* Google Maps Integration */}
              <Paper
                elevation={3}
                sx={{
                  borderRadius: 4,
                  overflow: 'hidden',
                  flex: 1,
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.15)'
                  }
                }}
              >
                <Box sx={{ position: 'relative', height: '100%', minHeight: '280px' }}>
                  {/* Google Maps iframe */}
                  <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7564.373072077908!2d73.76916137372169!3d18.565627167799295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf1ac00cc3cf%3A0x443199f2b7816250!2sEdsom%20Fintech%20Pvt.%20Ltd.!5e0!3m2!1sen!2sus!4v1740662304602!5m2!1sen!2sus" 
                    width="100%" 
                    height="100%" 
                    style={{ border: 0, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} 
                    allowFullScreen="" 
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Google Maps"
                  ></iframe>
                  
                  {/* Location Info Overlay */}
                  <Box 
                    sx={{ 
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 70%, transparent 100%)',
                      color: 'white',
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2
                    }}
                  >
                    <IconButton 
                      sx={{ 
                        backgroundColor: 'rgba(63, 81, 181, 0.8)',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: 'rgba(63, 81, 181, 1)'
                        }
                      }}
                    >
                      <LocationOnIcon />
                    </IconButton>
                    <Box>
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        Visit Our Office
                      </Typography>
                      <Typography variant="body2">
                      Edsom Fintech Pvt. Ltd.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
        
        {/* Footer Section */}
        {/* <Box sx={{ textAlign: 'center', mt: 8, color: 'text.secondary' }}>
          <Typography variant="body2">
            © {new Date().getFullYear()} Coinera. All rights reserved.
          </Typography>
        </Box> */}
      </Container>

      {/* Success/Error Notification */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          sx={{ 
            width: '100%',
            boxShadow: '0 5px 15px rgba(0,0,0,0.1)'
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ContactUsPage;