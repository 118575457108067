import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFlip, Navigation, Pagination, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/navigation";
import "swiper/css/pagination";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import { IconButton } from "@mui/material";
import { PictureAsPdf, PhotoCamera, Image } from "@mui/icons-material"; // MUI icons

const GradientCard = ({ cardDetails, cardType }) => {
  const handleDownloadImage = (format) => {
    const cardFront = document.getElementById("cardFront"); // Select only the front side of the card

    if (!cardFront) {
      console.error("Card front not found!");
      return;
    }

    html2canvas(cardFront, { useCORS: true }).then((canvas) => {
      const ctx = canvas.getContext("2d");
      ctx.font = "bold 20px Arial";
      ctx.fillStyle = "#FFD700"; // Gold color
      ctx.fillText("ERA Pay", canvas.width - 10, 40); // Adjust position as needed

      if (format === "png") {
        const image = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = image;
        link.download = "yourCard.png";
        link.click();
      } else if (format === "pdf") {
        html2pdf()
          .from(canvas)
          .toPdf()
          .get("pdf")
          .then((pdfDoc) => {
            pdfDoc.save("yourCard.pdf");
          });
      }
    });
  };

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle empty values
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      year: "2-digit",
    });
  };

  return (
    <div id="cardContainer" style={styles.container}>
      {/* Download buttons with MUI icons above the card */}
      <div style={styles.downloadButtons}>
        <IconButton
          onClick={() => handleDownloadImage("png")}
          style={styles.iconButton}
        >
          <Image style={styles.icon} />
        </IconButton>
        <IconButton
          onClick={() => handleDownloadImage("jpeg")}
          style={styles.iconButton}
        >
          <PhotoCamera style={styles.icon} />
        </IconButton>
        <IconButton
          onClick={() => handleDownloadImage("pdf")}
          style={styles.iconButton}
        >
          <PictureAsPdf style={styles.icon} />
        </IconButton>
      </div>

      <Swiper
        effect="flip"
        grabCursor={true}
        modules={[EffectFlip, Navigation, Pagination, A11y]}
        navigation
        pagination={{ clickable: true }}
        className="card-swiper"
        style={styles.swiper}
      >
        <SwiperSlide>
          <div id="cardFront" style={styles.cardFront}>
            <div style={styles.circleOne}></div>
            <div style={styles.circleTwo}></div>

            {/* Conditionally render chip only if cardType is 'VIRTUAL' */}
            {cardType === "PHYSICAL" && (
              <img src="/cardchip.svg" alt="Card Chip" style={styles.chip} />
            )}

            <div style={styles.cardNumberContainer}>
              <div style={styles.cardNumber}>{cardDetails?.cardNumber}</div>
              <div style={styles.logo}>{cardDetails?.cardNetwork}</div>
            </div>

            <div style={styles.details}>
              <div>
                <span style={styles.label}>CARDHOLDER</span>
                <div style={styles.text}>{cardDetails?.name}</div>
              </div>
              <div>
                <span style={styles.label}>EXPIRES</span>
                <div style={styles.text}>{formatDate(cardDetails?.end)}</div>
              </div>
            </div>

            <div style={styles.bankName}>ERA Pay</div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div style={styles.cardBack}>
            <div style={styles.magneticStripe}></div>
            <div style={styles.cvvBox}>
              <div style={styles.cvvText}>{cardDetails?.cvv}</div>
              <span style={styles.label}>CVV</span>
            </div>
            <div style={styles.terms}>
              For customer service, call +91-9876543210. Use of this card
              constitutes acceptance of our terms and conditions.
            </div>
            <div style={styles.bankNameBack}>ERA Pay</div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

// STYLES (same as before)
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "#f5f5f5",
    padding: "0 10px",
    position: "relative", // Ensure download buttons appear above the card
  },
  swiper: {
    width: "100%",
    maxWidth: "500px",
    margin: "0 auto",
  },
  downloadButtons: {
    position: "absolute", // Position buttons above the card
    top: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: "10", // Ensure buttons are above the card content
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  iconButton: {
    backgroundColor: "#633EF8",
    margin: "0 10px",
    padding: "10px",
    borderRadius: "50%",
    color: "#fff",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  icon: {
    fontSize: "24px",
  },
  cardNumberContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    bottom: "90px",
    left: "30px",
    width: "80%",
  },
  cardNumber: {
    fontSize: "22px",
    fontWeight: "bold",
    letterSpacing: "2px",
  },
  cardFront: {
    width: "100%",
    height: "300px",
    background: "linear-gradient(90deg, #633EF8 0%, #633EF8 100%)",
    borderRadius: "20px",
    position: "relative",
    overflow: "hidden",
    padding: "20px",
    color: "#fff",
    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
    transition: "transform 0.3s ease-in-out",
  },
  cardBack: {
    width: "100%",
    height: "300px",
    background: "linear-gradient(90deg, #633EF8 0%, #633EF8 100%)",
    borderRadius: "20px",
    position: "relative",
    overflow: "hidden",
    padding: "20px",
    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
  },
  circleOne: {
    width: "391px",
    height: "391px",
    backgroundColor: "#8567FF",
    position: "absolute",
    left: "214px",
    top: "-22px",
    borderRadius: "50%",
    opacity: "0.4",
  },
  circleTwo: {
    width: "343px",
    height: "343px",
    backgroundColor: "#7856FF",
    position: "absolute",
    left: "252.2px",
    top: "88px",
    borderRadius: "50%",
    opacity: "0.4",
  },
  chip: {
    width: "60px",
    height: "40px",
    position: "absolute",
    top: "50px",
    left: "30px",
  },
  details: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    bottom: "40px",
    left: "30px",
    width: "80%",
  },
  label: {
    fontSize: "10px",
    fontWeight: "bold",
    opacity: "0.8",
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  bankName: {
    position: "absolute",
    top: "20px",
    right: "30px",
    fontSize: "18px",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "1px",
    color: "linear-gradient(90deg, #FFD700, #FFA500)", // Gold to Orange gradient
    // color: "transparent", // Keeps the text color transparent
    backgroundClip: "text", // Will clip the gradient to the text
    WebkitBackgroundClip: "text", // This will clip it in WebKit browsers
    textShadow: "1px 1px 3px rgba(0, 0, 0, 0.3)",
  },

  logo: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  magneticStripe: {
    width: "100%",
    height: "50px",
    backgroundColor: "#222",
    position: "absolute",
    top: "30px",
  },
  cvvBox: {
    width: "80px",
    height: "30px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    position: "absolute",
    top: "100px",
    right: "30px",
    textAlign: "center",
    paddingTop: "5px",
    fontWeight: "bold",
    color: "#000",
  },
  cvvText: {
    fontSize: "16px",
  },
  terms: {
    fontSize: "10px",
    opacity: "0.8",
    position: "absolute",
    bottom: "40px",
    left: "30px",
    right: "30px",
  },
  bankNameBack: {
    position: "absolute",
    bottom: "10px",
    left: "30px",
    fontSize: "16px",
    fontWeight: "bold",
  },
};

export default GradientCard;
