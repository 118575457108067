import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Tooltip,
  Switch,
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  Pagination,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add"; // MUI Add Icon
import { styled } from "@mui/material/styles";
import CustomPagination from "./CustomPagination";

const MaterialUISwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    backgroundColor: "#FF4D4D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const UserTable = ({
  searchQuery,
  handleSearchChange,
  handleSearchSubmit,
  partners,
  tablemsg,
  handleViewOpen,
  handleEditOpen,
  handleOpenModal,
  handleStatusToggle,
  pageSize,
  currentPage,
  totalRecords,
  mode,
  handlePageChange,
  handlePageSizeChange,
  handleOpen,
  handleAddUser, // Add user handler passed as prop
}) => {
  const [userType, setUserType] = useState("");
  const theme = useTheme(); // Get the current theme

  useEffect(() => {
    const storedUserType = sessionStorage.getItem("role");
    setUserType(storedUserType);
  }, []);

  const isAdmin = userType === "ADMIN"; // Adjust based on your roles

  // Theme-dependent styles
  const backgroundColor = theme.palette.mode === "dark" ? "#424242" : "#ffffff";
  const textColor = theme.palette.mode === "dark" ? "#ffffff" : "#343C6A";
  const tableHeaderColor = theme.palette.mode === "dark" ? "#333333" : "#f2b173";
  const rowColorEven = theme.palette.mode === "dark" ? "#616161" : "#FFFFFF";
  const rowColorOdd = theme.palette.mode === "dark" ? "#757575" : "#fbe5d0";

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid>
          <Typography
            style={{
              fontWeight: "bold",
              fontFamily: "sans-serif",
              fontSize: "19px",
              color: textColor,
            }}
          >
            Users List
          </Typography>
        </Grid>
        <Grid sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Button
            sx={{
              backgroundColor: "#4caf50",
              color: "white",
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "#45a049",
              },
            }}
            variant="contained"
            onClick={handleOpen}
            startIcon={<AddIcon />}
          >
            <Typography variant="button" sx={{ textTransform: "none" }}>
              Add User
            </Typography>
          </Button>
          <TextField
            variant="outlined"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={handleSearchSubmit}
            size="small"
          />
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{
          marginTop: 2,
          borderRadius: 2,
          boxShadow: 3,
          overflowX: "auto",
          backgroundColor: backgroundColor,
        }}
      >
        <Table>
          <TableHead sx={{ backgroundColor: tableHeaderColor }}>
            <TableRow>
              {[
                "Sr. No.",
                "User ID",
                "Name",
                "Email",
                "Mobile Number",
                "Adhaar",
                "Wallet Balance",
                "Status",
              ]
                .concat(isAdmin ? ["Actions"] : [])
                .map((header) => (
                  <TableCell
                    key={header}
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      padding: "10px",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {partners.length === 0 ? (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  {tablemsg || "No records found."}
                </TableCell>
              </TableRow>
            ) : (
              partners.map((row, index) => (
                <TableRow
                  key={row.userId}
                  sx={{
                    backgroundColor: index % 2 === 0 ? rowColorEven : rowColorOdd,
                  }}
                >
                  <TableCell>{currentPage * pageSize + index + 1}</TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        <div>
                          <strong>Name:</strong> {row.name} <br />
                          <strong>Email:</strong> {row.email} <br />
                          <strong>Mobile Number:</strong> {row.mobile} <br />
                          <strong>Wallet Balance:</strong> ₹
                          {row.wallet.toFixed(2)} <br />
                          <strong>Status:</strong> {row.status} <br />
                        </div>
                      }
                      placement="top"
                    >
                      <span style={{ cursor: "pointer", color: "#027BFE" }}>
                        {row.userId}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.mobile}</TableCell>
                  <TableCell>{row.adhaar}</TableCell>
                  <TableCell>₹{row.wallet.toFixed(2)}</TableCell>
                  <TableCell>
                    <MaterialUISwitch
                      checked={row.status === "ACTIVE"}
                      onChange={
                        isAdmin ? () => handleStatusToggle(row) : undefined
                      }
                      disabled={!isAdmin}
                    />
                  </TableCell>

                  {isAdmin && (
                    <TableCell>
                      <Tooltip title="View Details">
                        <IconButton
                          onClick={() => handleViewOpen(row)}
                          sx={{ color: "#027BFE" }}
                        >
                          <Visibility fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit User">
                        <IconButton
                          onClick={() => handleEditOpen(row)}
                          sx={{ color: "#FFA500" }}
                          disabled={!isAdmin}
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete User">
                        <IconButton
                          onClick={() => handleOpenModal(row)}
                          sx={{ color: "#FF0000" }}
                          disabled={!isAdmin}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: "1%" }}
      >
        <Grid item>
          <Select
            variant="outlined"
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
          <Typography display="inline" style={{ marginLeft: 8 }}>
            Showing {currentPage * pageSize + 1} to{" "}
            {Math.min((currentPage + 1) * pageSize, totalRecords)} out of{" "}
            {totalRecords} records
          </Typography>
        </Grid>
        <Grid item>
          <Pagination
            count={Math.ceil(totalRecords / pageSize)}
            color="primary"
            page={currentPage + 1}
            onChange={handlePageChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UserTable;
