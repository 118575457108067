import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
  Pagination,
  Box,
  IconButton,
  Dialog,
  FormControl,
  InputLabel,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Slide,
} from "@mui/material";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Close as CloseIcon, CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from '@mui/icons-material';

const DepositReport = () => {
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState();
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [confirmationOpen, setConfirmationOpen] = useState(false); // Confirmation Dialog state
  const [selectedDeposit, setSelectedDeposit] = useState(null); // To store the selected deposit for the confirmation

  const userType = sessionStorage.getItem("role");

  const fetchData = async () => {
    setLoading(true);
    const token = sessionStorage.getItem("token");

    try {
      const apiUrl =
        userType === "ADMIN"
          ? "https://api.erapay.biz/erapay/admin/depositlist"
          : "https://api.erapay.biz/erapay/user/mydeposits";

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          currPage: currentPage,
          pageSize: pageSize,
        },
      });

      if (response.status === 200) {
        setData(response.data?.data?.content || []);
        setTotalRecords(response.data?.data?.totalElements || 0);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeStatus = (id, newStatus, coins) => {
    if (newStatus === "COMPLETED") {
      // If status is 'COMPLETED', open the confirmation dialog
      setSelectedDeposit({ id, coins });
      setConfirmationOpen(true);
    } else {
      // No confirmation needed, just call the API
      callApiUpdateStatus(id, newStatus, coins);
    }
  };

  const callApiUpdateStatus = async (id, newStatus, coins) => {
    const token = sessionStorage.getItem("token");

    try {
      // Multiply coins by 21 when the status is "COMPLETED"
      const amount = newStatus === "COMPLETED" ? coins * 21 : undefined;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          status: newStatus,
          id: id,
          ...(amount !== undefined && { amount }), // Add amount header if it's calculated
        },
      };

      const response = await axios.put(
        "https://api.erapay.biz/erapay/admin/changedepositstatus",
        {}, // Empty payload since coins are passed in headers
        config
      );

      if (response.status === 200) {
        alert("Status updated successfully!");
        fetchData(); // Refresh data after update
      } else {
        alert("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      alert("Error updating status");
    }
  };



  const handleConfirmationClose = (confirmed) => {
    if (confirmed && selectedDeposit) {
      // Call API with the selected deposit's coins
      callApiUpdateStatus(selectedDeposit.id, "COMPLETED", selectedDeposit.coins);
    }
    setConfirmationOpen(false); // Close the confirmation dialog
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setCurrentPage(0);
  };

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box style={{ padding: "2rem" }}>
      <Grid>
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "sans-serif",
            fontSize: "19px",
            color: "#343C6A",
          }}
        >
          Deposit Reports
        </Typography>
      </Grid>

      <Grid style={{ marginTop: "1%" }}>
        {loading ? (
          <Grid sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Grid>
        ) : (
          <TableContainer
            component={Paper}
            sx={{ overflowX: "auto", borderRadius: "12px 12px 0px 0px" }}
          >
            <Table>
              <TableHead style={{ backgroundColor: "#f2b173", color: "white" }}>
                <TableRow>
                  <TableCell sx={{ color: "white" }}>Sr No.</TableCell>
                  {userType === "ADMIN" && (
                    <>
                      <TableCell sx={{ color: "white" }}>ID</TableCell>
                      <TableCell sx={{ color: "white" }}>User Name</TableCell>
                      <TableCell sx={{ color: "white" }}>Mobile No.</TableCell>
                    </>
                  )}
                  <TableCell sx={{ color: "white" }}>Deposit Coin</TableCell>
                  <TableCell sx={{ color: "white" }}>Deposit Time</TableCell>
                  <TableCell sx={{ color: "white" }}>Deposit From</TableCell>
                  <TableCell sx={{ color: "white" }}>View</TableCell>
                  <TableCell sx={{ color: "white" }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#fbe5d0",
                      }}
                    >
                      <TableCell>{currentPage * pageSize + index + 1}</TableCell>
                      {userType === "ADMIN" && (
                        <>
                          <TableCell>{row.id || "N/A"}</TableCell>
                          <TableCell>{row.user.name || "N/A"}</TableCell>
                          <TableCell>{row.user.mobile || "N/A"}</TableCell>
                        </>
                      )}
                      <TableCell>{row.coins || "N/A"}</TableCell>
                      <TableCell>{row.depositTime || "N/A"}</TableCell>
                      <TableCell>{row.depositedFromWallet || "N/A"}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            handleOpen(
                              `https://api.erapay.biz/erapay/auth/screenshots/${row.url}`
                            )
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        {userType === "ADMIN" && row.status !== "COMPLETED" ? (
                          <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                              label="Status"
                              value={row.status || ""}
                              onChange={(e) => handleChangeStatus(row.id, e.target.value, row.coins)}
                            >
                              <MenuItem value="COMPLETED">Completed</MenuItem>
                              <MenuItem value="PROCESSING">Processing</MenuItem>
                              <MenuItem value="REJECTED">Rejected</MenuItem>
                            </Select>
                          </FormControl>
                        ) : (
                          <TextField value={row.status || "N/A"} disabled fullWidth />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>

      {/* Image Modal */}
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <img src={selectedImage} alt="Screenshot" style={{ width: "100%", height: "auto" }} />
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        TransitionComponent={Transition}  // Optional for smooth transition
        PaperProps={{
          sx: {
            backgroundColor: '#f5f5f5', // Light background color for the dialog
            padding: '20px', // Add some padding to the dialog
            borderRadius: '8px', // Rounded corners
            minWidth: '300px', // Minimum width for the dialog
          },
        }}
      >
        <DialogTitle sx={{ textAlign: 'center', color: '#1976d2' }}>
          Confirm Status Change
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setConfirmationOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'gray',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
          <Typography variant="body1" sx={{ fontSize: '16px', color: '#333' }}>
            Are you sure you want to mark this deposit as "Completed"?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            onClick={() => handleConfirmationClose(false)}
            color="error"
            variant="contained"
            sx={{
              borderRadius: '20px', // Rounded button corners
              padding: '6px 16px',
              minWidth: '120px',
            }}
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmationClose(true)}
            color="success"
            variant="contained"
            sx={{
              borderRadius: '20px',
              padding: '6px 16px',
              minWidth: '120px',
            }}
            startIcon={<CheckCircleIcon />}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Pagination */}
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: "1%" }}
      >
        <Grid item>
          <Select
            variant="outlined"
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
          <Typography display="inline" style={{ marginLeft: 8 }}>
            Showing {currentPage * pageSize + 1} to{" "}
            {Math.min((currentPage + 1) * pageSize, totalRecords)} out of{" "}
            {totalRecords} records
          </Typography>
        </Grid>
        <Grid item>
          <Pagination
            count={Math.ceil(totalRecords / pageSize)}
            color="primary"
            page={currentPage + 1}
            onChange={handlePageChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DepositReport;
