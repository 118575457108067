import React, { useState } from 'react';
import { Box, Typography, IconButton, Paper, Container, Collapse } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const FaqItem = ({ question, answer }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    
    <Paper   
      elevation={0} 
      sx={{ 
        mb: 2, 
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
        overflow: 'hidden'
      }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: { xs: 2, md: 3 },
          cursor: 'pointer'
        }}
        onClick={handleToggle}
      >
        <Typography 
          variant="h6" 
          sx={{ 
            fontSize: { xs: '16px', md: '18px' },
            fontWeight: 600
          }}
        >
          {question}
        </Typography>
        <IconButton 
          sx={{ color: 'primary.main' }}
          aria-label={expanded ? "collapse" : "expand"}
        >
          {expanded ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        <Box 
          sx={{ 
            p: { xs: 2, md: 3 }, 
            pt: 0,
            pr: { xs: 6, md: 8 }
          }}
        >
          <Typography 
            variant="body1" 
            sx={{ 
              color: 'text.secondary',
              lineHeight: 1.6,
              fontSize: { xs: '14px', md: '16px' }
            }}
          >
            {answer}
          </Typography>
        </Box>
      </Collapse>
    </Paper>
  );
};

const HomeFaqFive = () => {
  const faqData = [
    {
      question: "Why was my KYC verification not approved?",
      answer: "After submitting your identity information, please be patience as the KYC review process will normally take about 5-10 minutes. There are several reasons if you fail to pass KYC. Currently, we do not provide support to residents of certain countries and regions, including Afghanistan, Belarus, Burma (Myanmar), Canada, Central African Republic, China, Cuba, Democratic Republic of the Congo, Ethiopia, Haiti, Iran, Iraq, Lebanon, Libya, Mali, North Korea, Nicaragua, Russia, Somalia, South Sudan, Sudan, Syria, Ukraine, United States, Venezuela,Yemen, Zimbabwe, Burkina Faso, Congo, Cameroon, Jamaica, Mozambique, Senegal, Tanzania, Palau, Uganda, Burundi, Guinea-Bissau, Eritrea, Liberia, Rwanda, Sierra Leone, Croatia, Bosnia and Herzegovina, Montenegro, Macedonia, Serbia, Slovenia, Kosovo."
    },
    {
      question: "What should I do if my deposit does not meet the minimum required amount?",
      answer: "Each cryptocurrency has a minimum deposit amount on its respective deposit page. Since the deposit process is all completed on the chain, this amount depends on the gas fee for each chain transfer. Please do not attempt to deposit less than this amount as this may result in funds not being credited."
    },
    {
      question: "How long does it take to process a withdrawal?",
      answer: "Withdrawal processing times vary depending on the cryptocurrency and network congestion. Most withdrawals are processed within 30 minutes to 2 hours. During periods of high network traffic, processing may take longer."
    },
    {
      question: "How can I reset my password?",
      answer: "To reset your password, click on the 'Forgot Password' link on the login page. You will receive a password reset link to your registered email address. Follow the instructions to create a new password. For security reasons, make sure your new password is strong and unique."
    },
    {
      question: "What trading fees do you charge?",
      answer: "Our platform uses a tiered fee structure based on your 30-day trading volume. Maker fees range from 0.1% to 0.01%, and taker fees range from 0.15% to 0.05%. Higher trading volumes qualify for lower fees. VIP users may receive additional fee discounts."
    }
  ];

  return (
    <Container
     id="Home-Section-One"
     maxWidth="lg" sx={{ py: { xs: 4, md: 8 } }}>
      <Box sx={{ textAlign: 'center', mb: { xs: 4, md: 6 } }}>
        <Typography 
          variant="h2" 
          component="h2" 
          sx={{ 
            fontWeight: 900, 
            fontSize: { xs: '28px', sm: '36px', md: '48px' },
            letterSpacing: '-0.5px',
            mb: 2
          }}
        >
          FREQUENTLY ASKED QUESTIONS
        </Typography>
      </Box>
      
      <Box>
        {faqData.map((item, index) => (
          <FaqItem 
            key={index} 
            question={item.question} 
            answer={item.answer} 
          />
        ))}
      </Box>
    </Container>
    
  );
};

export default HomeFaqFive;