import React, { useEffect, useState } from "react";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Typography, Select, MenuItem, Pagination, Box, TextField } from "@mui/material";
import axios from "axios";

const AllPayIn = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchData = async () => {
    setLoading(true);
    const token = sessionStorage.getItem("token");
    const userType = sessionStorage.getItem("role");
    const userId = sessionStorage.getItem("userId"); // Ensure userid exists in sessionStorage

    let url, headers;

    if (userType === "ADMIN") {
      url = "https://api.erapay.biz/erapay/admin/allpayin";
      headers = {
        Authorization: `Bearer ${token}`,
        currPage: currentPage,
        pageSize: pageSize,
        searchBy: searchQuery,
      };
    } else {
      url = "https://api.erapay.biz/erapay/user/payinreport";
      headers = {
        Authorization: `Bearer ${token}`,
        currPage: currentPage,
        pageSize: pageSize,
        userid: userId, // Pass userid for non-admin users
      };
    }

    try {
      const response = await axios.get(url, { headers });

      if (response.status === 200) {
        setData(response.data.data.content || []);
        setTotalRecords(response.data.data.totalElements || 0);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setCurrentPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    if (event.key === "Enter") {
      setCurrentPage(0);
      fetchData();
    }
  };

  return (
    <Box style={{ padding: "2rem", marginTop: "calc(0% + 56px)" }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "sans-serif",
            fontSize: "19px",
            color: "#343C6A",
          }}
        >
          All Pay In Transactions
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyDown={handleSearchSubmit}
          size="small"
        />
      </Grid>

      <Grid style={{ marginTop: "3%" }}>
        {loading ? (
          <Grid sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Grid>
        ) : (
          <TableContainer
            component={Paper}
            sx={{ overflowX: "auto", borderRadius: "12px 12px 0px 0px" }}
          >
            <Table>
              <TableHead style={{ backgroundColor: "#f2b173", color: "white" }}>
                <TableRow>
                  <TableCell sx={{ color: "white" }}>Sr No.</TableCell>
                  <TableCell sx={{ color: "white" }}>Transaction ID</TableCell>
                  <TableCell sx={{ color: "white" }}>EasePay ID</TableCell>
                  <TableCell sx={{ color: "white" }}>User Name</TableCell>
                  <TableCell sx={{ color: "white" }}>Phone</TableCell>
                  <TableCell sx={{ color: "white" }}>Amount</TableCell>
                  <TableCell sx={{ color: "white" }}>Date</TableCell>
                  <TableCell sx={{ color: "white" }}>Status</TableCell>
                  <TableCell sx={{ color: "white" }}>Settlement</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row, index) => (
                    <TableRow key={index}
                      sx={{
                        backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#fbe5d0",
                      }}>
                      <TableCell>
                        {currentPage * pageSize + index + 1}
                      </TableCell>
                      <TableCell>{row.txnid || "N/A"}</TableCell>
                      <TableCell>{row.easepayid || "N/A"}</TableCell>
                      <TableCell>{row.user.name || "N/A"}</TableCell>
                      <TableCell>{row.user.mobile || "N/A"}</TableCell>
                      <TableCell>{row.amount || "N/A"}</TableCell>
                      <TableCell>
                        {row.addedon
                          ? new Date(row.addedon).toLocaleString()
                          : "N/A"}
                      </TableCell>
                      <TableCell>{row.status || "N/A"}</TableCell>
                      <TableCell>{row.settlement || "N/A"}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>

      {/* Pagination */}
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: "1%" }}
      >
        <Grid item>
          <Select
            variant="outlined"
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
          <Typography display="inline" style={{ marginLeft: 8 }}>
            Showing {currentPage * pageSize + 1} to{" "}
            {Math.min((currentPage + 1) * pageSize, totalRecords)} out of{" "}
            {totalRecords} records
          </Typography>
        </Grid>
        <Grid item>
          <Pagination
            count={Math.ceil(totalRecords / pageSize)}
            color="primary"
            page={currentPage + 1}
            onChange={handlePageChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllPayIn;
