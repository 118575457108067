import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Tabs, Tab, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import virtualCardImage from '../assets/Home/Virtual-card.jpg'; 
import physicalCardImage from '../assets/Home/physical-card.png'; 

// Custom styled tabs
const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: '#2575fc',
    height: 3,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '1rem',
  color: 'rgba(0, 0, 0, 0.7)',
  '&.Mui-selected': {
    color: '#2575fc',
    fontWeight: 'bold',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.85rem',
    padding: '6px 12px',
  },
}));

// TabPanel component to render tab content
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`card-tabpanel-${index}`}
      aria-labelledby={`card-tab-${index}`}
      style={{ width: '100%', height: '100%' }}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const HomePageTwo = () => {
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  // Function to handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Auto-switch tabs every 6 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTab((prevTab) => (prevTab === 0 ? 1 : 0));
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  // Feature items for each card
  const virtualCardFeatures = [
    'Instant transaction',
    'Widely-accepted',
    'Compatible with Apple Pay/Google Pay'
  ];

  const physicalCardFeatures = [
    'Tap and Pay',
    'ATM withdrawal',
    'One card for all purchases'
  ];

  return (
    <Container
    id="Home-Section-Two"
    maxWidth={false} disableGutters sx={{ overflow: 'hidden' }}>
      <Box sx={{ 
        width: '100%', 
        bgcolor: 'background.paper',
        padding: { xs: '20px 10px', sm: '30px 20px', md: '40px' }
      }}>
        <StyledTabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          sx={{ 
            marginBottom: { xs: 2, sm: 3, md: 4 },
            '& .MuiTab-root': { fontSize: { xs: '0.9rem', sm: '1rem', md: '1.2rem' } }
          }}
        >
          <StyledTab label="VIRTUAL CARD" />
          <StyledTab label="PHYSICAL CARD" />
        </StyledTabs>

        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' }, 
          height: { xs: 'auto', md: '70vh' },
          gap: { xs: '20px', md: '0' }
        }}>
          {/* Tab Panels */}
          <TabPanel value={activeTab} index={0}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', md: 'row' },
              height: '100%' 
            }}>
              <Box sx={{ 
                width: { xs: '100%', md: '50%' }, 
                textAlign: 'left',
                padding: { xs: '10px', sm: '20px', md: '30px' },
                order: { xs: 2, md: 1 }
              }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                    lineHeight: 1.2,
                    marginBottom: { xs: 2, md: 3 },
                    fontSize: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
                  }}
                >
                  VIRTUAL CARD ANYTIME, ANYWHERE
                </Typography>
                
                <Box sx={{ marginTop: { xs: 2, md: 4 } }}>
                  {virtualCardFeatures.map((feature, index) => (
                    <Typography
                      key={index}
                      sx={{
                        fontSize: { xs: '1rem', sm: '1.1rem', md: '1.3rem' },
                        marginBottom: { xs: 1, sm: 1.5, md: 2 },
                        display: 'flex',
                        alignItems: 'center',
                        '&:before': {
                          content: '"•"',
                          color: '#2575fc',
                          fontWeight: 'bold',
                          marginRight: '10px',
                          fontSize: '1.5em'
                        }
                      }}
                    >
                      {feature}
                    </Typography>
                  ))}
                </Box>
              </Box>
              
              <Box sx={{ 
                width: { xs: '100%', md: '50%' }, 
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                order: { xs: 1, md: 2 },
                padding: { xs: '10px', md: '20px' }
              }}>
                <img 
                  src={virtualCardImage || "https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569_960_720.jpg"} 
                  alt="Virtual Card" 
                  style={{ 
                    width: '100%', 
                    height: 'auto', 
                    marginRight: '40px',
                    maxHeight: '100%',
                    // objectFit: 'contain', 
                    borderRadius: '10px',
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'
                  }}
                />
              </Box>
            </Box>
          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', md: 'row' },
              height: '100%' 
            }}>
              <Box sx={{ 
                width: { xs: '100%', md: '50%' }, 
                textAlign: 'left',
                padding: { xs: '10px', sm: '20px', md: '30px' },
                order: { xs: 2, md: 1 }
              }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                    lineHeight: 1.2,
                    marginBottom: { xs: 2, md: 3 },
                    fontSize: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
                  }}
                >
                  PHYSICAL CARD ONE CARD FOR ALL
                </Typography>
                
                <Box sx={{ marginTop: { xs: 2, md: 4 } }}>
                  {physicalCardFeatures.map((feature, index) => (
                    <Typography
                      key={index}
                      sx={{
                        fontSize: { xs: '1rem', sm: '1.1rem', md: '1.3rem' },
                        marginBottom: { xs: 1, sm: 1.5, md: 2 },
                        display: 'flex',
                        alignItems: 'center',
                        '&:before': {
                          content: '"•"',
                          color: '#2575fc',
                          fontWeight: 'bold',
                          marginRight: '10px',
                          fontSize: '1.5em'
                        }
                      }}
                    >
                      {feature}
                    </Typography>
                  ))}
                </Box>
              </Box>
              
              <Box sx={{ 
                width: { xs: '100%', md: '50%' }, 
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                order: { xs: 1, md: 2 },
                padding: { xs: '10px', md: '20px' }
              }}>
                <img 
                  src={physicalCardImage || "https://cdn.pixabay.com/photo/2019/07/31/01/54/bank-card-4373313_960_720.jpg"} 
                  alt="Physical Card" 
                  style={{ 
                    width: '100%', 
                    height: 'auto', 
                    maxHeight: '100%',
                    marginRight: '40px',
                    // objectFit: 'contain', 
                    borderRadius: '10px',
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'
                  }}
                />
              </Box>
            </Box>
          </TabPanel>
        </Box>
      </Box>
    </Container>
  );
};

export default HomePageTwo;